


































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AbstractModal extends Vue {
  @Prop({ required: true }) modalId: string;
  @Prop({ default: 'trademark' }) backgroundColor: string;
  @Prop({ default: true}) centered: boolean;
  @Prop({ default: true }) fade: boolean;
  @Prop({ default: 'lg'}) size: string;
  @Prop() styleClasses: string;
  @Prop({ default: false }) backdropped: boolean;
  @Prop({ default: false }) scrollable: boolean;

  get modalDialogClasses() {
    return [
      this.size != 'md' ? `modal-${this.size}` : '',
      this.centered ? 'modal-dialog-centered' : '',
      this.scrollable ? 'modal-dialog-scrollable' : '',
    ];
  }

  get modalClasses() {
    return [
      this.fade ? 'fade' : '',
      this.styleClasses,
    ];
  }
}

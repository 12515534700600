import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { Note } from '@/interfaces/note';

const api = '/notes';

export class NoteService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getNotesForProject = async (id: number): Promise<Note[]> => {
    const response = await this.get<Note[]>(api + '/byProject/' + id);
    return this.getResponse(response);
  };

  public getNotesForProcurement = async (id: number): Promise<Note[]> => {
    const response = await this.get<Note[]>(api + '/byProcurement/' + id);
    return this.getResponse(response);
  };

  public createNote = async (note: Note): Promise<Note> => {
    const response = await this.post<Note>(api, note);
    return this.getResponse(response);
  };

  public updateNote = async (note: Note): Promise<Note> => {
    const response = await this.put<Note>(api, note);
    return this.getResponse(response);
  };

  public deleteNote = async (id: number): Promise<void> => {
    const response = await this.delete<void>(`${api}/${id}`);
    return this.getResponse(response);
  };
}

export const noteService = new NoteService(apiConfig);

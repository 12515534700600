









































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Agent } from '@/interfaces/agent';
import AgentCountField from '@/components/dashboard-components/agent-count-field.vue';
import { agentService } from '@/services/restapi/agent-service';
import { AgentStatistics } from '@/interfaces/agent-statistics';
import ModalAgentSettings from '@/components/modals/modal-agent-settings.vue';
import { RouterNames } from '@/util/enums/router-names';

@Component({
  components: {
    AgentCountField,
    ModalAgentSettings,
  },
})
export default class AgentsOverview extends Vue {
  @Prop({ default: 5 }) limit: number;
  showAll = false;
  statisticsData: AgentStatistics[] = null;

  created() {
    agentService.agentStatistics().then(statistics => this.statisticsData = statistics);
  }

  get agents() {
    return this.$vmx.agent.agents;
  }

  get limitedAgents() {
    return this.showAll ? this.agents : this.agents.slice(0, this.limit);
  }
  
  get lineClasses() {
    return [
      'border-bottom',
      'border-trademark-25',
    ]
  }

  openAgentSettingsModal(agent: Agent) {
    this.$vmx.modal.openAgentSettingsModal(agent);
  }

  searchWithAgent(agent: Agent) {
    this.$vmx.search.setAgent(agent);
    this.$router.push({ name: RouterNames.LOGGED_IN_SEARCH, params: { page: '1' } });
  }

  getStatistics(agent: Agent): AgentStatistics {
    if (this.statisticsData == null) {
      return { agentId: agent.id, searchCount: null, dailyCount: null, monthlyCount: null };
    }

    const stats = this.statisticsData.filter(stat => stat.agentId === agent.id)[0] ?? null;

    if (stats == null) {
      return { agentId: agent.id, searchCount: null, dailyCount: null, monthlyCount: null };
    }

    return stats;
  }
}

import { createModule as createModuleVuex } from 'vuex-class-component';

export const createModule = (namespaced: string) => {
  return class StoreModule extends createModuleVuex({ namespaced, strict: false }) {
    public get namespace(): string {
      return namespaced;
    }
  };
};

export const preserve = async <T>(
  promise: Promise<T>,
  promiseFunc: () => Promise<T>,
  setter: (val: T) => void,
  reset?: boolean,
): Promise<T> => {
  if (promise != null && !(reset ?? false)) {
    return promise;
  }
  promise = promiseFunc();
  promise.then(setter);
  return promise;
};

import { Component, Prop, Vue } from 'vue-property-decorator';
import { Company } from '@/interfaces/company';
import CompanyProjectsList from '@/components/company-list-components/company-projects-list.vue';
import { companyService } from '@/services/restapi/company-service';
import { Contact } from '@/interfaces/contact';
import { contactPersonService } from '@/services/restapi/contact-person-service';
import DropdownIconBar from '@/components/dropdowns/dropdown-icon-bar.vue';
import DropdownOrderBy from '@/components/dropdowns/dropdown-order-by.vue';
import Error401 from '@/components/error-components/error-401.vue';
import { Modal } from 'bootstrap';
import ModalNoteDelete from '@/components/modals/modal-note-delete.vue';
import ModalNoteEdit from '@/components/modals/modal-note-edit.vue';
import { Note } from '@/interfaces/note';
import NoteList from '@/components/general-components/note-list.vue';
import { Tip } from '@/interfaces/tip';

@Component({
  components: {
    CompanyProjectsList,
    DropdownIconBar,
    DropdownOrderBy,
    Error401,
    ModalNoteEdit,
    ModalNoteDelete,
    NoteList,
  },
})
export default class CompanyView extends Vue {
  @Prop() private id: number;
  loading = true;
  company: Company = null;
  contacts: Contact[] = null;
  getImageFromFile = this.$vmx.file.imageFromFile;
  errorCode: number = null;
  contactError: number = null;
  selectedContact: Contact = null;
  selectedNote: Note = null;

  mounted() {
    this.loadCompany();
    this.loadContacts();
  }

  get isOnCompanyList(): boolean {
    return this.$vmx.companyList.isInCompanyList(this.company);
  }

  get noteId(): string {
    return 'note-list';
  }

  get hasSomeBasicInfo(): boolean {
    return !!this.company.file
      || !!this.company.description
      || !!this.company.address
      || !!this.company.mail
      || !!this.company.link
      || !!this.company.phone;
  }

  loadCompany() {
    this.loading = true;
    companyService.getCompanyById(this.id)
      .then(
        (company) => {
          this.company = company;
          document.title = company.companyName;
        },
        (error) => {
          this.errorCode = error;
        },
      )
      .finally(() => {
        this.$nextTick(() => {
          this.loading = false;
        })
      });
  }

  loadContacts() {
    contactPersonService.getContactsForCompany(this.id)
      .then(
        (contacts) => {
          this.contacts = contacts
        },
        (error) => {
          this.contactError = error;
        },
      );
  }

  noteSaved(note: Note) {
    if (this.company.notes.some((x) => x.id === note.id)) {
      this.company.notes = this.company.notes.map((obj) => (obj.id === note.id ? note : obj));
    } else {
      this.company.notes.push(note);
      this.company.notes.sort((a, b) => (a.created > b.created ? -1 : 1));
    }
    this.selectedNote = null;
  }

  noteDeleted(id: number) {
    this.company.notes.splice(
      this.company.notes.findIndex((x) => x.id === id),
      1,
    );
    this.selectedNote = null;
  }

  openCompanyListAddModal() {
    this.$vmx.modal.openCompanyListAddModal(this.company);
  }

  openDeleteNoteModal(id: number) {
    this.selectedNote = this.company.notes.find((note) => note.id == id);
    this.openModal(`delete-${this.noteId}`);
  }

  openModal(modalId: string) {
    const element = document.getElementById(modalId);
    const modal = new Modal(element);
    modal.show();
  }

  openTipModal(contact: Contact) {
    const user = this.$vmx.user.user;
    const tip: Tip = {
      contactId: contact.id,
      paUserId: user.id,
      name: user.name,
      email: user.mail,
      phone: user.phone,
      description: null,
    }
    this.$vmx.modal.openTipModal(tip);
  }
}
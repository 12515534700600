



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { Modal } from 'bootstrap';
import { Note } from '@/interfaces/note';
import { noteService } from '@/services/restapi/note-service';

@Component({
  components: {
    BaseModalContainer,
  },
})
export default class ModalNoteDelete extends Vue {
  @Prop({ required: true }) modalId: string;
  @Prop({ required: true }) note: Note;
  loading = true;
  submitting = false;
  error = false;

  mounted() {
    this.setEventListeners();
  }

  activated() {
    this.setEventListeners();
  }

  setEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  initializeModal() {
    this.loading = false;
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal();
    }
  }

  async submit() {
    if (this.note.id) {
      this.submitting = true;
      try {
        await noteService.deleteNote(this.note.id);
        this.$emit('noteDeleted', this.note.id);
        this.error = false;
        this.submitting = false;
        this.hideModal();
      } catch (error) {
        this.error = true;
        this.submitting = false;
      }
    }
  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }
}


















































import { Component, Vue } from 'vue-property-decorator';
import { RouterNames } from '@/util/enums/router-names';

@Component
export default class DropdownProfileContent extends Vue {

  get routeCookiePolicy(): string {
    return RouterNames.LOGGED_OUT_COOKIE_POLICY;
  }

  get routeUserSettings(): string {
    return RouterNames.LOGGED_IN_USER_SETTINGS;
  }

  async logout() {
    // this.$ga.set('dimension1', null);
    // this.$gtag.customMap({ 'dimension1': null });
    // authService.logout();
    this.$vmx.session.loginOut();
  }
}

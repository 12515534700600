import { action } from 'vuex-class-component';
import { cookieUtils } from '@/util/cookie-utils';
import { createModule } from '../preloader';
import { LimitedAccess } from '@/interfaces/limited-access';
import { limitedAccessService } from '@/services/restapi/limited-access-service';
import { ResponseCodes } from '@/util/enums/response-codes';

export default class LimitedAccessStore extends createModule('limited-access') {
  private limitedAccessData = null as LimitedAccess
  private limitedAccessPromise = null as Promise<LimitedAccess>

  get limitedAccess(): LimitedAccess {
    return this.limitedAccessData;
  }

  get token(): Promise<string> {
    return limitedAccessService.getLimitedAccessToken();
  }

  @action
  async fetchLimitedAccess(): Promise<LimitedAccess> {
    if (this.limitedAccessData != null) {
      return this.limitedAccessData
    }

    if (this.limitedAccessPromise != null) {
      return this.limitedAccessPromise
    }

    this.limitedAccessPromise = limitedAccessService.getLimitedAccess();
    this.limitedAccessData = await this.limitedAccessPromise;

    return this.limitedAccessPromise;
  }

  @action
  async reFetchLimitedAccess(): Promise<LimitedAccess> {
    this.limitedAccessPromise = limitedAccessService.getLimitedAccess();
    this.limitedAccessData = await this.limitedAccessPromise;
    return this.limitedAccessPromise;
  }

  @action
  async create(info: {name: string, mail: string}) {
    return await limitedAccessService.create(info);
  }

  @action
  async resend(mail: string) {
    return await limitedAccessService.resend(mail);
  }

  @action
  async auth(info: {mail: string, code: string}) {
    const status = await limitedAccessService.auth(info);
    if (status != ResponseCodes.SUCCESS) {
      return status;
    }

    this.fetchLimitedAccess();
    return status;
  }

  @action
  async clear() {
    cookieUtils.deleteCookie('session');
    this.limitedAccessData = null;
    this.limitedAccessPromise = null;
  }
}
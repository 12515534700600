import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { Tip } from '@/interfaces/tip';

const api = '/tips';

export class TipService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getTipById = async (id: number): Promise<Tip> => {
    const response = await this.get<Tip>(api + '/' + id);
    return this.getResponse(response);
  };

  public postTip = async (tip: Tip): Promise<Tip> => {
    const response = await this.post<Tip>(api, tip);
    return this.getResponse(response);
  };

  public companyRoleHasTip = async (companyRoleId: number): Promise<boolean> => {
    const response = await this.get<boolean>(api + '/hasCompanyAndUser/' + companyRoleId);
    return this.getResponse(response);
  };
}

export const tipService = new TipService(apiConfig);



































































































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseAlert from '@/components/containers/base-alert.vue';
import BaseLoadingSpinner from '@/components/general-components/base-loading-spinner.vue';
import { ResponseCodes } from '@/util/enums/response-codes';

@Component({
  components: {
    AbstractForm,
    BaseLoadingSpinner,
    BaseAlert,
  },
})
export default class FormLoginLimitedAccess extends Vue {
  @Prop({ default: 'white '}) textColor: string;
  @Prop() backgroundColor: string;
  @Prop({ default: '5' }) paddingX: string;
  @Prop({ default: '5' }) paddingY: string;
  @Prop({ default: false }) centerHeader: boolean;
  @Ref() formContainer: HTMLDivElement;
  @Ref() coderef: HTMLInputElement[];

  formSize = window.innerWidth;

  name = '';
  mail = '';

  liveValidate: 'always' | 'onlyOnValid' = 'onlyOnValid';
  codeLiveValidate: 'always' | 'onlyOnValid' = 'onlyOnValid'

  nameIsValid = false;
  mailIsValid = false;

  codeFormState: 'new' | 'existing' | 'code' = 'new';

  disabled = false;

  spinnerOn = false;

  errorMessage: 'conflict' | 'error' | 'not found' | 'code error' | null = null;

  async create() {
    // const returnCode = this.$vmx.limitedAccess.create({ name: this.name, mail: this.mail })

    if (this.codeFormState == 'new' && this.nameIsValid && this.mailIsValid) {
      this.$vmx.limitedAccess.create({ name: this.name, mail: this.mail }).then((returnCode) => {
        if (returnCode == ResponseCodes.SUCCESS) {
          this.liveValidate = 'onlyOnValid';
          this.codeFormState = 'code';
        } else if (returnCode == ResponseCodes.CONFLICT) {
          this.errorMessage = 'conflict'
        } else {
          this.errorMessage = 'error';
        }
      })
      return;
    } else {
      this.liveValidate = 'always';
    }

    if (this.codeFormState == 'existing' && this.mailIsValid) {
      this.$vmx.limitedAccess.resend(this.mail).then((returnCode) => {
        if (returnCode == ResponseCodes.SUCCESS) {
          this.liveValidate = 'onlyOnValid';
          this.codeFormState = 'code';
        } else if (returnCode == ResponseCodes.NOT_FOUND) {
          this.errorMessage = 'not found';
        } else {
          this.errorMessage = 'error';
        }
      })
      return;
    } else {
      this.liveValidate = 'always';
    }

    return;
  }

  mounted() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  }

  get textSizeClass() {
    return [
      this.formSize > 496 ? 'roboto-5x' : this.formSize > 436 ? 'roboto-4x' : 'roboto-3x',
    ];
  }

  keyup(n: 0 | 1 | 2 | 3, keyEvent: KeyboardEvent) {
    if (n <= 2 && this.coderef[n].value != '') {
      this.coderef[n + 1].focus();
    }

    if (this.coderef.every((input) => input.value != '')) {
      const code = this.coderef.map((input) => input.value).reduce((s1, s2) => s1 + s2);
      this.spinnerOn = true;

      this.$vmx.limitedAccess.auth({ mail: this.mail, code })
        .then((status) => {
          if (status == ResponseCodes.SUCCESS) {
            location.reload();
          } else {
            this.errorMessage = 'code error';
            this.spinnerOn = false;
            this.coderef.forEach((input) => input.value = '');
          }
        });
    }
  }

  public resizeHandler() {
    if (this.formContainer) {
      this.formSize = this.formContainer.clientWidth;
    }
  }

  codeValidator(val: string) {
    const isValid = val.match('[a-z]{4}') != null;
    return isValid;
  }

  mailClick() {
    this.name = '';
    this.mail = '';
    this.liveValidate = 'onlyOnValid';

    this.errorMessage = null;

    if (this.codeFormState == 'new') {
      this.codeFormState = 'existing';
    } else if (this.codeFormState == 'existing') {
      this.codeFormState = 'new';
    }
  }
}

import { getStorage, setStorage } from '../storage';
import { action } from 'vuex-class-component';
import { Agent } from '@/interfaces/agent';
import { agentService } from '@/services/restapi/agent-service';
import { createModule } from '../preloader';
import { Criterion } from '@/interfaces/criterias/criterion';
import { MailTime } from '@/interfaces/enums/mail-time';
import { SearchItemPage } from '@/interfaces/search-item-page';
import { vmx } from '..';

export default class SearchStore extends createModule('search') {
  public static readonly orderByOptions = {
    bestMatch: null as null,
    budget: 'BUDGET_' as const,
    updated: 'UPDATED_' as const,
    published: 'PUBLISHED_' as const,
  };

  private agentData = null as Agent;

  private searchItemPageData = null as SearchItemPage;
  private searchItemPagePromise = null as Promise<SearchItemPage>;
  private searchItemNextPagePromise = null as Promise<SearchItemPage>;

  private pageData = null as number;

  private orderByData = null as string;
  private orderByDescData = null as boolean;

  private debouncer = null as NodeJS.Timeout;

  get agent(): Agent {
    if (this.agentData != null) {
      return this.agentData;
    }

    const storeAgent = getStorage('agent');
    if (storeAgent != null && this.isAgent(storeAgent)) {
      this.agentData = storeAgent;
      setStorage('agent', this.agentData);
      return this.agentData;
    }

    this.agentData = this.getInitialAgent();
    setStorage('agent', this.agentData);
    return this.agentData;
  }

  private get isAgent(): (agent: Agent) => boolean {
    return (agent) => {
      const agentKeys        = JSON.stringify(Object.keys(agent).sort());
      const initialAgentKeys = JSON.stringify(Object.keys(this.getInitialAgent()).sort());

      return agentKeys === initialAgentKeys;
    };
  }

  get searchItemPage() {
    return this.searchItemPageData;
  }

  get page() {
    if (this.pageData == null) {
      this.pageData = 1;
    }
    return this.pageData;
  }

  get limit() {
    return 20;
  }

  get orderBy() {
    if (this.orderByData === null) {
      this.orderByData = SearchStore.orderByOptions.bestMatch;
    }
    return this.orderByData;
  }

  get orderByDesc() {
    if (this.orderByDescData == null) {
      this.orderByDescData = true;
    }
    return this.orderByDescData;
  }

  get agentCriteriaOfType(): (typeId: number) => Criterion[] {
    return (typeId) => {
      if (this.agent.criteria) {
        return this.agent.criteria.filter(criterion => criterion.typeId === typeId)
      }
      return [];
    }
  }

  @action
  async setAgent(agent: Agent) {
    this.agentData = { ...agent };
    setStorage('agent', this.agent);
    await this.search();
  }

  @action
  async updateAgent(agent: Partial<Agent>) {
    this.agentData = {
      ...this.agentData,
      ...agent,
    };
    setStorage('agent', this.agentData);
    await this.search();
  }

  @action
  async updateCriteriaForType(args: { criteria: Criterion[], typeId: number }) {
    this.agentData.criteria = this.agentData.criteria?.filter(criterion => criterion.typeId !== args.typeId) ?? [];
    this.agentData.criteria = this.agentData.criteria.concat(args.criteria);
    this.agent.id = null;
    setStorage('agent', this.agentData);
    await this.search();
  }

  @action
  async clearAgent() {
    this.setAgent(this.getInitialAgent());
  }

  @action
  async clearCriteria() {
    this.setAgent({
      ...this.getInitialAgent(),
      isProcurement: this.agent.isProcurement,
    });
  }

  @action
  async setPage(page: number) {
    if (this.pageData == page) {
      return;
    }

    if (this.pageData + 1 == page) {
      this.pageData = page;
      await this.loadNextPage();
      return;
    }

    this.pageData = page;
    this.search();
  }

  @action
  private async loadNextPage() {
    this.searchItemPageData = null;
    this.searchItemPageData = await this.searchItemNextPagePromise;

    this.searchItemPagePromise = this.searchItemNextPagePromise;

    const orderByDesc = this.orderByDesc ? 'DESC' : 'ASC';
    const orderByQuary =
      this.orderBy == null
        ? null
        : `${this.orderBy}${orderByDesc}`;

    this.searchItemNextPagePromise = agentService.search(
      this.agent,
      this.page * this.limit,
      this.limit,
      orderByQuary,
    );
  }

  @action
  async setOrderBy(orderBy: string) {
    let isCorrectForm = false;
    for (const value of Object.values(SearchStore.orderByOptions)) {
      if (value === null || value === orderBy) {
        isCorrectForm = true;
        break;
      }
    }
    if (!isCorrectForm) {
      return;
    }
    if (this.orderByData !== orderBy) {
      this.orderByData = orderBy;
      await this.search();
    }
  }

  @action
  async setOrderByDesc(orderByDesc: boolean) {
    this.orderByDescData = orderByDesc;
    await this.search();
  }

  @action
  async fetchSearchItemPage() {
    if (this.searchItemPageData) {
      return this.searchItemPageData;
    }

    if (this.searchItemPagePromise) {
      return this.searchItemPagePromise;
    }

    await this.search();

    return this.searchItemPageData;
  }

  @action
  async filterSearchItemResults() {
    const searchItemPageResults = (await this.searchItemPagePromise).results;

    const results = await vmx.blacklist.filterSearchItemList(searchItemPageResults);

    if (results.length == 0) {
      this.search();
      return;
    }

    this.searchItemPageData = {
      ...this.searchItemPageData,
      results,
    };
  }

  get getInitialAgent(): () => Agent {
    // const allowUnusedCriterionTypes = vmx.criterion.criterionTypes?.map(criterionType => criterionType.id) ?? [];
    return () => ({
      id: null,
      name: '',
      freeSearch: '',
      criteria: [],
      minBudget: null,
      maxBudget: null,
      minSizeBuilding: null,
      minSizeGround: null,
      minSizeMetres: null,
      maxSizeBuilding: null,
      maxSizeGround: null,
      maxSizeMetres: null,
      minNumberOfUnits: null,
      maxNumberOfUnits: null,
      projectStart: [],
      projectEnd: [],
      isPublic: null,
      allowUnusedCriterionTypes: [],
      procurementPhases: [],
      procurementTypes: [],
      projectPhases: [],
      allowUnspecifiedSize: true,
      allowUnspecifiedBudget: true,
      allowUnspecifiedStartAndEnd: true,
      mailTime: MailTime.Daily,
      isProcurement: vmx.user.user?.product?.basic ?? false,
      paUserIds: [],
      newResultsDaily: null,
      newResultsMonthly: null,
    });
  }

  get canResetAgent(): boolean {
    if (this.agent == null) {
      return false;
    }
    
    const checkOther = (agent: Agent) => {
      agent.isProcurement = !agent.isProcurement;
      return JSON.stringify(agent) !== JSON.stringify(this.getInitialAgent());
    }
    const agentCopy = {...this.agent};

    return JSON.stringify(agentCopy) !== JSON.stringify(this.getInitialAgent()) && checkOther(agentCopy);
  }

  @action
  async resetPage() {
    await this.search();
  }

  @action
  private async search() {
    const orderByDesc = this.orderByDesc ? 'DESC' : 'ASC';
    const orderByQuary =
      this.orderBy == null
        ? null
        : `${this.orderBy}${orderByDesc}`;

    this.searchItemPageData = {
      ...this.searchItemPageData,
      results: null,
    };

    if (this.debouncer != null) {
      clearTimeout(this.debouncer);
    }

    this.debouncer = setTimeout(async () => {
      this.searchItemPagePromise = agentService.search(
        this.agent,
        (this.page - 1) * this.limit,
        this.limit,
        orderByQuary,
      );

      await this.searchItemPagePromise;
      const networkDebounce = Promise.race([
        this.searchItemPagePromise.then(() => true),
        Promise.resolve(false),
      ]);

      if (networkDebounce) {
        this.searchItemPageData = await this.searchItemPagePromise;
      }

      this.searchItemNextPagePromise = agentService.search(
        this.agent,
        this.page * this.limit,
        this.limit,
        orderByQuary,
      );

      this.searchItemPageData = await this.searchItemPagePromise;
    }, 500);

    // await this.searchItemPagePromise;
    // this.searchItemPageData = await Promise.race([
    //   this.searchItemPagePromise,
    //   Promise.resolve(null),
    // ]);
  }
}














































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseInputField from '@/components/form-fields/base-input-field.vue';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import FormMailTime from '@/components/forms/form-mail-time.vue';
import { globalFunctions } from '@/util/global-functions';
import { List } from '@/interfaces/list';
import { listService } from '@/services/restapi/list-service';
import { MailTime } from '@/interfaces/enums/mail-time';
import { Modal } from 'bootstrap';
import { ModalIds } from '@/util/enums/modal-ids';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementReference } from '@/interfaces/procurement-reference';
import { Project } from '@/interfaces/project';
import { RouterNames } from '@/util/enums/router-names';
import { User } from '@/interfaces/user';

@Component({
  components: {
    AbstractForm,
    BaseModalContainer,
    FormMailTime,
  },
})
export default class ModalListSettings extends Vue {
  @Ref() listName: BaseInputField;
  listCopy: List = null;
  listsWithObject: List[] = null;
  users: User[] = [];
  selectedUsers: User[] = [];
  loading = true;
  submitting = false;
  error = false;

  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  get buttonRowClasses() {
    return [
      `justify-content-${this.list ? 'between' : 'end'}`,
    ];
  }
  
  get dataObject(): Project | Procurement | ProcurementReference {
    return this.$vmx.modal.listObject;
  }

  get deleteModalId(): string {
    return `delete-${this.modalId}`;
  }

  get formIsValid(): boolean {
    return this.listName.validate() && this.selectedUsers.length > 0;
  }

  get list(): List {
    return this.$vmx.modal.list;
  }

  get modalId(): string {
    return ModalIds.LIST_SETTINGS_MODAL;
  }

  @Watch('selectedUsers')
  selectedUsersChanged() {
    this.listCopy.paUserIds = this.selectedUsers.map(x => x.id);
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal(this.modalId);
    }
  }

  async createNewList(): Promise<List> {
    const newList: List = {
      id: null,
      name: '',
      projects: !globalFunctions.isProcurementOrProcurementReference(this.dataObject) ? [this.dataObject.id] : [],
      procurements: globalFunctions.isProcurementOrProcurementReference(this.dataObject) ? [this.dataObject.id] : [],
      mailTime: MailTime.Daily,
      paUserIds: [],
      budgetSum: null,
    };
    return newList;
  }

  async deleteList() {
    this.submitting = true;
    try {
      await listService.deleteList(this.listCopy.id)
      const index = this.$vmx.list.lists.findIndex((list: List) => list.id == this.listCopy.id);
      this.$vmx.list.lists.splice(index, 1);
      this.$emit('listDeleted');
      this.hideModal(this.deleteModalId);
      if (this.$route.name === RouterNames.LOGGED_IN_LIST_VIEW) {
        this.$router.push({ name: RouterNames.LOGGED_IN_DASHBOARD });
      }
      this.submitting = false;
    } catch (error) {
      this.error = true;
      this.submitting = false;
    }
  }

  async getOriginalLists() {
    if (this.dataObject) {
      await this.$vmx.list.fetchLists();
      if (this.$vmx.list.lists != null && this.$vmx.list.lists.length > 0) {
        this.listsWithObject = this.$vmx.list.listsWithObject(this.dataObject)
          .filter(list => list.id != this.listCopy.id);
      }
    }
  }

  hideModal(modal: string) {
    const element = document.getElementById(modal);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }

  initializeModal() {
    this.loading = true;
    if (this.list) {
      this.listCopy = {...this.list};
      this.setUsers();
      this.getOriginalLists().then(() => {
        this.loading = false;
      });
    } else {
      this.createNewList().then((newList) => {
        this.listCopy = newList;
        this.setUsers();
        this.getOriginalLists().then(() => {
          this.loading = false;
        });
      });
    }
  }

  nameValidator(name: string): boolean {
    return name.trim().length >= 1 && name.trim().length <= 100;
  }

  openModal(id: string) {
    const element = document.getElementById(id);
    const modal = new Modal(element);
    modal.show();
  }

  private setUsers() {
    const user = this.$vmx.user.user as User;
    this.users = this.$vmx.user.colleagues;
    if (this.listCopy.id) {
      this.selectedUsers = this.users.filter(x => this.listCopy.paUserIds.some(paUserId => paUserId === x.id));
    } else {
      this.selectedUsers = [user];
    }
  }

  async submit() {
    if (this.formIsValid) {
      this.submitting = true;
      try {
        if (this.listCopy.id) {
          const newList = await listService.putList(this.listCopy);
          if (newList) {
            this.error = false;
            this.$vmx.list.putList(newList);
          } else {
            this.error = true;
          }
          this.submitting = false;
          this.hideModal(this.modalId);
        } else {
          const addedList = await listService.postList(this.listCopy);
          if (addedList) {
            this.error = false;
            this.$vmx.list.lists.push(addedList);
          } else {
            this.error = true;
          }
          this.submitting = false;
          this.hideModal(this.modalId);
        }
      } catch (error) {
        this.error = true;
        this.submitting = false;
      }
    }
  }
}

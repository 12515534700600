


























































































import { Component, Prop, Ref, VModel, Vue } from 'vue-property-decorator';
import { globalFunctions } from '@/util/global-functions';

@Component
export default class InputTags extends Vue {
  @VModel() tags: any[];
  @Prop({ required: true }) options: any[];
  @Prop() placeholder: string;
  @Prop() title: string;
  @Prop({ default: true }) canClearAll: boolean;
  @Prop({ default: true }) bordered: boolean;
  @Prop({ default: 'white' }) backgroundColor: string;
  @Prop({ default: 'secondary-background' }) tagColor: string;
  @Prop({ default: (e: any):string => e }) displayProp: Function;
  @Prop() chooseAllLabel: string;
  @Prop() clearLabel: string;
  @Ref() inputField: HTMLInputElement;
  @Ref() dropdown: HTMLUListElement;
  searchText = '';
  hoverIndex: -1;
  showDropdown = false;
  inputFocus = false;
  dropdownFocus = false;

  mounted() {
    this.inputField.addEventListener('focusin', () => {
      this.inputFocus = true;
      this.showDropdown = true;
    });
    this.inputField.addEventListener('focusout', () => {
      this.inputFocus = false;
      globalFunctions.timeout(50).then(() => {
        if (!this.dropdownFocus) {
          this.showDropdown = false;
          this.hoverIndex = -1
        }
      })
    });
    this.dropdown.addEventListener('focusin', () => {
      this.dropdownFocus = true;
    });
    this.dropdown.addEventListener('focusout', () => {
      this.dropdownFocus = false;
      this.showDropdown = false;
    })
  }

  get computedTitle(): string {
    return this.title ? this.title : (this.$t('components.selectTagInput.text') as string);
  }

  get computedPlaceholder(): string {
    return this.placeholder ? this.placeholder : (this.$t('components.selectTagInput.placeholder') as string);
  }

  get computedChooseAllLabel(): string {
    return this.chooseAllLabel ? this.chooseAllLabel : (this.$t('components.selectTagInput.selectAll') as string);
  }

  get computedClearLabel(): string {
    return this.clearLabel ? this.clearLabel : (this.$t('components.selectTagInput.clear') as string);
  }

  get ulClassList(): string[] {
    return [
      'overflow-x-auto',
      'd-flex',
      'flex-wrap',
      'list-reset',
      'p-1',
      'p-1',
      'm-0',
      'w-auto',
    ]
  }

  get matchingOptions(): any[] {
    return this.options.filter((option) => {
      return !this.tags.includes(option) && (this.displayProp(option) as string).toLowerCase().startsWith(this.searchText.toLowerCase());
    });
  }

  get dropdownClassList(): string[] {
    return [
      'position-absolute',
      'w-100',
      'overflow-x-auto',
      'overflow-y-scroll',
      'appearance-none',
      'text-start',
      'list-reset',
      'bg-white',
      'rounded',
      'border',
      'border-secondary',
      'dropdown',
    ]
  }

  dropdownItemClassList(index: number): string[] {
    return [
      'dropdown-item',
      index === this.hoverIndex ? 'dropdown-hover' : '',
    ];
  }

  addOption(index: number) {
    const option = this.matchingOptions[index];
    if (!this.tags.includes(option)) {
      this.tags.push(option);
      this.searchText = '';
      this.showDropdown = false;
    }
  }

  addSearchOption() {
    if (
      this.options.some(option => this.displayProp(option) === this.searchText)
      && !this.tags.some(tag => this.displayProp(tag) === this.searchText)
    ) {
      this.tags.push(this.options.find(option => this.displayProp(option) === this.searchText));
      this.searchText = '';
    }
  }

  removeOption(index: number) {
    if (this.tags && this.tags.length > 0) {
      this.tags.splice(index, 1);
    }
  }

  selectAll() {
    this.options.forEach((option) => {
      if (!this.tags.some(tag => this.displayProp(tag) === this.displayProp(option))) {
        this.tags.push(option)
      }
    });
  }

  removeAll() {
    this.tags = [];
  }
}

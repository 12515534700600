



















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseLogo from '@/components/buttons/base-logo.vue';
import DropdownProfile from '@/components/dropdowns/dropdown-profile.vue';
import DropdownProfileContent from '@/components/dropdowns/dropdown-profile-content.vue';
import HeaderRoutingBar from '@/components/headers/header-routing-bar.vue';
import { RouterNames } from '@/util/enums/router-names';
import { RoutingButton } from '@/interfaces/non-entities/routing-button';

@Component({
  components: {
    BaseLogo,
    DropdownProfile,
    DropdownProfileContent,
    HeaderRoutingBar,
  },
})
export default class HeaderLoggedIn extends Vue {
  @Prop({ required: true }) routingButtons: RoutingButton[];
  @Prop({ required: true }) showLogin: boolean;
  @Prop({ required: true }) setShowLogin: (val: boolean) => void;

  readonly routeHelp = RouterNames.LOGGED_IN_HELP;

  // get routingButtons(): RoutingButton[] {
  //   return [
  //     {
  //       title: 'pages.dashboard.title',
  //       name: RouterNames.LOGGED_IN_DASHBOARD,
  //     },
  //     {
  //       title: 'pages.blacklist.title',
  //       name: RouterNames.LOGGED_IN_BLACKLIST_VIEW,
  //     },
  //     {
  //       title: 'pages.search.title',
  //       name: RouterNames.LOGGED_IN_SEARCH,
  //       params: { page: '0' },
  //     },
  //     {
  //       title: 'pages.companySearch.title',
  //       name: RouterNames.LOGGED_IN_COMPANY_SEARCH,
  //     },
  //   ]

  // }
}














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class BaseAlert extends Vue {
  @Prop({ default: 'primary' }) type: string;
  @Prop() paddingY: string;

  get leftSideClasses() {
    return [
      'left-side',
      `bg-${this.type}`,
    ]
  }

  get rightSideClasses() {
    return [
      'right-side',
      'flex-grow-1',
      'alert',
      `alert-${this.type}`,
      this.paddingY ? `py-${this.paddingY}` : '',
    ];
  }
}

import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { ContactInfo } from '@/interfaces/non-entities/contact-info';
import moment from 'moment';

const api = '/emails';

export class EmailService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public sendBookDemo = async (contactInfo: ContactInfo): Promise<any> => {
    const body = {
      name: contactInfo.name,
      phone: contactInfo.phone,
      email: contactInfo.mail,
      date: moment(contactInfo.date).add(contactInfo.time).format('yyyy-MM-DD HH:mm'),
      companyName: contactInfo.companyName,
      online: contactInfo.online,
      url: contactInfo.url,
    };
    const response = await this.post<any>(api + '/bookDemo', body);
    return this.getResponse(response);
  };

  public scheduleCall = async (contactInfo: ContactInfo): Promise<any> => {
    const body = {
      name: contactInfo.name,
      phone: contactInfo.phone,
      email: contactInfo.mail,
      date: moment(contactInfo.date).add(contactInfo.time).format('yyyy-MM-DD HH:mm'),
      companyName: contactInfo.companyName,
    };
    const response = await this.post<any>(api + '/scheduleCall', body);
    return this.getResponse(response);
  };

  public sendQuestion = async (contactInfo: ContactInfo): Promise<any> => {
    const body = {
      name: contactInfo.name,
      phone: contactInfo.phone,
      email: contactInfo.mail,
      question: contactInfo.question,
      companyName: contactInfo.companyName,
    };
    const response = await this.post<any>(api + '/sendQuestion', body);
    return this.getResponse(response);
  };
}

export const emailService = new EmailService(apiConfig);

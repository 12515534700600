













































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CompanyList } from '@/interfaces/company-list';
import { RouterNames } from '@/util/enums/router-names';

@Component
export default class CompanyListsOverview extends Vue {
  @Prop({ default: 5 }) limit: number;
  showAll = false;

  get companyLists(): CompanyList[] {
    return this.$vmx.companyList.companyLists;
  }

  get limitedCompanyLists(): CompanyList[] {
    return this.showAll ? this.companyLists : this.companyLists.slice(0, this.limit);
  }

  get lineClasses() {
    return [
      'border-bottom',
      'border-trademark-25',
    ]
  }

  openCompanyListSettingsModal(companyList: CompanyList) {
    this.$vmx.modal.openCompayListSettingsModal({ companyList: companyList });
  }

  openCompanyListPage(companyList: CompanyList) {
    this.$router.push({ name: RouterNames.LOGGED_IN_COMPANY_LIST_VIEW, params: { id: companyList.id.toString() } });
  }
}

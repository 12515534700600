import 'vuex-class-component';
import { clearStorage, getStorage, setStorage } from '../storage';
import { action } from 'vuex-class-component';
import { cookieUtils } from '@/util/cookie-utils';
import { createModule } from '../preloader';
import { LoginDetails } from '@/interfaces/non-entities/login-details';
import { RawLocation } from 'vue-router';
import router from '@/router';
import { RouterNames } from '@/util/enums/router-names';
import { vmx } from '..';

export default class SessionStore extends createModule('session') {
  get isLoggedIn(): boolean {
    return this.sessionCookie != null && (this.isLoggedinUser || this.isLoggedInLimitedAccess) ;
  }

  get isLoggedinUser(): boolean {
    return this.sessionCookie != null && vmx.user.user != null;
  }

  get isLoggedInLimitedAccess(): boolean {
    return this.sessionCookie != null && vmx.limitedAccess.limitedAccess != null;
  }

  @action
  async clearState(): Promise<void> {
    // TODO
  }

  @action
  async init(): Promise<void> {
    vmx.language.updateLanguage(vmx.language.language);

    this.fetchDefaultData();
    const isCurrenVersion = await this.fetchVersionCheck();

    if (!isCurrenVersion && this.isLoggedIn) {
      alert('A new version of Projektagenten is available.\nPlease log in again');
      clearStorage();
      await this.loginOut();
      return;
    }

    if (vmx.user.user != null && this.sessionCookie == null) {
      await this.loginOut();
      return;
    }

    if (this.isLoggedinUser) {
      this.fetchLoginData();
      return;
    }

    if (this.sessionCookie != null) {
      const limitedAccess = await vmx.limitedAccess.fetchLimitedAccess();
    }
  }

  @action
  async loginIn (loginDetails: LoginDetails): Promise<void> {
    await vmx.user.login(loginDetails);
    await this.pushVersionCheck();
    if (loginDetails.redirect) {
      // const href = router.resolve(loginDetails.redirect).href;
      // location.assign(href);
      router.push({
        name: loginDetails.redirect,
      })
    }
    location.reload();
  }

  @action
  async impersonate({
    username,
    password,
    token,
  }: {
    username: string;
    password: string;
    token: string;
  }) {
    await vmx.user.impersonate({ username, password, token });
    await this.pushVersionCheck();
    await router.push({ name: RouterNames.LOGGED_IN_DASHBOARD });
  }

  @action
  async loginOut(redirect?: RawLocation | RouterNames) {
    this.deleteCookie('session');
    vmx.user.clearUser();
    vmx.search.clearAgent();

    if (redirect == null) {
      // await router.push({ name: RouterNames.LOGGED_OUT_HOME})
      // location.reload()
      const href = router.resolve({ name: RouterNames.LOGGED_OUT_HOME}).href;
      location.assign(href);
    } else if (typeof redirect == 'string') {
      // await router.push({ name: redirect })
      // location.reload()
      const href = router.resolve({ name: redirect }).href;
      location.assign(href);
    } else {
      // await router.push(redirect)
      // location.reload();
      const href = router.resolve(redirect).href;
      location.assign(href);
    }
  }

  @action
  async panic(msg: string) {
    this.deleteCookie('session')
    vmx.user.clearUser();
    vmx.search.clearAgent();
    window.stop();

    router.push({ name: RouterNames.LOGGED_OUT_HOME }).then(() => {
      window.alert(msg);
      window.location.reload();
    })
  }

  private get sessionCookie(): string {
    return cookieUtils.getCookie('session');
  }

  @action
  private async deleteCookie(name: string) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;Path=/;';
  }

  @action
  private async fetchDefaultData() {
    return Promise.all([
      vmx.product.fetchProducts().then(() => {}),
      vmx.product.fetchProductsFeatures().then(() => {}),
    ])
  }

  @action
  private async fetchLoginData() {
    return Promise.all([
      vmx.user.fetchColleagues().then(() => {}),
      vmx.list.fetchLists().then(() => {}),
      vmx.agent.fetchAgents().then(() => {}),
      vmx.companyAgent.fetchCompanyAgents().then(() => {}),
      vmx.country.fetchCountries().then(() => {}),
      vmx.criterion.fetchCriterionTypes().then(() => {}),
      vmx.blacklist.fetchBlacklists().then(() => {}),
      vmx.companyList.fetchCompanyLists().then(() => {}),
      vmx.procurementPhase.fetchProcurementPhases().then(() => {}),
      vmx.projectPhase.fetchProjectPhases().then(() => {}),
      vmx.procurementType.fetchProcurementTypes().then(() => {}),
      vmx.role.fetchRoles().then(() => {}),
      vmx.search.fetchSearchItemPage().then(() => {}),
    ]);
  }

  @action
  private async fetchVersionCheck(): Promise<boolean> {
    const currentVersion = (await import('../../../package.json')).version?.split('.');
    const localVersion = getStorage('version')?.split('.');

    return localVersion != null && currentVersion != null && currentVersion[0] === localVersion[0];
  }

  @action
  private async pushVersionCheck(): Promise<void> {
    const currentVersion = (await import('../../../package.json')).version;
    setStorage('version', currentVersion);
  }
}

import { action } from 'vuex-class-component';
import { Company } from '@/interfaces/company';
import { CompanyAgent } from '@/interfaces/search/company-agent';
import { companyService } from '@/services/restapi/company-service';
import { createModule } from '../preloader';
import { MailTime } from '@/interfaces/enums/mail-time';
import { OrderByOptions } from '@/util/enums/order-by-options';
import { vmx } from '..';

export default class SearchCompanyStore extends createModule('searchCompany') {

  private static LIMIT = 20;

  private companyAgentData: CompanyAgent = this.initialCompanyAgent();
  private debouncer = null as NodeJS.Timeout;
  private indexData = 0;
  private limitData = SearchCompanyStore.LIMIT;
  private countData = null as number;
  private resultData = null as Company[];
  private resultPromise = null as Promise<{ count: number, companies: Company[]}>;

  private orderByData = null as OrderByOptions;
  private orderByDescData = true as boolean;

  get companyAgent(): CompanyAgent {
    return this.companyAgentData;
  }

  get count(): number {
    return this.countData;
  }

  get index(): number {
    return this.index;
  }

  get initialCompanyAgent(): () => CompanyAgent {
    return () => ({
      freeSearch: '',
      countryIds: [],
      roles: [],
      mailTime: MailTime.Daily,
      paUserIds: [],
    })
  }

  get isSearchEmpty(): boolean {
    return !this.companyAgentData.freeSearch
      && this.companyAgentData.countryIds.length < 1
      && this.companyAgentData.roles.length < 1;
  }

  get limit(): number {
    return this.limitData;
  }

  get loading(): boolean {
    return this.resultPromise != null;
  }

  get orderBy() {
    return this.orderByData;
  }

  get orderByDesc() {
    if (this.orderByDescData == null) {
      this.orderByDescData = true;
    }
    return this.orderByDescData;
  }

  get results(): Company[] {
    return this.resultData;
  }

  @action
  async resetCompanyAgent() {
    this.setCompanyAgent(this.initialCompanyAgent());
  }

  @action
  private async search(newLimit: number) {
    if (!vmx.user.hasCompanyAccess) {
      return;
    }

    if (this.debouncer != null) {
      clearTimeout(this.debouncer);
    }

    if (this.isSearchEmpty) {
      this.resultData = null;
      this.countData = null;
      this.resultPromise = null;
      return;
    }

    this.debouncer = setTimeout(async () => {

      if (!newLimit || this.resultData == null) {
        this.resultData = [];
      }

      // Set index and limit only after timeout, to not mess with the page view.
      this.indexData = newLimit ? this.limitData : 0;
      this.limitData = newLimit ?? SearchCompanyStore.LIMIT;

      const orderByDesc = this.orderByDesc !== null && !this.orderByDesc ? 'ASC' : 'DESC';
      const orderByQuary =
        this.orderBy == null
          ? null
          : `${this.orderBy}${orderByDesc}`;

      this.resultPromise = companyService.searchCompanies(
        this.companyAgentData,
        this.indexData,
        this.limitData,
        orderByQuary,
      );

      const newData = await this.resultPromise;
      this.resultData = this.resultData.concat(newData.companies);
      if (!newLimit) {
        this.countData = newData.count;
      }
      this.resultPromise = null;
      this.debouncer = null;
    }, 500);
  }

  @action
  async setCompanyAgent(companyAgent: CompanyAgent) {
    this.companyAgentData = { ...companyAgent }
    await this.search(null);
  }

  @action
  async setOrderByDesc(orderByDesc: boolean) {
    if (this.orderByDescData != orderByDesc) {
      this.orderByDescData = orderByDesc;
      await this.search(null);
    }
  }

  @action
  async setOrderBy(orderBy: OrderByOptions) {
    if (this.orderByData !== orderBy) {
      this.orderByData = orderBy;
      await this.search(null);
    }
  }

  @action
  async updateCompanyAgent(companyAgent: Partial<CompanyAgent>) {
    this.companyAgentData = {
      ...this.companyAgent,
      ...companyAgent,
    };
    await this.search(null);
  }

  @action
  async setLimit(newLimit: number) {
    await this.search(newLimit);
  }
}

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ButtonUnfold extends Vue {
  @Prop({ default: 'md'}) size: string;
  @Prop({default: '1x'}) iconSize: string;
  @Prop({default: false}) alignButtonEnd: boolean;
  @Prop({ default: 'white '}) variant: string;
  @Prop() text: string;
  public unfolded = false;

  updateState() {
    this.unfolded = !this.unfolded
    if (this.unfolded === true) {this.$emit('unfolded')}
    else {this.$emit('folded')}
    this.$emit('click')
  }
}





















































































import { Component, Ref, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseInputTextarea from '../form-fields/base-input-textarea.vue';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { Modal } from 'bootstrap';
import { ModalIds } from '@/util/enums/modal-ids';
import { Tip } from '@/interfaces/tip';
import { tipService } from '@/services/restapi/tip-service';

@Component({
  components: {
    AbstractForm,
    BaseModalContainer,
  },
})
export default class ModalTip extends Vue {
  @Ref() tipDescription: BaseInputTextarea;

  tipCopy: Tip = null;

  error = false;
  loading = true;
  submitting = false;
  tipSent = false;

  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  get formIsValid(): boolean {
    return this.tipDescription.validate();
  }

  get modalId(): string {
    return ModalIds.TIP_MODAL;
  }

  get tip(): Tip {
    return this.$vmx.modal.tip;
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal();
    }
  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }

  initializeModal() {
    this.loading = true;
    if (this.tip) {
      this.tipCopy = {...this.tip};
      this.error = false;
      this.loading = false;
      this.submitting = false;
      this.tipSent = false;
    } else {
      this.hideModal();
    }
  }

  async submit() {
    if (this.formIsValid) {
      this.submitting = true;
      try {
        await tipService.postTip(this.tipCopy);
        this.submitting = false;
        this.tipSent = true;
      } catch (error) {
        this.error = true;
        this.submitting = false;
        this.tipSent = false;
      }
    }
  }
}

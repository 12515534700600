














import { Component, Prop, Vue } from 'vue-property-decorator';
import AbstractModal from '@/components/modals/abstract-modal.vue';
import BasePaywall from '@/components/general-components/base-paywall.vue';

@Component({
  components: {
    AbstractModal,
    BasePaywall,
  },
})
export default class ModalPaywall extends Vue {
  @Prop({ required: true }) modalId: string;
  @Prop({ default: 'trademark' }) backgroundColor: string;
  @Prop({ default: 'white' }) textColor: string;
  @Prop() fade: string;
  @Prop({ default: 'xl' }) size: string;
}

import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { ProjectPhase } from '@/interfaces/criterias/project-phase';

const api = '/project-phases';

export class ProjectPhaseService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getProjectPhases = async (): Promise<ProjectPhase[]> => {
    const response = await this.get<ProjectPhase[]>(api);
    return this.getResponse(response);
  };

  public getProjectPhaseById = async (id: number): Promise<ProjectPhase> => {
    const response = await this.get<ProjectPhase>(api + '/' + id);
    return this.getResponse(response);
  };
}

export const projectPhaseService = new ProjectPhaseService(apiConfig);

































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProductFeature } from '@/interfaces/product-feature';
import { SuggestedProduct } from '@/interfaces/suggested-product';

@Component
export default class SuggestedProductBox extends Vue {
  @Prop({ required: true }) suggestedProduct: SuggestedProduct;
  productRetries = 0;
  featureRetries = 0;
  selectedIndex: number = null;
  selectedIndices: number[] = [];

  mounted() {
  }

  get product() {
    return this.$vmx.product.products?.find(x => x.id === this.suggestedProduct.productId) ?? undefined;
  }

  getDomainSVG(tld: string) {
    return {
      backgroundImage: `url('${require('@/assets/flags/' + tld + '.svg')}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
  }

  productHasFeature(feature: ProductFeature): boolean {
    return this.product.features.some((f) => f.id === feature.id);
  }

  buttonClicked() {
    this.$emit('buttonClicked', this.product.id);
  }

  selectFeature(index: number) {
    this.selectedIndex = this.selectedIndex === index ? null : index;

    const existingIndex = this.selectedIndices.findIndex(x => x === index);
    if (existingIndex >= 0) {
      this.selectedIndices.splice(existingIndex, 1);
    } else {
      this.selectedIndices.push(index);
    }
  }
}

import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { Contact } from '@/interfaces/contact';

const api = '/contacts';

export class ContactPersonService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getContactPersonById = async (id: number): Promise<Contact> => {
    const response = await this.get<Contact>(api + '/' + id);
    return this.getResponse(response);
  };

  public getContactsForCompany =async (companyId: number): Promise<Contact[]> => {
    const respose = await this.get<Contact[]>(`${api}/forCompany/${companyId}`);
    return this.getResponse(respose);
  }
}

export const contactPersonService = new ContactPersonService(apiConfig);

import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { Company } from '@/interfaces/company';
import { CompanyAgent } from '@/interfaces/search/company-agent';
import { File } from '@/interfaces/file';
import { Procurement } from '@/interfaces/procurement';
import { Project } from '@/interfaces/project';

const api = '/companies';

export class CompanyService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getCompanyById = async (id: number): Promise<Company> => {
    const response = await this.get<Company>(`${api}/byId/${id}`);
    return this.getResponse(response);
  };

  public getCompanyLogosByDomain = async (): Promise<File[]> => {
    const response = await this.get<File[]>(`${api}/logo/byDomain`);
    return this.getResponse(response);
  };

  public getItemsForCompany = async (id: number, onlyProjects: boolean, limit: number): Promise<Array<Project | Procurement>> => {
    let response;
    let config = {
      params: {},
    };
    if (onlyProjects) {
      config = {
        params: {
          onlyProjects: onlyProjects,
        },
      };
    }
    if (limit) {
      config.params = {
        ...config.params,
        limit: limit,
      }
    }
    if (config.params) {
      response = await this.get<Array<Project>>(`${api}/items/${id}`, config);
    } else {
      response = await this.get<Array<Project | Procurement>>(`${api}/items/${id}`);
    }
    return this.getResponse(response);
  };

  public searchCompanies = async (
    companyAgent: CompanyAgent,
    index: number,
    limit: number,
    orderBy: string,
  ): Promise<{ count: number, companies: Company[] }> => {
    const response = await this.post<{ count: number, companies: Company[] }>(
      `${api}/search`, companyAgent, { params: { index, limit, orderBy }},
    );
    return this.getResponse(response);
  };

  public searchCompanyCount = async (companyAgent: CompanyAgent, maxDaysOld?: number): Promise<number> => {
    let response;
    if (maxDaysOld != null) {
      const config = {
        params: { maxDaysOld: maxDaysOld },
      };
      response = await this.post<number>(`${api}/search/count`, companyAgent, config);
    } else {
      response = await this.post<number>(`${api}/search/count`, companyAgent);
    }
    return this.getResponse(response);
  };
}

export const companyService = new CompanyService(apiConfig);

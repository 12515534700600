













































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import DropdownMailExport from '@/components/dropdowns/dropdown-mail-export.vue';
import { formatUtils } from '@/util/format-utils';
import { globalFunctions } from '@/util/global-functions';
import moment from 'moment';

@Component({
  components: {
    DropdownMailExport,
  },
})
export default class ContainerDateRow extends Vue {
  @Prop() procurementName: string;
  @Prop() text: string;
  @Prop() fromDate: string;
  @Prop() toDate: string;
  @Prop() address: string;
  @Prop() note: string;
  @Prop() link: string;
  @Ref() daterowdata: HTMLSlotElement;

  get downloadName(): string {
    return `${this.procurementName.replaceAll(' ', '_')}.ics`
  }

  get textPrefix(): string {
    return this.fromDate && this.toDate ? '' : this.fromDate ? 'Start: ' : 'Slut: ';
  }

  get from(): moment.Moment {
    return (this.fromDate && moment(this.fromDate) || moment(this.toDate)).locale('da');
  }

  get to(): moment.Moment {
    return (this.toDate && moment(this.toDate) || moment(this.fromDate)).locale('da');
  }

  get computedLink(): string {
    return this.isBasicProduct ? 'https://fakelink.dk/thisisnotareallink?isthisreal=no' : this.link;
  }

  get isBasicProduct(): boolean {
    return !this.$vmx.user.hasProduct;
  }

  get paywallModalId() {
    return globalFunctions.getPaywallModalId();
  }

  get mailHeader(): string {
    return `${this.procurementName} (${this.textPrefix}${this.$t(this.text)})`
  }

  formatDate(date: string) {
    return date;
  }

  mailBody(): string {
    return this.daterowdata ? this.daterowdata.innerText : '';
  }
}





















































import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import BaseFoldMenu from '../buttons/base-fold-menu.vue'

@Component({
  components: {
    BaseFoldMenu,
  },
})
export default class CountrySelector extends Vue {
  @VModel() countryIds: number[]
  @Prop({ default: true }) onlyDomainCountries: boolean;
  @Prop({ default: false }) disabled: boolean;

  get allCountriesLimited() {
    if (this.$vmx.country.countries) {
      const allCountries = this.$vmx.country.countries;
      const countries = this.onlyDomainCountries
        ? allCountries.filter(c => this.$vmx.domain.domains.some(d => d.countryId === c.id))
        : allCountries;
      if (countries) {
        const countryMap = countries.map((c) => {
          return {
            ids: [c.id],
            name: c.name,
          }
        });
        if (this.onlyDomainCountries) {
          // TODO: When we have more foreign companies, uncomment this code
          // countryMap.push({
          //   ids: allCountries.filter(c => !countries.some(c2 => c2.id === c.id)).map(c => c.id),
          //   name: this.$tc('general.other', 2) as string,
          // })
        } else {
          allCountries.forEach(c => {
            countryMap.push({
              ids: [c.id],
              name: c.name,
            });
          })
        }
        return countryMap;
      }
    }
    return [];
  }

  countryClicked(countryMap: {ids: number[], name: string}) {
    let newCountryIds = [...this.countryIds];
    if (countryMap.ids.some(id => newCountryIds.includes(id))) {
      newCountryIds = newCountryIds.filter(cid => !countryMap.ids.includes(cid));
    } else {
      newCountryIds = newCountryIds.concat(countryMap.ids);
    }
    this.countryIds = [...new Set(newCountryIds)]; // unique values
  }

  isSelected(countryMap: {ids: number[], name: string}): boolean {
    return countryMap.ids.every(id => this.countryIds.includes(id));
  }
}





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseFoldMenu from '../buttons/base-fold-menu.vue';
import { Criterion } from '@/interfaces/criterias/criterion';


@Component({
  name: 'CriterionTree',
  components: {
    CriterionTree,
    BaseFoldMenu,
  },
})
export default class CriterionTree extends Vue {
  @Prop({ required: true }) checkedCriteria: Criterion[];
  @Prop({ required: true }) typeId: number;
  @Prop({ required: true }) criterionOptions: Criterion[];
  @Prop({ default: null }) isDisabled: (criterion: Criterion) => boolean;

  @Prop({ default: 2 }) yPadding: number;

  get criteriaCountRecord() {
    return this.$vmx.search.searchItemPage?.criterionCount ?? null;
  }

  updateCriteriaTre(newCheckedCriteria: Criterion[], typeId: number) {
    this.$emit('input', newCheckedCriteria, typeId);
  }

  getCriterionCount(criterion: Criterion): number {
    if (criterion.children == null || criterion.children.length == 0) {
      return this.criteriaCountRecord != null && this.criteriaCountRecord[criterion.id] != null
        ? this.criteriaCountRecord[criterion.id]
        : 0
    }

    return criterion.children?.reduce(
      (acc, child) => acc + this.getCriterionCount(child),
      this.criteriaCountRecord != null && this.criteriaCountRecord[criterion.id] != null
        ? this.criteriaCountRecord[criterion.id]
        : 0,
    );
  }

  isChecked(criterion: Criterion): boolean {
    return this.checkedCriteria.some((criterion0) => criterion0.id === criterion.id);
  }

  click(criterion: Criterion) {
    const isChecked = this.isChecked(criterion);

    const subs = this.findChildren(criterion).filter(
      (sub) => this.isChecked(sub) === isChecked,
    );

    let newCheckedCriteria: Criterion[] = [];
    if (isChecked) {
      newCheckedCriteria = this.checkedCriteria.filter((criteria0) =>
        !subs.some((sub) => sub.id === criteria0.id),
      )
    } else {
      newCheckedCriteria = [ ...this.checkedCriteria, ...subs ];
    }

    this.$emit('input', newCheckedCriteria, this.typeId);
  }

  private findChildren(criteria: Criterion): Criterion[] {
    const children = criteria?.children?.flatMap(this.findChildren);
    return [ criteria, ...(children ?? [])]
  }

}

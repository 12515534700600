

































import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { Note } from '@/interfaces/note';
import NoteList from '@/components/general-components/note-list.vue';


@Component({
  components: {
    NoteList,
  },
})
export default class DropdownNotes extends Vue {
  @VModel({ required: true }) selectedNote: Note;
  @Prop({ required: true }) notes: Note[];
  @Prop({ required: true }) id: string;
  @Prop({ default: 'sm' }) size: string;
  @Prop({ default: 'sm' }) dropdownSize: string;
  @Prop({ default: 'xs' }) iconSize: string;
  @Prop({ default: 'primary' }) variant: string;
  @Prop({ default: false, type: Boolean }) noFlip: boolean;
  @Prop() label: string;
  @Prop({ default: false }) rightAlign: boolean;
}

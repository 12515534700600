























































































































import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import BaseDropdown from '@/components/dropdowns/base-dropdown.vue';
import moment from 'moment-timezone';

@Component({
  components: {
    BaseDropdown,
  },
})
export default class AgentCriteriaQuarterSelector extends Vue {
  @VModel() data: string[];
  @Prop({ required: true }) id: string;

  showExtra = false;

  addNewYear(year: string) {
    this.setQuarters(year, ['Q1', 'Q2', 'Q3', 'Q4'])
    this.showExtra = false;
  }

  setYear(oldYear: string, newYear: string) {
    this.data = this.data.map(
      (val) => val.slice(3, 7) == oldYear ? `${val.slice(0, 2)} ${newYear}` : val,
    )
  }

  remove(year: string) {
    this.data = this.data.filter((val) => val.slice(3, 7) !== year);
  }

  get getShowExtra() {
    return this.showExtra || this.data == null || this.data.length == 0;
  }

  get years() {
    return this.data.map((val) => val.slice(3, 7)).reduce(
      (acc, val) => acc.some((val0) => val0 === val) ? acc : [val, ...acc],
      [] as string[],
    ).sort((a, b) => a < b ? 1 : -1);
  }

  get yearOptions(): string[] {
    const currentYear = parseInt(moment().format('YYYY')) - 3;
    return Array.from(Array(23).keys())
      .map((i) => currentYear + i)
      .map((year) => year.toString())
      .filter((year) => this.years.every((year0) => year0 != year));
  }

  get quartersOptions() {
    return ['Q1', 'Q2', 'Q3', 'Q4'] as const;
  }

  getQuarters(year: string): ('Q1' | 'Q2' | 'Q3' | 'Q4')[] {
    return this.data
      .filter((val) => val.slice(3,7) === year)
      .map((val) => val.slice(0, 2)) as ('Q1' | 'Q2' | 'Q3' | 'Q4')[];
  }

  setQuarters(year: string, quarters: ('Q1' | 'Q2' | 'Q3' | 'Q4')[]) {
    this.data = this.data
      .filter((val) => val.slice(3, 7) !== year)
      .concat(quarters.map((quarter) => `${quarter} ${year}`))
  }
}











































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseButtonIcon from '@/components/buttons/base-button-icon.vue'
import { File } from '@/interfaces/file';
import { globalFunctions } from '@/util/global-functions';
import { HelpdeskPage } from '@/interfaces/help/helpdesk-page';
import { HelpdeskTab } from '@/interfaces/help/helpdesk-tab';

@Component({
  components: { BaseButtonIcon },
})
export default class UserGuideRow extends Vue {
  @Prop({ required: true }) tab: HelpdeskTab;
  @Prop({ required: true }) page: HelpdeskPage;
  @Prop({ default: false }) active: boolean;
  @Prop({ required: true }) setCurrentPage: (id: number) => void;

  loopHandler(ref: string) {
    globalFunctions.timeout(5000).then(() => {
      if ((this.$refs[ref] as HTMLVideoElement[]) && (this.$refs[ref] as HTMLVideoElement[])[0]) {
        (this.$refs[ref] as HTMLVideoElement[])[0].play();
      }
    });
  }

  imageFromFile(file: File) {
    const getFromFile = this.$vmx.file.fromFile;
    if (file) {
      return file.link ? file.link : getFromFile(`${file.code}.${file.extension}`);
    } else {
      return getFromFile(undefined);
    }
  }
}










































import 'scroll-shadow-element';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { globalFunctions } from '@/util/global-functions';

@Component
export default class HorizontalDraggable extends Vue {
  @Ref() slider: HTMLElement;
  @Ref() leftPaddle: HTMLElement;
  @Ref() rightPaddle: HTMLElement;
  @Prop({default: false, type: Boolean}) hiddenScrollbar: boolean;
  @Prop({default: false, type: Boolean}) noLeftShadow: boolean;
  @Prop({ default: true }) grab: boolean;
  sliderDown = false;
  public dragging = false;
  startX: number;
  scrollLeft: number;

  mounted() {
    this.addSliderEventListeners();
    window.addEventListener('resize', this.resizeHandler);
    this.displayPaddles(this.slider.scrollLeft);
  }

  addSliderEventListeners() {
    this.slider.addEventListener('mousedown', (e) => {
      if (e.offsetY > this.slider.clientHeight) {
        return;
      }
      this.sliderDown = true;
      this.startX = e.pageX - this.slider.offsetLeft;
      this.scrollLeft = this.slider.scrollLeft;
      this.slider.classList.add('active');
    });

    this.slider.addEventListener('mouseleave', () => {
      globalFunctions.timeout(50).then(() => {
        this.sliderDown = false;
        this.toggleDragging(false);
        this.slider.classList.remove('active');
      });
    });

    this.slider.addEventListener('mouseup', () => {
      globalFunctions.timeout(50).then(() => {
        this.sliderDown = false;
        this.toggleDragging(false);
        this.slider.classList.remove('active');
        this.displayPaddles(this.slider.scrollLeft);
      });
    });

    this.slider.addEventListener('mousemove', (e) => {
      if (!this.sliderDown) {
        return;
      }
      if (!this.dragging) {
        this.toggleDragging(true);
      }
      e.preventDefault();
      const x = e.pageX - this.slider.offsetLeft;
      const walk = x - this.startX;
      this.slider.scrollLeft = this.scrollLeft - walk;
    });
  }

  private toggleDragging(isDragging: boolean) {
    this.dragging = isDragging;
    this.$emit('dragging', isDragging);
  }

  public resizeHandler() {
    if (this.slider) {
      this.displayPaddles(this.slider.scrollLeft)
    }
  }

  get styleClasses() {
    return [
      this.grab ? 'grabbable' : '',
      this.hiddenScrollbar ? 'hidden-scrollbar' : '',
    ];
  }

  paddleClicked(isLeft: boolean) {
    const startX = this.slider.scrollLeft;
    let scrollX = startX + (isLeft ? -1 : 1) * 500;
    scrollX = scrollX < 0 ? 0 : scrollX;
    this.slider.scroll({
      left: scrollX,
      behavior: 'smooth',
    });

    this.displayPaddles(scrollX);
  }

  displayPaddles(scrollX: number) {
    if (scrollX <= 0) {
      if (!this.leftPaddle.classList.contains('d-none')) {
        this.leftPaddle.classList.add('d-none');
      }
    } else {
      this.leftPaddle.classList.remove('d-none');
    }

    const scrollEnd = this.slider.scrollWidth - this.slider.clientWidth;
    if (scrollX >= scrollEnd) {
      if (!this.rightPaddle.classList.contains('d-none')) {
        this.rightPaddle.classList.add('d-none');
      }
    } else {
      this.rightPaddle.classList.remove('d-none');
    }
  }
}





















































































import { Component, Vue } from 'vue-property-decorator';
import { Domain } from '@/interfaces/domain';
import ProductBox from '@/components/general-components/product-box.vue';
import { ProductFeature } from '@/interfaces/product-feature';
import { Tooltip } from 'bootstrap';

@Component ({
  components: {
    ProductBox,
  },
})
export default class ProductTable extends Vue {
  selectedDomains: { selected: boolean, domain: Domain }[] = [];
  selectedFeatures: { selected: boolean, feature: ProductFeature }[] = [];

  mounted() {
    const domainId = this.$vmx.domain.domain.id;
    this.selectedDomains = this.$vmx.domain.domains.map(domain => {
      return { selected: domain.id === domainId, domain };
    });
    this.selectedDomains.sort((a,b) => a.domain.id === domainId ? -1 : b.domain.id === domainId ? 1 : 0);

    this.$vmx.product.fetchProductsFeatures().then((features) => {
      this.selectedFeatures = features
        .filter(feature => feature.filter)
        .map(feature => {
          return { selected: true, feature };
        });
      this.selectedFeatures.sort((a,b) => a.feature.index < b.feature.index ? -1 : 1);
      this.setTooltip();
    });
  }

  get filteredProducts() {
    return this.$vmx.product.products.filter(product => {
      for (const selectedDomain of this.selectedDomains) {
        if (!selectedDomain.selected && product.domains.some(domain => domain.id === selectedDomain.domain.id)) {
          return false;
        }
      }
      for (const selectedFeature of this.selectedFeatures) {
        if (!selectedFeature.selected && product.features.some(feature => feature.id === selectedFeature.feature.id)) {
          return false;
        }
      }
      return true;
    });
  }

  getDomainImgClasses(domainId: number) {
    const index = this.selectedDomains.findIndex(selectedDomain => selectedDomain.domain.id === domainId);
    return {
      'opacity-25': !this.selectedDomains[index].selected,
    };
  }

  getDomainSVG(tld: string) {
    return {
      backgroundImage: `url('${require('@/assets/flags/' + tld + '.svg')}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
  }

  setDomainSelected(domainId: number) {
    const index = this.selectedDomains.findIndex(selectedDomain => selectedDomain.domain.id === domainId)
    this.selectedDomains[index].selected = !this.selectedDomains[index].selected;
  }

  setTooltip() {
    this.$nextTick(() => {
      for (const selectedFeature of this.selectedFeatures) {
        const popperElement = document.getElementById(`tooltip-${selectedFeature.feature.id}`);
        if (popperElement) {
          new Tooltip(popperElement, {
            placement: 'top',
            trigger: 'hover',
          });
        }
      }
    })
  }

  showAll() {
    this.selectedDomains.forEach(selectedDomain => selectedDomain.selected = true);
    this.selectedFeatures.forEach(selectedFeature => selectedFeature.selected = true);
  }

}

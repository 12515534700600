import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { LocaleMessages } from 'vue-i18n';

const api = '/translations';

export class TranslationService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getTranslations = async (id: number): Promise<LocaleMessages> => {
    const response = await this.get<LocaleMessages>(`${api}/${id}`);
    return this.getResponse(response);
  };
}

export const translationService = new TranslationService(apiConfig);













































































































import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component
export default class PageNavigationBar extends Vue {
  @VModel() page: number;
  @Prop({ required: true }) pageCount: number;
  // @Prop({ required: true }) page: SearchItemPage;

  emitPageChange(newPage: number) {
    this.page = newPage;
    this.$emit('pageChanged', newPage);
  }

  underlineIfCurrentPage(number: number) {
    return this.page == number ? 'underline' : ''
  }
}










































































































import { Component, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { Company } from '@/interfaces/company';
import { CompanyList } from '@/interfaces/company-list';
import { Modal } from 'bootstrap';
import { ModalIds } from '@/util/enums/modal-ids';

@Component({
  components: {
    AbstractForm,
    BaseModalContainer,
  },
})
export default class ModalCompanyListAdd extends Vue {
  chosenCompanyList: CompanyList = null;
  originalCompanyLists: CompanyList[] = null;
  loading = true;
  submitting = false;
  error = false;
  loadingError = false;

  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  get buttonRowClasses() {
    return [
      'justify-content-end',
      `justify-content-sm-${this.originalCompanyLists ? 'between' : 'end'}`,
    ];
  }

  get company(): Company {
    return this.$vmx.modal.company;
  }

  get modalId(): string {
    return ModalIds.COMPANY_LIST_ADD_MODAL;
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal();
    }
  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }

  initializeModal() {
    this.loading = true;
    if (!this.company) {
      this.hideModal();
      return;
    }
    this.originalCompanyLists = null;
    this.$vmx.companyList.fetchCompanyLists().then(() => {
      if (this.$vmx.companyList.companyLists != null && this.$vmx.companyList.companyLists.length > 0) {
        const listsWithCompany = this.$vmx.companyList.companyListsWithCompany(this.company);
        if (listsWithCompany != null && listsWithCompany.length > 0) {
          this.chosenCompanyList = listsWithCompany[0];
          this.originalCompanyLists = listsWithCompany;
        } else {
          this.chosenCompanyList = this.$vmx.companyList.companyLists[0];
        }
      }
    }).catch(() => {
      this.loadingError = true;
    }).finally(() => this.loading = false);
  }

  openCompanyListRemoveModal() {
    this.$vmx.modal.openCompanyListRemoveModal(this.company);
  }

  openCompanyListSettingsModal() {
    this.$vmx.modal.openCompayListSettingsModal({ companyList: null, company: this.company })
  }

  async submit() {
    if (this.submitting) {
      return;
    }
    this.submitting = true;
    if (
      !this.originalCompanyLists == null
      && !this.originalCompanyLists.some(companyList => companyList.id != this.chosenCompanyList.id)
    ) {
      this.submitting = false;
      return;
    }

    const companyListsToUpdate: CompanyList[] = [];

    if (this.originalCompanyLists && this.originalCompanyLists.length > 0) {
      const removedLists = this.originalCompanyLists
        .filter(companyList =>
          companyList.id != this.chosenCompanyList.id,
        );
      removedLists.forEach((companyList) => {
        companyList.companies = companyList.companies.filter(company => company.id != this.company.id);
        companyListsToUpdate.push(companyList);
      });
    }

    if (
      this.chosenCompanyList.companies
      && !this.chosenCompanyList.companies.some(company => company.id === this.company.id)
    ) {
      this.chosenCompanyList.companies.push({
        id: this.company.id,
        name: this.company.companyName,
      })
      companyListsToUpdate.push(this.chosenCompanyList);
    }

    try {
      for (const companyListToUpdate of companyListsToUpdate) {
        const companyList = await this.$vmx.companyList.putCompanyList(companyListToUpdate);
        if (companyList) {
          this.error = false;
        } else {
          this.error = true;
          break;
        }
      }
      if (!this.error) {
        this.hideModal();
      }
      this.submitting = false;
    } catch {
      this.error = true;
      this.submitting = false;
    }
  }
}

import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { CompanyAgent } from '@/interfaces/search/company-agent';

const api = '/company-agents';

export class CompanyAgentService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getCompanyAgent = async (id: number): Promise<CompanyAgent> => {
    const response = await this.get<CompanyAgent>(`${api}/${id}`);
    return this.getResponse(response);
  }

  public getAllCompanyAgentsForUser = async (): Promise<CompanyAgent[]> => {
    const response = await this.get<CompanyAgent[]>(api);
    return this.getResponse(response);
  }

  public createCompanyAgent = async (companyAgent: CompanyAgent): Promise<CompanyAgent> => {
    const response = await this.post<CompanyAgent>(api, companyAgent);
    return this.getResponse(response);
  }

  public updateCompanyAgent = async (companyAgent: CompanyAgent): Promise<CompanyAgent> => {
    const response = await this.put<CompanyAgent>(api, companyAgent);
    return this.getResponse(response);
  }

  public deleteCompanyAgent = async (id: number): Promise<void> => {
    const response = await this.delete<void>(`${api}/${id}`);
    return this.getResponse(response);
  }
}

export const companyAgentService = new CompanyAgentService(apiConfig);
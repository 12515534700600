




























import { Component, VModel, Vue } from 'vue-property-decorator';
import { MailTime } from '@/interfaces/enums/mail-time';


@Component
export default class FormMailTime extends Vue {
  @VModel() mailTime: MailTime;
}

import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseLogo from '@/components/buttons/base-logo.vue';
import { formatUtils } from '@/util/format-utils';
import { globalFunctions } from '@/util/global-functions';
import { LocaleMessage } from 'vue-i18n';
import { Note } from '@/interfaces/note';
import PrintNote from '@/components/print/print-note.vue';
import PrintRow from '@/components/print/print-row.vue';
import PrintSection from '@/components/print/print-section.vue';
import { Project } from '@/interfaces/project';
import { ProjectPhaseRecord } from '@/interfaces/project-phase-record';
import { projectService } from '@/services/restapi/project-service';
import { Update } from '@/interfaces/update';
import { updateService } from '@/services/restapi/update-service';

@Component({
  components: {
    BaseLogo,
    PrintSection,
    PrintRow,
    PrintNote,
  },
})
export default class PrintProjectView extends Vue {
  @Prop() id: string;

  data: Project = null;

  loadedImages: number;

  isLoading = true;

  created() {
    window.onafterprint = () => {
      this.$router.go(-1);
      this.$nextTick(() => {
        // window.print();
      });
    };
  }

  mounted() {
    this.fetchData().then(async (data) => {
      this.data = data;
      this.loadedImages = 0;

      setTimeout(() => {
        const numberOfImages = this.data.files.filter((v) => v.image != null).length;

        this.printIfImageLoaded(numberOfImages);
      }, 1000);
    });
  }

  printIfImageLoaded(numberOfImages: number) {
    setTimeout(() => {
      if (this.loadedImages >= numberOfImages) {
        this.isLoading = false;
        this.$nextTick().then(() => {
          window.print();
        });
      } else {
        this.printIfImageLoaded(numberOfImages);
      }
    }, 200);
  }

  async fetchData(): Promise<Project> {
    const project = await projectService.getProjectById(Number.parseInt(this.id));
    return project;
  }

  formatInfo(data: string): { data: string; class: string[] } {
    if (data == null) {
      return null;
    }
    return { data: data, class: ['mb-1'] };
  }

  formatUrl(data: string): { data: string; class: string[] } {
    return { data: data, class: ['mb-1', 'text-underlined', 'text-primary', 'text-underlined'] };
  }

  formatDate(date: string) {
    return date != null ? formatUtils.formatDate(date, 'DD.MM.YYYY') : 'N/A';
  }

  formatDates(date1: string, date2: string) {
    if (date1 == null && date2 == null) {
      return null;
    }
    return `${this.formatDate(date1)} - ${this.formatDate(date2)}`;
  }

  // NAVN & LOGO
  getLogo(): string {
    return this.$vmx.file.fromFile('project-agent/logo-210x50.svg');
  }

  // Date
  get hasDateData() {
    return this.getDateData != null && this.getDateData.length != 0;
  }

  get getDateData(): { label: LocaleMessage; datas: { data: string; class: string[] }[] }[] {
    return [
      { label: this.startEndLabel, datas: this.startEndData },
      { label: this.$tc('general.latestUpdate', 1), datas: this.lastUpdateDateData },
      { label: this.$t('general.published'), datas: this.publishedData },
    ].filter((v) => v.datas != null && v.datas.length != 0);
  }

  get startEndLabel(): LocaleMessage {
    if (this.data.start != null && this.data.end != null) {
      return this.$t('general.criteria.startEnd');
    } else if (this.data.start != null) {
      return this.$t('general.criteria.startEndOptions.start');
    } else if (this.data.end != null) {
      return this.$t('general.criteria.startEndOptions.end');
    } else {
      return null;
    }
  }

  get startEndData(): { data: string; class: string[] }[] {
    let startEnd: string[];

    if (
      this.data.start != null &&
      this.data.start.length != 0 &&
      this.data.end != null &&
      this.data.end.length != 0
    ) {
      startEnd = [this.data.start + ' - ' + this.data.end];
    } else if (this.data.start != null && this.data.start.length != null) {
      startEnd = [this.data.start];
    } else if (this.data.end != null && this.data.end.length != null) {
      startEnd = [this.data.end];
    }

    return startEnd?.map(this.formatInfo);
  }

  get lastUpdateDateData(): { data: string; class: string[] }[] {
    const data = this.data.lastUpdateDate;
    if (data == null) {
      return null;
    }
    return [this.formatInfo(data)];
  }

  get publishedData(): { data: string; class: string[] }[] {
    const data = this.data.published;
    if (data == null) {
      return null;
    }
    return [this.formatInfo(data)];
  }

  // Information
  get hasInformationData(): boolean {
    return this.informationData != null && this.informationData.length != 0;
  }

  get informationData(): { label: LocaleMessage; datas: { data: string; class: string[] }[] }[] {
    const information = [];
    for (const criterionType of this.$vmx.criterion.standardCriterionTypes) {
      information.push({
        label: this.$tc(criterionType.translationProperty, 2),
        datas: this.getCriterionDataForType(criterionType.id),
      });
    }
    information.push({ label: this.$t('general.criteria.estimatedSum'), datas: this.budgetData });
    return information.filter((v) => v.datas != null && v.datas.length > 0);
  }

  get budgetData() {
    if (this.data.budget == null) {
      return null;
    }
    const data = this.data.budget.toLocaleString('da') + 'kr.';
    return [this.formatInfo(data)];
  }

  // PHASE RECORDS
  get phaseRecords(): ProjectPhaseRecord[] {
    return this.data.projectPhaseRecords;
  }

  getCriterionDataForType(typeId: number) {
    const data = this.data.criteria?.filter(criterion => criterion.typeId === typeId)
      .map((criterion) => criterion.name).join(', ') ?? null;
    return data ? [this.formatInfo(data)] : null;
  }

  getIconOrder(record: ProjectPhaseRecord): number {
    return record.projectPhase.id - this.data.projectPhase.id;
  }

  getCircleStyle(record: ProjectPhaseRecord) {
    return {
      width: this.getIconOrder(record) == 0 ? '60px' : '32px',
      height: this.getIconOrder(record) == 0 ? '60px' : '32px',
      'border-radius': '50%',
      'border-color': 'trademark',
      'background-color': 'trademark',
    };
  }

  // Roles
  getWinnerFields() {
    const data = [
      this.$t('pages.procurement.winners.companyField'),
      this.$t('pages.procurement.winners.roleField'),
      this.$t('components.modals.role.contact'),
    ];
    return data;
  }

  getWinnersItems() {
    const data = this.data.companyRoles;
    if (data == null || data.length == 0) {
      return null;
    }
    return data;
  }

  // Notes
  get hasNoteData(): boolean {
    return this.noteData != null && this.noteData.length != 0;
  }
  get noteData(): Note[] {
    return this.data.notes.filter(
      (note) =>
        note != null &&
        note.title != null &&
        note.title.length != 0 &&
        note.text != null &&
        note.text.length != 0,
    );
  }

  get noteDataSize(): boolean {
    return (
      this.data.notes.length > 3 ||
      this.data.notes.map((note) => note.text).reduce((acc, val) => val + acc, '').length > 900
    );
  }

  // Picture
  get picturesData() {
    // return this.data.files.filter((v) => v.image != null);
    const pictures = this.data.files.filter((v) => v.image != null);
    const newpicture = [];
    while (pictures.length > 0) {
      newpicture.push([pictures.pop(), pictures.pop()]);
    }
    return newpicture;
  }

  onLoad(bla: any) {
    this.loadedImages += 1;
  }
}
































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseInputField from '@/components/form-fields/base-input-field.vue';
import BaseInputTextarea from '@/components/form-fields/base-input-textarea.vue';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { Modal } from 'bootstrap';
import { Note } from '@/interfaces/note';
import { noteService } from '@/services/restapi/note-service';
import { User } from '@/interfaces/user';

@Component({
  components: {
    AbstractForm,
    BaseModalContainer,
  },
})
export default class ModalNoteEdit extends Vue {
  @Prop({ required: true }) modalId: string;
  @Prop({ default: null }) initialNote: Note;
  @Prop() projectId: number;
  @Prop() procurementId: number;
  @Prop() companyId: number;
  @Ref() noteTitle: BaseInputField;
  @Ref() noteText: BaseInputTextarea;
  note: Note = null;
  users: User[] = [];
  loading = true;
  submitting = false;
  error = false;

  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  initializeModal() {
    this.loading = true;
    this.note = this.initialNote ? {...this.initialNote} : null;
    const user = this.$vmx.user.user;
    this.users = this.$vmx.user.colleagues;
    if (!this.note) {
      this.note = {
        projectId: this.projectId,
        procurementId: this.procurementId,
        companyId: this.companyId,
        createdBy: user.name,
        title: null,
        text: null,
        paUserIds: [user.id],
        created: null,
        updated: null,
      };
    } else {
      this.note.paUserIds = [...this.users].map(u => u.id);
    }
    this.loading = false;
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal(this.modalId);
    }
  }

  get buttonRowClasses() {
    return [
      `justify-content-${this.note?.id ? 'between' : 'end'}`,
    ];
  }

  get formIsValid() {
    return this.noteText.validate();
  }

  get validateNote(): boolean {
    if (this.note) {
      return this.note.paUserIds
        && this.note.paUserIds.length > 0
        && !!this.note.text
        && this.note.text.trim().length > 0;
    }
    return false;
  }

  async submit() {
    if (this.formIsValid) {
      this.submitting = true;
      try {
        const newNote: Note = this.note.id && this.note.id !== undefined
          ? await noteService.updateNote(this.note)
          : await noteService.createNote(this.note);
        // await this.$vmx.list.refetchLists();
        this.$emit('noteSaved', newNote);
        this.error = false;
        this.submitting = false;
        this.hideModal(this.modalId);
      } catch (error) {
        this.error = true;
        this.submitting = false;
      }
    }
  }

  openDeleteModal() {
    this.$emit('openDeleteModal', this.note.id);
  }

  hideModal(modal: string) {
    const element = document.getElementById(modal);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }
}

import { Component, Vue } from 'vue-property-decorator';
import ContactOptions from '@/components/general-components/contact-options.vue';
import TheHeader from '@/components/headers/the-header.vue';

@Component({
  components: {
    ContactOptions,
    TheHeader,
  },
})
export default class ContactView extends Vue {}
































import { Component, Vue } from 'vue-property-decorator';
import BaseFoldMenu from '../buttons/base-fold-menu.vue'
import CountrySelector from './country-selector.vue';
import CriteriaTree from './criteria-tree.vue'
import { Role } from '@/interfaces/criterias/role';

@Component({
  components: {
    BaseFoldMenu,
    CountrySelector,
    CriteriaTree,
  },
})
export default class CompanyCriteriaMenu extends Vue {
  get canReset(): boolean {
    return !this.$vmx.searchCompany.isSearchEmpty;
  }

  get roles() {
    return this.$vmx.searchCompany.companyAgent.roles;
  }

  set roles(roles: Role[]) {
    this.$vmx.searchCompany.updateCompanyAgent({
      id: null,
      roles,
    });
  }

  get countryIds(): number[] {
    return this.$vmx.searchCompany.companyAgent.countryIds;
  }

  set countryIds(countryIds: number[]) {
    this.$vmx.searchCompany.updateCompanyAgent({
      id: null,
      countryIds,
    });
  }

}

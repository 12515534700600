




























import { Component, Prop, Vue } from 'vue-property-decorator';
import DropdownProfileContent from '@/components/dropdowns/dropdown-profile-content.vue';

@Component({
  components: {
    DropdownProfileContent,
  },
})
export default class DropdownProfile extends Vue {
  @Prop({ default: 'true' }) bsAutoClose: string;

  canBeClosed = false;
}

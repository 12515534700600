













import * as domainUtils from '@/util/domain-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouterNames } from '@/util/enums/router-names';

@Component
export default class BaseLogo extends Vue {
  @Prop({default: '250px'}) width: string;
  @Prop({ default: false }) loggedIn: boolean;
  @Prop({default: false, type: Boolean}) black: boolean;

  get link (): RouterNames {
    return this.$vmx.session.isLoggedinUser ? RouterNames.LOGGED_IN_DASHBOARD : RouterNames.LOGGED_OUT_HOME;

  }

  get logoClasses() {
    return {
      'logged-in': this.loggedIn,
      'inverted': this.black,
    }
  }

  getLogo() {
    const logo = this.$vmx.domain.domain.logo;
    return this.$vmx.file.fromFile(logo ? `${logo.code}.${logo.extension}` : 'project-agent/logo-210x50_dk.svg')
  }
}































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

@Component
export default class AbstractForm extends Vue {
  @Prop({ default: false }) needsValidation: boolean;
  @Prop({ default: 5 }) gutterX: number;
  @Prop({ default: 3 }) gutterY: number;
  @Prop({ default: 'trademark'}) background: string;
  @Prop({ default: 'white'}) textColor: string;
  @Prop() styleClasses: string;
  @Prop({ default: '5' }) paddingX: string;
  @Prop({ default: '5' }) paddingY: string;
  @Prop() submitButtonText: string;
  @Prop({ default: false }) isModal: boolean;
  @Prop({ default: null }) onSubmit: () => void;
  @Ref() inputForm: HTMLFormElement;

  get formStyleClasses() {
    return [
      `bg-${this.background}`,
      this.needsValidation ? 'needs-validation' : '',
      `px-${this.paddingX}`,
      `py-${this.paddingY}`,
      `text-${this.textColor}`,
      this.styleClasses,
    ]
  }

  public setValidated(wasValidated: boolean) {
    if (wasValidated) {
      this.inputForm.classList.add('was-validated');
    } else {
      this.inputForm.classList.remove('was-validated');
    }
  }

  submitEvent(event: Event) {
    if (!this.needsValidation || this.inputForm.checkValidity()) {
      this.$emit('submit');
    }
    if (this.needsValidation) {
      this.inputForm.classList.add('was-validated');
    }
  }
}







































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Modal } from 'bootstrap';
import { Product } from '@/interfaces/product';
import ProductBox from '@/components/general-components/product-box.vue';
import { RouterNames } from '@/util/enums/router-names';

@Component({
  components: {
    ProductBox,
  },
})
export default class BasePaywall extends Vue {
  @Prop({ default: 'transparent' }) backgroundColor: string;
  @Prop() textColor: string;
  @Prop() modalId: string

  retries = 0;

  get classObj() {
    return [
      `bg-${this.backgroundColor}`,
      this.textColor ? `text-${this.textColor}` : '',
    ]
  }

  get products() {
    return this.$vmx.product.products ?? [];
  }

  gotoPrices() {
    this.hideModal();
    this.$router.push({ name: RouterNames.LOGGED_OUT_PRICES, hash: '#contact'});
  }

  hideModal() {
    if (this.modalId) {
      const element = document.getElementById(this.modalId);
      if (element) {
        const modal = Modal.getInstance(element);
        if (modal) {
          modal.hide();
        }
      }
    }
  }
}

import * as EmailValidator from 'email-validator';
import moment from 'moment';

export class FormValidator {
  constructor() {}

  validateLetterNumberString(str: string) {
    if (str.length >= 1 && str.length <= 255 && this.isNumbersAndLetters(str)) {
      return true;
    }
    return false;
  }

  validateLetterString(str: string) {
    if (this.isBetween1And255Characters(str) && this.isOnlyLetters(str)) {
      return true;
    }
    return false;
  }

  validatePhoneNumber(str: string) {
    const reg = new RegExp('^([+0-9 ]*)$');
    return reg.test(str) && this.isBetween1And255Characters(str);
  }

  isBetween1And255Characters(str: string) {
    return str.length >= 1 && str.length <= 255;
  }

  isNumbersAndLetters(str: string) {
    const reg = new RegExp('^([a-zæøåA-ZÆØÅ0-9 ]*)$');
    return reg.test(str);
  }

  isOnlyLetters(str: string) {
    const reg = new RegExp('^([a-zæøåA-ZÆØÅ ]*)$');
    return reg.test(str);
  }

  validateEmail(mail: string) {
    return EmailValidator.validate(mail);
  }

  validateDate(date: string) {
    return (
      moment(date, 'DD-MM-yyyy', true).isValid() ||
      moment(date, 'yyyy-MM-DD', true).isValid() ||
      moment(date, 'yyyy.MM.DD', true).isValid() ||
      moment(date, 'DD.MM.yyyy', true).isValid()
    );
  }

  validateTime(time: string) {
    return moment(time, 'HH:mm:ss', true).isValid() || moment(time, 'HH:mm', true).isValid();
  }

  timeIsBetween(time: string, min: string, max: string) {
    return (
      moment(time, 'HH:mm:ss', true).isSameOrAfter(moment(min, 'HH:mm:ss')) &&
      moment(time, 'HH:mm:ss', true).isSameOrBefore(moment(max, 'HH:mm:ss'))
    );
  }
}

export const formValidator = new FormValidator();

import da from '../../resources/translations/lang-da.json';
import en from '../../resources/translations/lang-en.json';
import sv from '../../resources/translations/lang-sv.json';
// import MessagesType from './locales/message-type';

const messages = {
  da,
  en,
  sv,
};

export default messages;






























































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import { Agent } from '@/interfaces/agent';
import BaseInputField from '../form-fields/base-input-field.vue';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import FormMailTime from '@/components/forms/form-mail-time.vue';
import { Modal } from 'bootstrap';
import { ModalIds } from '@/util/enums/modal-ids';
import { RouterNames } from '@/util/enums/router-names';
import { User } from '@/interfaces/user';

@Component({
  components: {
    AbstractForm,
    BaseModalContainer,
    FormMailTime,
  },
})
export default class ModalAgentSettings extends Vue {
  // @Prop({ required: true }) modalId: string;
  // @Prop({ required: true }) agent: Agent;
  @Ref() agentName: BaseInputField;

  agentCopy: Agent = null;
  users: User[] = [];
  selectedUsers: User[] = this.$vmx.user.colleagues;
  loading = true;
  submitting = false;
  error = false;

  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  initializeModal() {
    this.loading = true;
    if (!this.agent) {
      this.hideModal();
      return;
    }
    this.agentCopy = {...this.agent};
    this.setUsers();
    this.loading = false;
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal();
    }
  }

  get modalId(): string {
    return ModalIds.AGENT_SETTINGS_MODAL;
  }

  get agent(): Agent {
    return this.$vmx.modal.agent;
  }

  get formIsValid() {
    return this.agentName.validate() && this.selectedUsers.length > 0;
  }

  get validAgentName(): boolean {
    return this.agentCopy.name.trim().length >= 1 && this.agentCopy.name.trim().length <= 100;
  }

  get buttonRowClasses() {
    return [
      `justify-content-${this.agentCopy.id ? 'between' : 'end'}`,
    ];
  }

  private setUsers() {
    const user = this.$vmx.user.user as User;
    this.users = this.$vmx.user.colleagues;
    if (this.agentCopy.id) {
      this.selectedUsers = this.users.filter(u => this.agentCopy.paUserIds.some(userId => userId === u.id));
    } else {
      this.selectedUsers = [user];
    }
  }

  @Watch('selectedUsers')
  selectedUsersChanged() {
    this.agentCopy.paUserIds = this.selectedUsers.map(u => u.id);
  }

  async submit() {
    if (this.formIsValid) {
      try {
        this.submitting = true;
        // const newAgent = await this.$vmx.agent.pushAgent(this.agentCopy);
        const newAgent = await this.$vmx.agent.pushAgent(this.agentCopy);

        const saveAgent = { ...this.agentCopy, id: newAgent.id }

        if (this.$route.name === RouterNames.LOGGED_IN_SEARCH) {
          this.$vmx.search.setAgent(saveAgent);
        }
        this.error = false;
        this.submitting = false;
        this.hideModal();
      } catch (error) {
        this.error = true;
        this.submitting = false;
      }
    }
  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }

  openAgentDeleteModal() {
    this.$vmx.modal.openAgentDeleteModal(this.agent);
  }
}




































































import { Component, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { CompanyAgent } from '@/interfaces/search/company-agent';
import { Modal } from 'bootstrap';
import ModalAgentSettings from '@/components/modals/modal-agent-settings.vue';
import { ModalIds } from '@/util/enums/modal-ids';

@Component({
  components: {
    AbstractForm,
    BaseModalContainer,
    ModalAgentSettings,
  },
})
export default class ModalAgentReplace extends Vue {
  chosenCompanyAgent: CompanyAgent = null;
  loading = true;
  submitting = false;
  error = false;


  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  get companyAgent(): CompanyAgent {
    return this.$vmx.modal.companyAgent;
  }

  get companyAgents(): CompanyAgent[] {
    return this.$vmx.companyAgent.companyAgents;
  }

  get modalId(): string {
    return ModalIds.COMPANY_AGENT_REPLACE_MODAL;
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal();
    }
  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }

  initializeModal() {
    this.loading = true;
    if (!this.companyAgent) {
      this.hideModal();
      return;
    }
    if (this.companyAgents != null && this.companyAgents.length > 0) {
      this.chosenCompanyAgent = this.companyAgents[0];
    }
    this.loading = false;
  }

  openCompanyAgentSettingsModal() {
    this.$vmx.modal.openCompanyAgentSettingsModal(this.companyAgent);
  }

  async submit() {
    this.submitting = true;
    try {
      let updatedCompanyAgent = {...this.companyAgent};
      updatedCompanyAgent.name = this.chosenCompanyAgent.name;
      updatedCompanyAgent.id = this.chosenCompanyAgent.id;
      updatedCompanyAgent.mailTime = this.chosenCompanyAgent.mailTime;
      updatedCompanyAgent.paUserIds = this.chosenCompanyAgent.paUserIds;

      this.$vmx.companyAgent.pushCompanyAgent(updatedCompanyAgent);

      this.error = false;
      this.submitting = false;
      this.$emit('companyAgentSaved', updatedCompanyAgent);
      this.hideModal();
    } catch (error) {
      this.error = true;
      this.submitting = false;
    }
  }
}

import * as blacklistUtil from '@/util/blacklist-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Blacklist } from '@/interfaces/blacklist';
import { blacklistService } from '@/services/restapi/blacklist-service';
import { globalFunctions } from '@/util/global-functions';
import ListItemCard from '@/components/cards/list-item-card.vue';
import { Procurement } from '@/interfaces/procurement';
import { Project } from '@/interfaces/project';
import SearchItemsListView from '@/components/search-components/search-items-list-view.vue';
import SmallListLayout from '@/components/layouts/small-list-layout.vue';

@Component({
  components: {
    ListItemCard,
    SearchItemsListView,
    SmallListLayout,
  },
})
export default class BlacklistView extends Vue {
  public blacklistedItems: (Project | Procurement)[] = null;
  public filterText = '';
  public listView = true;
  loading = true;

  mounted() {
    blacklistService.getAllProcurementsAndProjects().then((value) => {
      this.blacklistedItems = [...value];
      this.loading = false;
    });
  }

  get blacklists(): Blacklist[] {
    return this.$vmx.blacklist.userBlacklists;
  }

  get spin() {
    return this.blacklistedItems == null || this.blacklists == null;
  }

  get itemResults() {
    if (this.filterText) {
      return this.blacklistedItems.filter((item) =>
        item.name.toLowerCase().includes(this.filterText.toLowerCase()),
      );
    } else {
      return this.blacklistedItems;
    }
  }

  get emptyListText() {
    if (this.blacklistedItems != null) {
      return null;
    }
    if (this.filterText.length != 0) {
      return this.$t('components.sorting.noResults', [this.filterText]);
    }
    return 'Du har ikke nogle skjulte udbud eller projekter.';
  }

  @Watch('$vmx.blacklist.blacklists')
  blacklistRemoved(blacklist: Blacklist) {
    this.blacklistedItems = this.blacklistedItems?.filter(
      (item) =>
        item.id !==
        (globalFunctions.isProcurement(item) ? blacklist.procurementId : blacklist.projectId),
    ) ?? [];
    this.$vmx.search.resetPage();
  }

  filterFunc(val: Procurement | Project): boolean {
    return blacklistUtil.isItemInBlacklists(val, this.$vmx.blacklist.userBlacklists);
  }

  // SmallListLayout Callbacks
  onFilterText(val: string) {
    this.filterText = val;
  }

  onOrderBy(val: (a: Project | Procurement, b: Project | Procurement) => number) {
    this.blacklistedItems = this.blacklistedItems.sort(val);
  }

  onListView(val: boolean) {
    this.listView = val;
  }
}

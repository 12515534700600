


























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PrintSection extends Vue {
  @Prop({ default: '' }) headerClass: string;
  @Prop({ default: '' }) headerText: string;
  @Prop({ default: '' }) bodyClass: string;
  @Prop({ default: null }) defaultText: string;
}

import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { Country } from '@/interfaces/country';

const api = '/countries';

export class CountryService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getCountries = async (): Promise<Country[]> => {
    const response = await this.get<Country[]>(api);
    return this.getResponse(response);
  }
}

export const countryService = new CountryService(apiConfig);



























































































































import { Component, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { globalFunctions } from '@/util/global-functions';
import { List } from '@/interfaces/list';
import { Modal } from 'bootstrap';
import { ModalIds } from '@/util/enums/modal-ids';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementReference } from '@/interfaces/procurement-reference';
import { Project } from '@/interfaces/project';

@Component({
  components: {
    AbstractForm,
    BaseModalContainer,
  },
})
export default class ModalListAdd extends Vue {
  chosenList: List = null;
  originalLists: List[] = null;
  loading = true;
  submitting = false;
  error = false;
  loadingError = false;

  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  get buttonRowClasses() {
    return [
      'justify-content-end',
      `justify-content-sm-${this.originalLists ? 'between' : 'end'}`,
    ];
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  initializeModal() {
    this.loading = true;
    if (!this.dataObject) {
      this.hideModal();
      return;
    }
    this.originalLists = null;
    this.$vmx.list.fetchLists().then(() => {
      if (this.hasList()) {
        const listsWithObject = this.$vmx.list.listsWithObject(this.dataObject);
        if (listsWithObject != null && listsWithObject.length > 0) {
          this.chosenList = listsWithObject[0];
          this.originalLists = listsWithObject;
        } else {
          this.chosenList = this.$vmx.list.lists[0];
        }
      }
    }).catch(() => {
      this.loadingError = true;
    }).finally(() => this.loading = false);
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal();
    }
  }

  get modalId(): string {
    return ModalIds.LIST_ADD_MODAL;
  }

  get dataObject(): Procurement | Project | ProcurementReference {
    return this.$vmx.modal.listObject;
  }

  async submit() {
    if (this.submitting) {
      return;
    }
    this.submitting = true;
    // const activityLog = await globalFunctions.createActivityLogFromItem(this.dataObject);
    if (!this.originalLists == null && !this.originalLists.some(list => list.id != this.chosenList.id)) {
      this.submitting = false;
      return;
    }

    const listsToUpdate: List[] = [];

    if (this.originalLists && this.originalLists.length > 0) {
      const removedLists = this.originalLists.filter(list => list.id != this.chosenList.id);
      removedLists.forEach((list) => {
        if (globalFunctions.isProcurementOrProcurementReference(this.dataObject)) {
          if (list.procurements) {
            list.procurements = list.procurements.filter(procurementId => procurementId != this.dataObject.id);
            listsToUpdate.push(list);
          }
        } else {
          if (list.projects) {
            list.projects = list.projects.filter(projectId => projectId != this.dataObject.id);
            listsToUpdate.push(list);
          }
        }
      });
    }

    if (globalFunctions.isProcurementOrProcurementReference(this.dataObject)) {
      if (this.chosenList.procurements && !this.chosenList.procurements.some(procurementId => procurementId == this.dataObject.id)) {
        this.chosenList.procurements.push(this.dataObject.id);
        listsToUpdate.push(this.chosenList);
      }
    } else {
      if (this.chosenList.projects && !this.chosenList.projects.some(projId => projId == this.dataObject.id)) {
        this.chosenList.projects.push(this.dataObject.id);
        listsToUpdate.push(this.chosenList);
      }
    }

    try {
      for (const listToUpdate of listsToUpdate) {
        const list = await this.$vmx.list.putList(listToUpdate);
        if (list) {
          this.error = false;
        } else {
          this.error = true;
          break;
        }
      }
      if (!this.error) {
        this.hideModal();
      }
      this.submitting = false;
    } catch {
      this.error = true;
      this.submitting = false;
    }
  }

  openListSettingsModal() {
    this.$vmx.modal.openListSettingsModal({ list: null, listObject: this.dataObject });
  }

  openListRemoveModal() {
    this.$vmx.modal.openListRemoveModal(this.dataObject);
  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }

  hasList() {
    return this.$vmx.list.lists != null && this.$vmx.list.lists.length > 0;
  }
}

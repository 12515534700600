import * as domainUtils from '@/util/domain-utils';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import ArticleCard from '@/components/cards/article-card.vue';
import { CompanyRole } from '@/interfaces/company-role';
import { Criterion } from '@/interfaces/criterias/criterion';
import { dateUtils } from '@/util/date-utils';
import Error401 from '@/components/error-components/error-401.vue';
import Error404 from '@/components/error-components/error-404.vue';
import { File } from '@/interfaces/file';
import { formatUtils } from '@/util/format-utils';
import { globalFunctions } from '@/util/global-functions';
import HorizontalDraggable from '@/components/draggables/horizontal-draggable.vue';
import IconBar from '@/components/containers/icon-bar.vue';
import ImageCarousel from '@/components/general-components/image-carousel.vue';
import { List } from '@/interfaces/list';
import { Modal } from 'bootstrap';
import ModalNoteDelete from '@/components/modals/modal-note-delete.vue';
import ModalNoteEdit from '@/components/modals/modal-note-edit.vue';
import ModalRoleInfo from '@/components/modals/modal-role-info.vue';
import { Note } from '@/interfaces/note';
import NoteList from '@/components/general-components/note-list.vue';
import PhaseTimeline from '@/components/phase-timeline.vue';
import { ProcurementReference } from '@/interfaces/procurement-reference';
import { Project } from '@/interfaces/project';
import { ProjectReference } from '@/interfaces/project-reference';
import ProjectReferenceCard from '@/components/cards/project-reference-card.vue';
import { projectService } from '@/services/restapi/project-service';
import { ResponseCodes } from '@/util/enums/response-codes';
import { RouterNames } from '@/util/enums/router-names';
import SearchItemsListView from '@/components/search-components/search-items-list-view.vue';
import { setTimeout } from 'timers';
import { Update } from '@/interfaces/update';
import UpdateList from '@/components/general-components/update-list.vue';
import { updateService } from '@/services/restapi/update-service';

@Component({
  components: {
    ArticleCard,
    Error401,
    Error404,
    HorizontalDraggable,
    IconBar,
    ImageCarousel,
    ModalNoteEdit,
    ModalNoteDelete,
    ModalRoleInfo,
    NoteList,
    PhaseTimeline,
    ProjectReferenceCard,
    SearchItemsListView,
    UpdateList,
  },
})
export default class ProjectView extends Vue {
  @Prop() private id: number;
  @Prop({ default: false }) printOnLoad: boolean;
  @Ref() slider: HTMLElement;
  loading = true;
  project: Project = null;
  getFromFile = this.$vmx.file.fromFile;
  sentTips: boolean[] = [];
  tipHasBeenSent: boolean = null;
  errorCode: number = null;

  selectedRole: CompanyRole = null;
  selectedNote: Note = null;

  sliderDown = false;
  articleClickable = true;
  startX: number;
  scrollLeft: number;

  windowSize = window.innerWidth;
  currentHash: string = null;

  updates: Update[] = null;

  mounted() {
    updateService.getUpdatesByProjectId(this.id).then(updates => {
      this.updates = updates;
    });

    window.scrollTo(0, 0);
    window.addEventListener('resize', this.resizeHandler);
    // this.project = await projectService.getProjectById(this.id);
    this.loadProject();
  }

  loadProject() {
    this.loading = true;

    projectService.getProjectById(this.id)
      .then(
        (project) => {
          this.project = project;
          document.title = this.project.name;
          this.project.articles.sort((x, y) => {
            return dateUtils.isDateStringAfter(x.created, y.created)
              ? -1 : dateUtils.isDateStringAfter(y.created, x.created)
                ? 1 : 0;
          });
          this.$nextTick(() => {
            if (this.printOnLoad) {
              // We wait 0.5 sec for the page to render and then open print
              globalFunctions.timeout(500).then(() => window.print());
            }
          });
          if (this.$vmx.session.isLoggedInLimitedAccess) {
            this.$vmx.limitedAccess.reFetchLimitedAccess();
          }
          this.setHash();
        },
        (error) => {
          if (error == ResponseCodes.ACCESS_RUN_OUT) {
            this.$router.push({ name: RouterNames.LOGGED_IN_ACCESS_EXPIRED });
            return;
          }
          this.errorCode = error;
        },
      )
      .finally(() => {
        this.$nextTick(() => {
          this.loading = false;
        });
      });
  }

  get noteId() {
    return 'note-list';
  }

  get firstListWithObject(): List {
    if (this.isOnList) {
      return this.$vmx.list.listsWithObject(this.project)[0];
    }
    return null;
  }

  get isOnList(): boolean {
    return this.$vmx.list.isInList(this.project);
  }

  get companyRoles(): CompanyRole[] {
    const companyRoles = this.project.companyRoles.slice();
    return companyRoles;
  }

  get budgetString(): string {
    if (this.project && this.project.budget) {
      return this.$n(this.project.budget, 'currency', domainUtils.domain.numberFormat);
    }
    return this.$t('general.emptyField') as string;
  }

  get projectStartEndString(): string {
    const empty = this.$t('general.emptyField');
    return `${this.project.start ?? empty} - ${this.project.end ?? empty}`;
  }

  get fullAddress(): string {
    let address = this.project.address ?? '';
    if (this.project.postalCode) {
      address += `, ${this.project.postalCode}`;
    }
    if (this.project.city) {
      address += ` ${this.project.city}`;
    }
    return address ?? 'N/A';
  }

  get hasCouncilSummaryInfo(): boolean {
    return (
      !!this.project.summaryCommittee ||
      !!this.project.summaryDate ||
      !!this.project.summaryLink ||
      !!this.project.summaryMunicipality ||
      !!this.project.summaryNumber
    );
  }

  get relatedProjects(): ProjectReference[] {
    let related = [];

    if (this.project.parentProject) {
      related.push(this.project.parentProject);
    }
    if (this.project.childrenProjects && this.project.childrenProjects.length > 0) {
      related = related.concat(this.project.childrenProjects);
    }
    return related;
  }

  get imagesFromFiles(): File[] {
    if (this.project.files) {
      return this.project.files.filter((file) => file.image);
    }
    return [];
  }

  get isAboveBasicProduct(): boolean {
    return this.$vmx.user.hasProduct;
  }

  get roleModalId() {
    return 'modal-role-info';
  }

  get tipSent(): boolean {
    if (this.selectedRole) {
      return this.sentTips[this.selectedRole.id];
    }
    return false;
  }

  @Watch('id')
  idChanged(newId: number) {
    window.scrollTo(0, 0);
    this.loadProject();
  }

  @Watch('$vmx.blacklist.blacklists')
  blacklistEdited() {
    this.$vmx.search.resetPage();
  }

  concatCriterionName(criteria: Criterion[], id: number): string {
    if (criteria && criteria.length > 0) {
      for (const criterion of criteria) {
        if (criterion.id === id) {
          return criterion.name;
        } else if (criterion.children && criterion.children.length > 0) {
          const childConcat = this.concatCriterionName(criterion.children, id);
          if (childConcat) {
            return childConcat.includes('>') ? childConcat : `${criterion.name} > ${childConcat}`;
          }
        }
      }
    }
    return null;
  }

  companyRoleString(companyRole: CompanyRole): string {
    const unspecified = this.$t('general.unspecified') as string;
    if (companyRole.role && companyRole.role.name && companyRole.role.name != unspecified) {
      return companyRole.role.name;
    } else if (companyRole.roleString) {
      return companyRole.roleString;
    } else {
      return unspecified;
    }
  }

  getCriteriaOfType(typeId: number): Criterion[] {
    return this.project.criteria?.filter(criterion => criterion.typeId === typeId) ?? [];
  }

  public getDraggableClasses(index: number, length: number) {
    return [
      { 'ps-0': index === 0 },
      { 'pe-0': index === length - 1 },
      { 'col-12': length === 1 },
      { 'col-10': length >= 1 },
    ];
  }

  getShortNote(note: string) {
    if (note && note.length > 50) {
      return note.slice(0, 50) + '...';
    }
    return note;
  }

  public formatDate(date: string, format: string): string {
    return formatUtils.formatDate(date, format);
  }

  public imageFromFile(file: File) {
    if (file) {
      return file.link ? file.link : this.getFromFile(`${file.code}.${file.extension}`);
    } else {
      return this.getFromFile(undefined);
    }
  }

  public procurementClicked(procurement: ProcurementReference) {
    this.$router.push({ name: RouterNames.LOGGED_IN_PROCUREMENT_VIEW, params: { id: procurement.id.toString() } });
  }

  public projectClicked(project: ProjectReference) {
    this.$router.push({ name: RouterNames.LOGGED_IN_PROJECT_VIEW, params: { id: project.id.toString() } });
  }

  public resizeHandler() {
    this.windowSize = window.innerWidth;
  }

  selectRole(companyRole: CompanyRole) {
    this.selectedRole = companyRole;
    this.openModal(this.roleModalId);
  }

  openModal(modalId: string) {
    const element = document.getElementById(modalId);
    const modal = new Modal(element);
    modal.show();
  }

  addSentTip(companyRoleId: number) {
    this.sentTips[companyRoleId] = true;
    this.sentTips = [...this.sentTips];
  }

  openDeleteNoteModal(id: number) {
    this.selectedNote = this.project.notes.find((note) => note.id == id);
    this.openModal(`delete-${this.noteId}`);
  }

  noteSaved(note: Note) {
    if (this.project.notes.some((x) => x.id === note.id)) {
      this.project.notes = this.project.notes.map((obj) => (obj.id === note.id ? note : obj));
    } else {
      this.project.notes.push(note);
      this.project.notes.sort((a, b) => (a.created > b.created ? -1 : 1));
    }
    this.selectedNote = null;
  }

  noteDeleted(id: number) {
    this.project.notes.splice(
      this.project.notes.findIndex((x) => x.id === id),
      1,
    );
    this.selectedNote = null;
  }

  routeToCompanyView(companyRole: CompanyRole) {
    this.$router.push({ name: RouterNames.LOGGED_IN_COMPANY_VIEW, params: { id: companyRole.companyId.toString() }})
  }

  setHash(type?: string) {
    if (type) {
      if (this.currentHash == type) {
        document.getElementById(type).scrollIntoView();
        return;
      }

      this.currentHash = type;
      this.$router.replace({ hash: `#${type}`});
      return;
    }

    this.currentHash = location.hash.split('#')[1] ?? null;
    if (this.currentHash == null) return;
    setTimeout(() => {
      document.getElementById(this.currentHash).scrollIntoView();
    }, 500);
  }

  getHashColor(type: string): string {
    return this.currentHash == type ? 'cta' : 'trademark';
  }
}












































import { Component, Ref, Vue } from 'vue-property-decorator';
import { ContactInfo } from '@/interfaces/non-entities/contact-info';
import FormAskQuestion from '@/components/forms/form-ask-question.vue';
import { LimitedAccess } from '@/interfaces/limited-access';
import ProductBox from '@/components/general-components/product-box.vue';
import { User } from '@/interfaces/user';

@Component({
  components: {
    FormAskQuestion,
    ProductBox,
  },
})
export default class Error401 extends Vue {

  @Ref() formAskQuestion: FormAskQuestion;

  hasUser: boolean = this.$vmx.session.isLoggedinUser;
  user: User = this.$vmx.user.user;
  limitedAccess: LimitedAccess = this.$vmx.limitedAccess.limitedAccess;

  contactInfo: ContactInfo;

  created() {
    this.contactInfo = {
      name: this.user?.name ?? this.limitedAccess.name,
      mail: this.user?.mail ?? '',
      companyName: this.user?.company ?? '',
      phone: this.user?.phone ?? '',
      date: null,
      time: null,
      online: true,
      question: '',
    };
  }


  get products() {
    return this.$vmx.product.products ?? [];
  }

  get unusedProducts() {
    return this.products.filter(p => this.hasUser ? p.id != this.user.product.id : true);
  }

  scrollToContact(id: number) {
    const productName = this.products.find(p => p.id === id).name;
    this.contactInfo.question = this.$t(
      'components.errorComponents.error401.upgradeToProduct',
      [this.user.product.name, productName],
    ) as string;
    this.formAskQuestion.setContactInfo(this.contactInfo);
    this.formAskQuestion.$el.scrollIntoView();
  }
}

import { createModule, preserve } from '../preloader';
import { action } from 'vuex-class-component';
import { Country } from '@/interfaces/country';
import { countryService } from '@/services/restapi/country-service';

export default class CountryStore extends createModule('country') {
  private countriesData = null as Country[];
  private countryPromise = null as Promise<Country[]>;

  get countries(): Country[] {
    return this.countriesData;
  }

  @action
  async setCountries(countries: Country[]) {
    this.countriesData = countries;
  }

  @action
  async fetchCountries() {
    return this.countriesData ?? preserve(this.countryPromise, countryService.getCountries, this.setCountries);
  }
}
import { action } from 'vuex-class-component';
import { Agent } from '@/interfaces/agent';
import { Company } from '@/interfaces/company';
import { CompanyAgent } from '@/interfaces/search/company-agent';
import { CompanyList } from '@/interfaces/company-list';
import { createModule } from '../preloader';
import { List } from '@/interfaces/list';
import { Modal } from 'bootstrap';
import { ModalIds } from '@/util/enums/modal-ids';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementReference } from '@/interfaces/procurement-reference';
import { Project } from '@/interfaces/project';
import { Tip } from '@/interfaces/tip';

export default class ModalStore extends createModule('modal') {
  private agentData = null as Agent;
  private blacklistObjectData = null as Procurement | Project | ProcurementReference;
  private companyAgentData = null as CompanyAgent;
  private companyListData = null as CompanyList;
  private companyData = null as Company;
  private listData = null as List;
  private listObjectData = null as Procurement | Project | ProcurementReference;
  private tipData = null as Tip;
  // private noteData = null as Note;

  get agent(): Agent {
    return this.agentData;
  }

  get blacklistObject(): Procurement | Project | ProcurementReference {
    return this.blacklistObjectData;
  }

  get companyAgent(): CompanyAgent {
    return this.companyAgentData;
  }

  get companyList(): CompanyList {
    return this.companyListData;
  }

  get company(): Company {
    return this.companyData;
  }

  get list(): List {
    return this.listData;
  }

  get listObject(): Procurement | Project | ProcurementReference {
    return this.listObjectData;
  }

  get tip(): Tip {
    return this.tipData;
  }

  @action
  async openAgentDeleteModal(agent: Agent) {
    if (agent != null) {
      this.agentData = agent;
      await this.openModal(ModalIds.AGENT_DELETE_MODAL);
    }
  }

  @action
  async openAgentReplaceModal(agent: Agent) {
    if (agent != null) {
      this.agentData = agent;
      await this.openModal(ModalIds.AGENT_REPLACE_MODAL);
    }
  }

  @action
  async openAgentSettingsModal(agent: Agent) {
    if (agent != null) {
      this.agentData = agent;
      await this.openModal(ModalIds.AGENT_SETTINGS_MODAL);
    }
  }

  @action
  async openCompanyAgentDeleteModal(companyAgent: CompanyAgent) {
    if (companyAgent != null) {
      this.companyAgentData = companyAgent;
      await this.openModal(ModalIds.COMPANY_AGENT_DELETE_MODAL);
    }
  }

  @action
  async openCompanyAgentReplaceModal(companyAgent: CompanyAgent) {
    if (companyAgent != null) {
      this.companyAgentData = companyAgent;
      await this.openModal(ModalIds.COMPANY_AGENT_REPLACE_MODAL);
    }
  }

  @action
  async openCompanyAgentSettingsModal(companyAgent: CompanyAgent) {
    if (companyAgent != null) {
      this.companyAgentData = companyAgent;
      await this.openModal(ModalIds.COMPANY_AGENT_SETTINGS_MODAL);
    }
  }

  @action
  async openBlacklistSettingsModal(blacklistObject: Procurement | Project | ProcurementReference) {
    if (blacklistObject != null) {
      this.blacklistObjectData = blacklistObject;
      await this.openModal(ModalIds.BLACKLIST_SETTINGS_MODAL);
    }
  }

  @action
  async openBlacklistRemoveModal(blacklistObject: Procurement | Project | ProcurementReference) {
    if (blacklistObject != null) {
      this.blacklistObjectData = blacklistObject;
      await this.openModal(ModalIds.BLACKLIST_REMOVE_MODAL);
    }
  }

  @action
  async openCompanyListAddModal(company: Company) {
    if (company != null) {
      this.companyData = company;
      await this.openModal(ModalIds.COMPANY_LIST_ADD_MODAL);
    }
  }

  @action
  async openCompanyListRemoveModal(company: Company) {
    if (company != null) {
      this.companyData = company;
      await this.openModal(ModalIds.COMPANY_LIST_REMOVE_MODAL);
    }
  }

  @action
  async openCompayListSettingsModal(payload: {
    companyList: CompanyList;
    company?: Company;
  }) {
    if (payload != null && (payload.companyList != null || payload.company != null)) {
      this.companyListData = payload.companyList
      this.companyData = payload.company;
      await this.openModal(ModalIds.COMPANY_LIST_SETTINGS_MODAL);
    }
  }

  @action
  async openListAddModal(listObject: Procurement | Project | ProcurementReference) {
    if (listObject != null) {
      this.listObjectData = listObject;
      await this.openModal(ModalIds.LIST_ADD_MODAL);
    }
  }

  @action
  async openListRemoveModal(listObject: Procurement | Project | ProcurementReference) {
    if (listObject != null) {
      this.listObjectData = listObject;
      await this.openModal(ModalIds.LIST_REMOVE_MODAL);
    }
  }

  @action
  async openListSettingsModal(payload: {
    list: List;
    listObject?: Procurement | Project | ProcurementReference;
  }) {
    if (payload != null && (payload.list != null || payload.listObject != null)) {
      this.listData = payload.list;
      this.listObjectData = payload.listObject;
      await this.openModal(ModalIds.LIST_SETTINGS_MODAL);
    }
  }

  @action
  async openTipModal(tip: Tip) {
    if (tip != null) {
      this.tipData = tip;
      await this.openModal(ModalIds.TIP_MODAL);
    }
  }

  // @action
  // async openNoteDeleteModal(note: Note) {
  //   if (note != null) {
  //     this.noteData = note;
  //     await this.openModal(ModalIds.NOTE_DELETE_MODAL);
  //   }
  // }

  // @action
  // async openNoteEditModal(note: Note) {
  //   if (note != null) {
  //     this.noteData = note;
  //     await this.openModal(ModalIds.NOTE_EDIT_MODAL);
  //   }
  // }

  @action
  async openPaywallModal() {
    await this.openModal(ModalIds.PAYWALL_MODAL);
  }

  @action
  private async openModal(modalId: string) {
    const element = document.getElementById(modalId);
    if (element) {
      const modal = new Modal(element);
      modal.show();
    }
  }
}

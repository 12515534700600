import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseLogo from '@/components/buttons/base-logo.vue';
import { formatUtils } from '@/util/format-utils';
import { LocaleMessage } from 'vue-i18n';
import { Note } from '@/interfaces/note';
import PrintNote from '@/components/print/print-note.vue';
import PrintRow from '@/components/print/print-row.vue';
import PrintSection from '@/components/print/print-section.vue';
import { Procurement } from '@/interfaces/procurement';
import { ProcurementPhaseRecord } from '@/interfaces/procurement-phase-record';
import { procurementService } from '@/services/restapi/procurement-service';
import { Project } from '@/interfaces/project';
import { projectService } from '@/services/restapi/project-service';

Component.registerHooks(['beforeRouteEnter']);

@Component({
  components: {
    BaseLogo,
    PrintSection,
    PrintRow,
    PrintNote,
  },
})
export default class PrintProcurementView extends Vue {
  @Prop() id: string;

  data: Procurement = null;
  project: Project = null;
  isLoading = true;

  created() {
    window.onafterprint = () => {
      this.$router.go(-1);
    };
  }

  mounted() {
    this.fetchData().then(async (data) => {
      this.data = data;

      setTimeout(() => {
        this.isLoading = false;
        this.$nextTick(() => {
          window.print();
        });
      }, 1000);
    });
  }

  async fetchData(): Promise<Procurement> {
    const procurement = await procurementService.getProcurementById(Number.parseInt(this.id));
    if (this.data?.project?.id != null) {
      const project = await projectService.getProjectById(this.data.project.id);
    }

    return {
      ...procurement,
      start: this.project?.start ?? procurement.start,
      end: this.project?.end ?? procurement.end,
    };
  }

  formatInfo(data: string): { data: string; class: string[] } {
    if (data == null) {
      return null;
    }
    return { data: data, class: ['mb-1'] };
  }

  formatUrl(data: string): { data: string; class: string[] } {
    return { data: data, class: ['mb-1', 'text-underlined', 'text-primary', 'text-underlined'] };
  }

  formatDate(date: string) {
    return date != null ? formatUtils.formatDate(date, 'DD.MM.YYYY') : 'N/A';
  }

  formatDates(date1: string, date2: string) {
    if (date1 == null && date2 == null) {
      return null;
    }
    return `${this.formatDate(date1)} - ${this.formatDate(date2)}`;
  }

  // NAVN & LOGO
  getLogo(): string {
    return this.$vmx.file.fromFile('project-agent/logo-210x50.svg');
  }

  // Dates
  get hasDateData() {
    return this.getDateData != null && this.getDateData.length != 0;
  }

  get getDateData(): { label: LocaleMessage; datas: { data: string; class: string[] }[] }[] {
    return [
      { label: this.startEndLabel, datas: this.startEndData },
      {
        label: this.$t('pages.procurement.importantDates.participation'),
        datas: this.participationData,
      },
      {
        label: this.$t('pages.procurement.importantDates.offers'),
        datas: this.offerData,
      },
      {
        label: this.$t('pages.procurement.importantDates.materials'),
        datas: this.materialData,
      },
      {
        label: this.$t('pages.procurement.importantDates.inquiries'),
        datas: this.inquiryData,
      },
    ].filter((v) => v != null);
  }

  get startEndLabel(): LocaleMessage {
    if (this.data.start != null && this.data.end != null) {
      return this.$t('general.criteria.startEnd');
    } else if (this.data.start != null) {
      return this.$t('general.criteria.startEndOptions.start');
    } else if (this.data.end != null) {
      return this.$t('general.criteria.startEndOptions.end');
    } else {
      return null;
    }
  }

  get startEndData(): { data: string; class: string[] }[] {
    let startEnd: string[];

    if (
      this.data.start != null &&
      this.data.start.length != 0 &&
      this.data.end != null &&
      this.data.end.length != 0
    ) {
      startEnd = [this.data.start + ' - ' + this.data.end];
    } else if (this.data.start != null && this.data.start.length != null) {
      startEnd = [this.data.start];
    } else if (this.data.end != null && this.data.end.length != null) {
      startEnd = [this.data.end];
    }

    return startEnd?.map(this.formatInfo);
  }

  get participationData(): { data: string; class: string[] }[] {
    const datas = [
      this.formatDates(this.data.participationFrom, this.data.participationTo),
      this.data.participationAddress,
      this.data.participationNote,
    ]
      .map(this.formatInfo)
      .concat(this.formatUrl(this.data.participationLink))
      .filter((data) => data?.data != null && data.data.length != 0);
    if (datas.length == 0) {
      return null;
    }
    return datas;
  }

  get offerData(): { data: string; class: string[] }[] {
    const datas = [
      this.formatDates(this.data.offersFrom, this.data.offersTo),
      this.data.offersAddress,
      this.data.offersNote,
    ]
      .map(this.formatInfo)
      .concat(this.formatUrl(this.data.offersLink))
      .filter((data) => data?.data != null && data.data.length != 0);
    if (datas.length == 0) {
      return null;
    }
    return datas;
  }

  get materialData(): { data: string; class: string[] }[] {
    const datas = [
      this.formatDates(this.data.materialFrom, this.data.materialTo),
      this.data.materialAddress,
    ]
      .map(this.formatInfo)
      .concat(this.formatUrl(this.data.materialLink))
      .filter((data) => data?.data != null && data.data.length != 0);
    if (datas.length == 0) {
      return null;
    }
    return datas;
  }

  get inquiryData(): { data: string; class: string[] }[] {
    const datas = [
      this.formatDates(this.data.inquiryFrom, this.data.inquiryTo),
      this.data.inquiryAddress,
    ]
      .map(this.formatInfo)
      .concat(this.formatUrl(this.data.inquiryLink))
      .filter((data) => data?.data != null && data.data.length != 0);
    if (datas.length == 0) {
      return null;
    }
    return datas;
  }

  // Information
  get hasInformationData(): boolean {
    return this.informationData != null && this.informationData.length != 0;
  }

  get informationData(): { label: LocaleMessage; datas: { data: string; class: string[] }[] }[] {
    const information = [this.procurementTypeData];
    for (const criterionType of this.$vmx.criterion.standardCriterionTypes) {
      information.push({
        label: this.$tc(criterionType.translationProperty, 2),
        datas: this.getCriterionDataForType(criterionType.id),
      });
    }
    information.push(this.estimatedSumData)
    return information.filter((v) => v != null && v.datas != null && v.datas.length > 0);
  }

  get procurementTypeData(): { label: LocaleMessage; datas: { data: string; class: string[] }[] } {
    const data: string = this.data.procurementType?.name;
    return data == null
      ? null
      : { label: this.$t('general.criteria.procurementType'), datas: [this.formatInfo(data)] };
  }

  get estimatedSumData(): { label: LocaleMessage; datas: { data: string; class: string[] }[] } {
    const data = this.data.budget;
    if (data == null) {
      return null;
    }
    return {
      label: this.$t('general.criteria.estimatedSum'),
      datas: [this.formatInfo(this.$n(data, 'currency', 'da-DK'))],
    };
  }

  // Notes
  get noteData(): Note[] {
    return this.data.notes.filter(
      (note) =>
        note != null &&
        note.title != null &&
        note.title.length != 0 &&
        note.text != null &&
        note.text.length != 0,
    );
  }

  get noteDataSize(): boolean {
    return (
      this.data.notes.length > 3 ||
      this.data.notes.map((note) => note.text).reduce((acc, val) => val + acc, '').length > 900
    );
  }

  getCriterionDataForType(typeId: number) {
    const data = this.data.criteria?.filter(criterion => criterion.typeId === typeId)
      .map((criterion) => criterion.name).join(', ') ?? null;
    return data ? [this.formatInfo(data)] : null;
  }

  // PHASE RECORDS
  getIconOrder(record: ProcurementPhaseRecord): number {
    return record.procurementPhase.id - this.data.procurementPhase.id;
  }

  getCircleStyle(record: ProcurementPhaseRecord) {
    return {
      width: this.getIconOrder(record) == 0 ? '60px' : '32px',
      height: this.getIconOrder(record) == 0 ? '60px' : '32px',
      'border-radius': '50%',
      'border-color': 'trademark',
      'background-color': 'trademark',
    };
  }

  // WINNERS
  getWinnerFields() {
    const data = [
      this.$t('pages.procurement.winners.companyField'),
      this.$t('pages.procurement.winners.roleField'),
      this.$t('components.modals.role.contact'),
    ];
    return data;
    // return [
    //   { key: 'companyName', label: this.$t('pages.procurement.winners.companyField') },
    //   { key: 'role', label: this.$t('pages.procurement.winners.roleField') },
    //   { key: 'contact', label: this.$t('components.modals.role.contact') },
    // ];
  }

  getWinnersItems() {
    const data = this.data.companyRoles?.filter((val) => val.isWinner);
    if (data == null || data.length == 0) {
      return null;
    }
    return data;
    //   const items = this.data.companyRoles
    //     ?.filter((role) => role.isWinner)
    //     .map((role) => {
    //       return {
    //         companyName: role.companyName,
    //         role: role.role?.name,
    //         name: role.
    //           ${role.contactPerson?.name}
    //           ${role.contactPerson?.mail}
    //           ${role.contactPerson?.phone}
    //         `,
    //       };
    //     });
    //   return items;
  }
}









































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActivityLog } from '@/interfaces/non-entities/activity-log';
import BaseLoadingSpinner from '@/components/general-components/base-loading-spinner.vue'
import { Update } from '@/interfaces/update';
import UpdateList from '@/components/general-components/update-list.vue';
import { updateService } from '@/services/restapi/update-service';

@Component({
  components: {
    UpdateList,
    BaseLoadingSpinner,
  },
})
export default class DropdownActivitylog extends Vue {
  @Prop({ required: true }) listId: number;
  @Prop({ default: 'activitylog-dropdown' }) id: string;
  @Prop({ default: 'sm' }) size: string;
  @Prop({ default: 'sm' }) iconSize: string;
  @Prop({ default: 'bell' }) iconName: string;
  @Prop({ default: 'primary' }) variant: string;
  @Prop({ default: false }) showBadge: boolean;
  @Prop({ default: null }) onClick?: (type: string) => void;
  @Prop({ default: null }) label: string;

  activityLogs: ActivityLog[] = null;
  fetching = false;

  get loading() {
    return this.activityLogs == null;
  }

  loadActivities() {
    if (this.fetching) {
      return;
    }

    this.fetching = true;

    updateService.getActivityLogsFromListId(this.listId).then(activityLogs => {
      this.activityLogs = activityLogs;
      console.log(this.activityLogs)
    });
  }
}












































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { globalFunctions } from '@/util/global-functions';
import { ICalCalendar } from 'ical-generator';
import moment from 'moment';

@Component
export default class DropdownMailExport extends Vue {
  @Prop({ required: true }) downloadName: string;
  @Prop({ required: true }) mailHeader: string;
  @Prop() mailBody: string;
  @Prop() fromDate: string;
  @Prop() toDate: string;

  get isBasicProduct(): boolean {
    return !this.$vmx.user.hasProduct;
  }

  get from(): moment.Moment {
    return (this.fromDate && moment(this.fromDate) || moment(this.toDate)).locale('da');
  }

  get to(): moment.Moment {
    return (this.toDate && moment(this.toDate) || moment(this.fromDate)).locale('da');
  }

  get shortMailDetails(): string {
    return `Projektagenten url: ${window.location.href}`
  }

  get mailDetails(): string {
    return [
      this.mailBody,
      '\n',
      `Projektagenten url: ${window.location.href}`,
    ].join('\r\n')
  }

  get paywallModalId() {
    return globalFunctions.getPaywallModalId();
  }

  private getOutlookCalenderEventLink(): string {
    // TODO changed the word "deeplink" to "action", between "0" and "compose", due to MS error. They might need changing back again.
    return (!this.fromDate && !this.toDate)
      ? null
      : 'https://outlook.office.com/calendar/0/action/compose?'.concat(
        [
          'path=/calendar/action/compose',
          'rru=addevent',
          `startdt=${this.from.format('YYYY-MM-DD[T]HH[:]mm[:]SS[Z]')}`,
          `enddt=${this.to.format('YYYY-MM-DD[T]HH[:]mm[:]SS[Z]')}`,
          `subject=${encodeURIComponent(this.mailHeader)}`,
          `body=${encodeURIComponent(this.shortMailDetails)}`,
        ].join('&'),
      )
  }

  private getOfficeCalenderEventLink(): string {
    // TODO changed the word "deeplink" to "action", between "0" and "compose", due to MS error. They might need changing back again.
    return (!this.fromDate && !this.toDate)
      ? null
      : 'https://outlook.office.com/calendar/0/action/compose?'.concat(
        [
          'path=/calendar/action/compose',
          'rru=addevent',
          `startdt=${this.from.format('YYYY-MM-DD[T]HH[:]mm[:]SS[Z]')}`,
          `enddt=${this.to.format('YYYY-MM-DD[T]HH[:]mm[:]SS[Z]')}`,
          `subject=${encodeURIComponent(this.mailHeader)}`,
          `body=${this.shortMailDetails}`,
        ].join('&'),
      )
  }

  private getGoogleCalendarEventLink(): string {
    return (!this.fromDate && !this.toDate)
      ? null
      : 'https://www.google.com/calendar/render?'.concat(
        [
          'action=TEMPLATE',
          `text=${encodeURIComponent(this.mailHeader)}`,
          `dates=${this.from.format('YYYYMMDD[T]HHmm')}/${this.to.format(('YYYYMMDD[T]HHmm'))}`,
          `&details=${encodeURIComponent(this.mailDetails)}`,
        ].join('&'),
      )
  }

  private getICS(): string {
    if (!this.fromDate && !this.toDate) return null;
    const cal = new ICalCalendar();

    cal.createEvent({
      start: this.from,
      end: this.to,
      summary: this.mailHeader,
      description: this.mailDetails,
    });

    return cal.toURL();
  }

}

































import * as domainUtils from '@/util/domain-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueTelInput } from 'vue-tel-input';

@Component({
  components: {
    VueTelInput,
  },
})
export default class InputPhone extends Vue {
  @Prop() number: string;
  @Prop({ default: 45 }) callingCode: number;
  @Prop() label: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) required: boolean;
  @Prop({ default: null }) validator: (val: string) => boolean;
  @Prop() invalidText: string;

  input: string = null;

  valid: boolean = null;
  isValidated = false;

  mounted() {
    const start: string = this.callingCode ? `+${this.callingCode}` : '45';
    if (this.number && !this.number.startsWith(start)) {
      this.input = `+${this.callingCode ? this.callingCode : '45'}${this.number}`
    }
  }

  get computedInput() {
    if (this.input && !this.input.startsWith('+')) {
      this.input = `+45${this.input}`;
    }
    return this.input;
  }

  get inputOptions() {
    return {
      required: this.required,
      styleClasses: this.inputStyleClasses,
      showDialCode: true,
    }
  }

  get inputStyleClasses() {
    return this.isValidated && this.valid !== null
      ? `form-control ${this.valid ? 'is-valid' : 'is-invalid'}`
      : 'form-control';
  }

  get preferredCountries() {
    return this.$vmx.domain.domains.map(d => d.tld);
  }

  get domainCountry() {
    return domainUtils.domain.tld;
  }

  phoneInput(number: string, phoneOutput: any) {
    this.valid = phoneOutput.valid;
    // this.input = phoneOutput.number;
    // this.input = number;
    this.$emit('nationalNumber', phoneOutput.nationalNumber);
    this.$emit('countryCallingCode', phoneOutput.countryCallingCode);
    this.$emit('number', phoneOutput.number);
    this.$emit('formatted', phoneOutput.formatted);
  }

  countryChanged(countryObject: any) {
    this.$emit('countryChange', +(countryObject.dialcode as string));
  }

  public validate(): boolean {
    if (this.validator != null) {
      this.valid = this.valid && this.validator(this.input);
    }

    this.isValidated = true;
    return this.valid;
  }
}

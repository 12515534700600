import { createModule, preserve } from '../preloader';
import { action } from 'vuex-class-component';
import { ProjectPhase } from '@/interfaces/criterias/project-phase';
import { projectPhaseService } from '@/services/restapi/project-phase-service';

export default class ProjectPhaseStore extends createModule('projectPhase') {
  private projectPhasesData = null as ProjectPhase[];
  private projectPhasesPromise = null as Promise<ProjectPhase[]>;

  get projectPhases(): ProjectPhase[] {
    return this.projectPhasesData;
  }

  @action
  async setProjectPhases(projectPhases: ProjectPhase[]) {
    this.projectPhasesData = projectPhases;
  }

  @action
  async fetchProjectPhases() {
    return (
      this.projectPhasesData ??
      preserve(
        this.projectPhasesPromise,
        projectPhaseService.getProjectPhases,
        this.setProjectPhases,
      )
    );
  }
}

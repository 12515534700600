import { Component, Ref, Vue } from 'vue-property-decorator';
import AgentsOverview from '@/components/dashboard-components/agents-overview.vue';
import ArticleCard from '@/components/cards/article-card.vue';
import { ArticleLink } from '@/interfaces/article-link';
import CompanyAgentsOverview from '@/components/dashboard-components/company-agents-overview.vue';
import CompanyListsOverview from '@/components/dashboard-components/company-lists-overview.vue';
import { dateUtils } from '@/util/date-utils';
import { globalFunctions } from '@/util/global-functions';
import HorizontalDraggable from '@/components/draggables/horizontal-draggable.vue';
import { listService } from '@/services/restapi/list-service';
import ListsOverview from '@/components/dashboard-components/lists-overview.vue';
import ProjectReferenceCard from '@/components/cards/project-reference-card.vue';
import { RouterNames } from '@/util/enums/router-names';

@Component({
  components: {
    AgentsOverview,
    ArticleCard,
    CompanyAgentsOverview,
    CompanyListsOverview,
    HorizontalDraggable,
    ListsOverview,
    ProjectReferenceCard,
  },
})
export default class DashboardView extends Vue {
  @Ref() articleDraggable: HorizontalDraggable;
  private readonly RELATED_ARTICLES_AMOUNT = 10;
  relevantArticles: ArticleLink[] = [];
  smallWindow = false;

  openSearchDropDown() {
    this.$root.$emit('show_search_box');
  }

  created() {
    listService
      .findRelevantArticles(this.RELATED_ARTICLES_AMOUNT)
      .then((articles: ArticleLink[]) => {
        this.relevantArticles = articles;
        this.relevantArticles.sort((x, y) => {
          return dateUtils.isDateStringAfter(x.created, y.created)
            ? -1 : dateUtils.isDateStringAfter(y.created, x.created)
              ? 1 : 0;
        });
      });
  }

  mounted() {
    this.smallWindow = window.innerWidth < 1200;
    window.addEventListener('resize', this.resizeHandler);
  }

  get agentsLoaded(): boolean {
    return this.$vmx.agent.agentsLoaded;
  }

  get companyAgentsLoaded(): boolean {
    return this.$vmx.companyAgent.companyAgentsLoaded;
  }

  get companyListsLoaded(): boolean {
    return this.$vmx.companyList.companyListsLoaded;
  }

  get hasAgents() {
    const agents = this.$vmx.agent.agents;
    return agents && agents.length > 0;
  }

  get hasCompanyAgents() {
    const companyAgents = this.$vmx.companyAgent.companyAgents;
    return companyAgents && companyAgents.length > 0;
  }

  get hasCompanyLists() {
    const companyLists = this.$vmx.companyList.companyLists;
    return companyLists != null && companyLists.length > 0;
  }

  get hasLists() {
    const lists = this.$vmx.list.lists;
    return lists != null && lists.length > 0;
  }

  get listsLoaded(): boolean {
    return this.$vmx.list.listsLoaded as boolean;
  }

  public getDraggableClasses(index: number, length: number) {
    return [
      { 'ps-0': index === 0 },
      { 'pe-0': index === length - 1 },
      { 'col-12': length === 1 },
      { 'col-10 col-sm-7 col-lg-5 col-xxl-3': length >= 1 },
    ];
  }

  resizeHandler() {
    if (window.innerWidth < 1200) {
      if (!this.smallWindow) {
        this.smallWindow = true;
        globalFunctions.timeout(50).then(() => {
          this.articleDraggable.resizeHandler();
        });
      }
    } else {
      this.smallWindow = false;
    }
  }

  createAgentClick() {
    this.$vmx.search.clearAgent();
    this.$router.push({
      name: RouterNames.LOGGED_IN_SEARCH,
      params: { page: '1' },
    });
  }

  createCompanyAgentClick() {
    this.$vmx.searchCompany.resetCompanyAgent();
    this.$router.push({
      name: RouterNames.LOGGED_IN_COMPANY_SEARCH,
      params: { page: '1' },
    });
  }
}

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class EmailChange extends Vue {
  @Prop({ required: true }) newEmail: string;
  @Prop({ required: true }) code: string;
  emailConfirmed = false;
  error = false;

  mounted() {
    if (this.newEmail != null && this.code != null) {
      try {
        this.$vmx.user.updateEmail({ email: this.newEmail, code: this.code }).then(() => {
          this.emailConfirmed = true;
        });
      } catch (error) {
        this.error = true;
      }
    } else {
      this.error = true;
    }
  }
}

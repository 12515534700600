






























import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { ArticleLink } from '@/interfaces/article-link';
import { File } from '@/interfaces/file';
import { formatUtils } from '@/util/format-utils';
import { globalFunctions } from '@/util/global-functions';

/*
  We handle clicks in this card differently to support horizontal drag
*/
@Component
export default class ArticleCard extends Vue {
  @Prop({required: true}) article: ArticleLink;
  @Ref() imageContainer: HTMLElement;
  private getFromFile = this.$vmx.file.fromFile;
  allowClick = false;
  loading = true;

  mounted() {
    this.loading = false;
  }

  get imageStyles() {
    if (!this.loading && this.article && this.article.coverImage) {
      return {
        'max-height': `${Math.round(this.imageContainer.clientWidth / 1.6)}px`,
      };
    }
    return null;
  }

  public articleClicked(article: ArticleLink) {
    if (this.allowClick) {
      window.open(`${article.hostDomain}/article/view/${article.id}`);
    }
  }

  public blockSlowClicks() {
    this.allowClick = true;
    globalFunctions.timeout(100).then(() => { this.allowClick = false; })
  }

  public formatDate(date: string, format: string): string {
    return formatUtils.formatDate(date, format);
  }

  public imageFromFile(file: File) {
    if (file) {
      return file.link ? file.link : this.getFromFile(`${file.code}.${file.extension}`);
    } else {
      return this.getFromFile(undefined);
    }
  }
}

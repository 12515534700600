import { AbstractCriteria } from '@/interfaces/criterias/abstract-criteria';
import { Criterion } from '@/interfaces/criterias/criterion';

export class CriteriaUtils {
  constructor() {}

  public sortCriteriaByName = (criteria: AbstractCriteria[]): AbstractCriteria[] => {
    return this.sortCriteria(criteria);
  };

  public sortCriteriaTreeByName = (criteria: Criterion[]): Criterion[] => {
    return this.sortCriteriaTree(criteria);
  }

  private sortCriteria(criteria: AbstractCriteria[]): AbstractCriteria[] {
    criteria = criteria.sort((c1, c2) => c1.name.localeCompare(c2.name));
    for (const criterium of criteria) {
      criterium.children = this.sortCriteria(criterium.children);
    }
    return criteria;
  }

  private sortCriteriaTree(criteria: Criterion[]): Criterion[] {
    criteria = criteria.sort((c1, c2) => c1.name.localeCompare(c2.name));
    for (const criterion of criteria) {
      criterion.children = this.sortCriteriaTree(criterion.children);
    }
    return criteria;
  }
}

export const criteriaUtils = new CriteriaUtils();

export enum ModalIds {
  AGENT_DELETE_MODAL = 'agent-delete-modal',
  AGENT_REPLACE_MODAL = 'agent-replace-modal',
  AGENT_SETTINGS_MODAL = 'agent-settings-modal',
  BLACKLIST_REMOVE_MODAL = 'blacklist-remove-modal',
  BLACKLIST_SETTINGS_MODAL = 'blacklist-settings-modal',
  COMPANY_AGENT_DELETE_MODAL = 'company-agent-delete-modal',
  COMPANY_AGENT_REPLACE_MODAL = 'company-agent-replace-modal',
  COMPANY_AGENT_SETTINGS_MODAL = 'company-agent-settings-modal',
  COMPANY_LIST_ADD_MODAL = 'company-list-add-modal',
  COMPANY_LIST_REMOVE_MODAL = 'company-list-remove-modal',
  COMPANY_LIST_SETTINGS_MODAL = 'company-list-settings-modal',
  LIST_ADD_MODAL = 'list-add-modal',
  LIST_REMOVE_MODAL = 'list-remove-modal',
  LIST_SETTINGS_MODAL = 'list-settings-modal',
  // NOTE_DELETE_MODAL = 'note-delete-modal',
  // NOTE_EDIT_MODAL = 'note-edit-modal',
  PAYWALL_MODAL = 'paywall-modal',
  TIP_MODAL = 'tip-modal',
}

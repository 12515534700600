


















































































































import * as domainUtils from '@/util/domain-utils';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActivityLog } from '@/interfaces/non-entities/activity-log';
import { dateUtils } from '@/util/date-utils';
import DropdownActivitylog from '@/components/dropdowns/dropdown-activitylog.vue'
import DropdownUpdates from '@/components/dropdowns/dropdown-updates.vue';
import { globalFunctions } from '@/util/global-functions';
import { List } from '@/interfaces/list';
import { listService } from '@/services/restapi/list-service';
import moment from 'moment-timezone';
import { Procurement } from '@/interfaces/procurement';
import { Project } from '@/interfaces/project';
import { RouterNames } from '@/util/enums/router-names';
import UpdateList from '@/components/general-components/update-list.vue'

@Component({
  components: {
    UpdateList,
    DropdownUpdates,
    DropdownActivitylog,
  },
})
export default class ListsOverview extends Vue {
  @Prop({ default: 5 }) limit: number;
  showAll = false;

  mounted() {

  }

  get lists(): List[] {
    return this.$vmx.list.lists;
  }

  get limitedLists(): List[] {
    return this.showAll ? this.lists : this.lists.slice(0, this.limit);
  }

  get lineClasses() {
    return [
      'border-bottom',
      'border-trademark-25',
    ]
  }

  getBudgetString(budgetSum: number): string {
    if (budgetSum) {
      return this.$n(budgetSum, 'currency', domainUtils.domain.numberFormat);
    }
    return this.$t('general.emptyField') as string;
  }

  getLogsInList(list: List): ActivityLog[] {
    return []; // TODO

    // let items: ActivityLog[] = [];
    // 
    //
    // items.push(...list.procurements);
    // items.push(...list.projects);
    //
    // items = items.sort((item1, item2) => {
    //   if(!item1.updates || item1.updates.length <= 0) {
    //     return 1;
    //   }
    //   if (!item2.updates || item2.updates.length <= 0) {
    //     return -1;
    //   }
    //   let newestDate1: string = item1.updates.reduce((prev, curr) => {
    //     return moment(curr.created).isAfter(moment(prev.created)) ? curr : prev;
    //   }).created;
    //   let newestDate2: string = item2.updates.reduce((prev, curr) => {
    //     return moment(curr.created).isAfter(moment(prev.created)) ? curr : prev;
    //   }).created;
    //   return moment(newestDate1).isAfter(moment(newestDate2)) ? -1 : 1;
    // });
    //
    // return items;
  }

  createRouterLocation(item: (Project | Procurement)) {
    if (globalFunctions.isProcurement(item)) {
      return {name: RouterNames.LOGGED_IN_PROCUREMENT_VIEW, params: {id: item.id.toString()}};
    } else {
      return {name: RouterNames.PRINT_PROJECT, params: {id: item.id.toString()}};
    }
  }

  listHasUnseenNotifications(list: List): boolean {
    return dateUtils.isDateStringAfter(list.lastUpdated, list.lastSeen);
  }

  updateSeenForList(id: number) {
    listService.setListSeen(id).then((list) => {
      this.lists[this.lists.findIndex(x => x.id === id)].lastSeen = list.lastSeen;
    })
  }

  openListSettingsModal(list: List) {
    this.$vmx.modal.openListSettingsModal({list});
  }

  openListPage(list: List) {
    this.$router.push({ name: RouterNames.LOGGED_IN_LIST_VIEW, params: { id: list.id.toString() } });
  }

  openViewFromActivityLog(log: ActivityLog) {
    if (log) {
      this.$router.push({
        name: log.isProcurement ? RouterNames.LOGGED_IN_PROCUREMENT_VIEW : RouterNames.LOGGED_IN_PROJECT_VIEW,
        params: { id: log.id.toString() },
      })
    }
  }
}







































import { Component, Prop, Ref, VModel, Vue, Watch } from 'vue-property-decorator';
import { stringUtils } from '@/util/string-utils';

@Component
export default class BaseInputField extends Vue {
  @VModel() text: string;
  @Prop() label: string;
  @Prop({ default: 'text' }) type : 'number' | 'password' | 'email' | 'text';
  @Prop() placeholder: string;
  @Prop() invalidText: string;

  @Prop({ default: 'never' }) liveValidate: 'always' | 'never' | 'onlyOnValid' | 'onlyOnInvalid';

  @Prop() inputGroupTextBefore: string;
  @Prop() inputGroupTextAfter: string;

  @Prop({ default: false }) required: boolean
  @Prop({ default: false }) skipNormalValidation: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Ref() input: HTMLInputElement;
  @Prop({ default: null }) validator: (val: string) => boolean;
  @Prop({ default: null }) isValidCb: (val: boolean) => void;

  public validate(text?: string): boolean {
    const isValid = this.isValid(text ?? this.text);

    if (this.liveValidate === 'always') {
      this.setValidClass(isValid);
    } else if (this.liveValidate === 'onlyOnValid') {
      this.setValidClass(isValid ? true : null);
    } else if (this.liveValidate === 'onlyOnInvalid') {
      this.setValidClass(!isValid ? false : null);
    }
    return isValid;
  }

  @Watch('liveValidate')
  public liveValidateChange() {
    this.validate();
  }

  set inputField(text: string) {
    this.text = text;
    this.validate(text);
  }

  get inputField(): string {
    return this.text;
  }

  public isValid(text: string) {
    if (!this.required) {
      return true;
    }

    let isValid = true;
    if (this.type === 'number') {
      isValid = this.validateNumber(text);
    } else if (this.type === 'password') {
      isValid = this.validatePassword(text);
    } else if (this.type === 'email') {
      isValid = this.validateEmail(text);
    } else {
      isValid = this.validateText(text);
    }

    if (this.validator != null) {
      isValid = this.validator(text);
    }

    if (this.isValidCb != null) {
      this.isValidCb(isValid);
    }

    return isValid;
  }

  public setValidClass(isValid: boolean) {
    if (isValid != null) {
      this.input.classList.add(isValid ? 'is-valid' : 'is-invalid');
      this.input.classList.remove(isValid ? 'is-invalid' : 'is-valid');
    } else {
      this.input.classList.remove('is-valid');
      this.input.classList.remove('is-invalid');
    }
  }

  private validateText(text: string): boolean {
    let isValid = !this.required || (!!text && text.length > 0);
    return isValid;
  }

  private validateNumber(text: string): boolean {
    return stringUtils.isNumeric(text, this.required);
  }

  private validatePassword(text: string): boolean {
    let isValid = !this.required || (!!text && text.length > 0);
    if (!this.skipNormalValidation) {
      isValid = isValid && !!text && text.length >= 8;
    }
    return isValid;
  }

  private validateEmail(text: string): boolean {
    let isValid = true;
    if (this.required || (!!text && text.length > 0)) {
      const format = /^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/;
      isValid = isValid && format.test(text);
      isValid = this.skipNormalValidation || isValid;
    }
    return isValid;
  }
}

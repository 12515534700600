import { Component, Prop, Vue } from 'vue-property-decorator';
import FormChangePassword from '@/components/forms/form-change-password.vue';
import TheHeader from '@/components/headers/the-header.vue';

@Component({
  components: {
    FormChangePassword,
    TheHeader,
  },
})
export default class ChangePasswordView extends Vue {
  @Prop({ required: true }) resetToken: string;

  getBackground(fileName: string) {
    return 'url(' + this.$vmx.file.fromFile('project-agent/backgrounds/' + fileName) + ')';
  }
}

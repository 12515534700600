






































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { Carousel } from 'bootstrap'
import { File } from '@/interfaces/file';
// import { globalFunctions } from '@/util/global-functions';

@Component({
  components: {
    BaseModalContainer,
  },
})
export default class ImageCarousel extends Vue {
  @Prop({type: String, required: true}) private carouselId: number;
  @Prop({type: Array, required: true}) private images: File[];
  @Prop({ type: Boolean, default: true }) private showControls: boolean;
  @Prop({
    type: Number,
    default: 3,
    validator: function(value: number) {
      return [1,2,3,4,6,12].indexOf(value) !== -1;
    },
  })
  private imagesShown: number;
  @Prop({type: Number, default: 4000}) private interval: number;
  @Ref() carousel: HTMLDivElement;
  @Ref() carouselInner: HTMLDivElement;
  getFromFile = this.$vmx.file.fromFile;
  selectedImage: File = null;

  mounted() {
    this.carouselInner.firstElementChild.classList.add('active');
    new Carousel(this.carousel);
  }

  public imageFromFile(image: File) {
    return image.link ? image.link : this.getFromFile(`${image.code}.${image.extension}`);
  }

  public modularOfShown() {
    if (this.images && this.images.length > 0) {
      return [...Array(this.images.length-1)].map((_,i)=>i).filter(i=>i%this.imagesShown===0);
    }
    return [];
  }

  public selectImage(image: File) {
    this.selectedImage = image;
  }

}






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PrintRow extends Vue {
  @Prop({ default: 'MISSING LABEL'}) label: string;
  @Prop({ default: null }) datas: { data: string; class: string[] }[]

  get getDatas() {
    const filterdata = this.datas?.filter(({ data }) => data != null && data.length > 0);
    if (filterdata == null || filterdata.length == 0) {
      return null;
    } else {
      return filterdata;
    }
  }
}

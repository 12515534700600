import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Company } from '@/interfaces/company';
import { CompanyAgent } from '@/interfaces/search/company-agent';
import CompanyCriteriaMenu from '@/components/search-components/company-criteria-menu.vue';
import DropdownOrderBy from '@/components/dropdowns/dropdown-order-by.vue';
import Error401 from '@/components/error-components/error-401.vue';
import { globalFunctions } from '@/util/global-functions';
import { OrderByOptions } from '@/util/enums/order-by-options';
import SearchInput from '@/components/form-fields/search-input.vue';
import TableCompanyList from '@/components/company-list-components/table-company-list.vue';
import TableCompanyListsAll from '@/components/company-list-components/table-company-lists-all.vue';

@Component({
  components: {
    CompanyCriteriaMenu,
    DropdownOrderBy,
    Error401,
    TableCompanyList,
    TableCompanyListsAll,
    SearchInput,
  },
})
export default class SearchCompanyView extends Vue {
  @Ref() tableCompanyList: TableCompanyList;
  private showMoreLimit = 10;
  orderByOptions: [OrderByOptions, string][] = [
    [null as OrderByOptions, this.$t('components.sorting.bestMatch') as string],
    [OrderByOptions.UPDATED, this.$t('components.sorting.updated') as string],
  ];

  get companyAgent(): CompanyAgent {
    return this.$vmx.searchCompany.companyAgent;
  }

  get count(): number {
    return this.$vmx.searchCompany.count;
  }

  get freeSearch(): string {
    return this.$vmx.searchCompany.companyAgent.freeSearch;
  }

  set freeSearch(freeSearch: string) {
    this.$vmx.searchCompany.updateCompanyAgent({freeSearch});
  }

  get isSearchEmpty(): boolean {
    return this.$vmx.searchCompany.isSearchEmpty;
  }

  get limit(): number {
    return this.$vmx.searchCompany.limit;
  }

  set limit(newLimit: number) {
    this.$vmx.searchCompany.setLimit(newLimit);
  }

  get loading(): boolean {
    return this.$vmx.searchCompany.loading || this.results == null;
  }

  get orderBy(): string {
    return this.orderByOptions.find(x => x[0] === this.$vmx.searchCompany.orderBy)[1];
  }

  set orderBy(orderBy: string) {
    this.$vmx.searchCompany.setOrderBy(this.orderByOptions.find(x => x[1] === orderBy)[0]);
  }

  get results(): Company[] {
    return this.$vmx.searchCompany.results;
  }

  @Watch('$vmx.searchCompany.isSearchEmpty')
  @Watch('$vmx.searchCompany.loading')
  @Watch('results')
  resizeTable() {
    globalFunctions.timeout(50).then(() => {
      if (this.tableCompanyList) {
        this.tableCompanyList.resizeHandler();
      }
    });
  }

  addToLimit() {
    this.limit = this.limit + this.showMoreLimit;
  }

  openCompanyAgentReplaceModal() {
    this.$vmx.modal.openCompanyAgentReplaceModal(this.companyAgent);
  }

  openCompanyAgentSettingsModal() {
    this.$vmx.modal.openCompanyAgentSettingsModal(this.companyAgent);
  }

  showMore() {
    this.$vmx.searchCompany.setLimit(this.$vmx.searchCompany.limit + this.showMoreLimit);
  }
}



















































import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { AbstractCriteria } from '@/interfaces/criterias/abstract-criteria';
import BaseFoldMenu from '../buttons/base-fold-menu.vue';

@Component({
  name: 'CriteriaTree',
  components: {
    CriteriaTree,
    BaseFoldMenu,
  },
})
export default class CriteriaTree extends Vue {
  @VModel() criterias: AbstractCriteria[];
  @Prop({ required: true }) criteriaOptions: AbstractCriteria[];
  @Prop({ default: null }) countRecord: Record<number, number>;
  @Prop({ default: null }) isDisabled: (criteria: AbstractCriteria) => boolean;

  @Prop({ default: 2 }) yPadding: number;

  getCriteriaCount(criteria: AbstractCriteria): number {
    if (criteria.children == null || criteria.children.length == 0) {
      return this.countRecord != null && this.countRecord[criteria.id] != null
        ? this.countRecord[criteria.id]
        : 0
    }

    return criteria.children?.reduce(
      (acc, child) => acc + this.getCriteriaCount(child),
      this.countRecord != null && this.countRecord[criteria.id] != null
        ? this.countRecord[criteria.id]
        : 0,
    );
  }

  isChecked(criteria: AbstractCriteria): boolean {
    return this.criterias.some((criteria0) => criteria0.id === criteria.id);
  }

  click(criteria: AbstractCriteria) {
    const isChecked = this.isChecked(criteria);

    const subs = this.findChildren(criteria).filter(
      (sub) => this.isChecked(sub) === isChecked,
    );

    if (isChecked) {
      this.criterias = this.criterias.filter(
        (criteria0) => !subs.some((sub) => sub.id === criteria0.id),
      )
    } else {
      this.criterias = [ ...this.criterias, ...subs ];
    }
  }

  private checkCriteria(criteria: AbstractCriteria, check: boolean): void {
    const isChecked = this.isChecked(criteria);

    if (criteria.children != null && criteria.children.length != 0) {
      criteria.children.forEach((child) => this.checkCriteria(child, check));
    }

    if (check && !isChecked) {
      this.criterias = [ criteria, ...this.criterias ];
    } else if (!check && isChecked) {
      this.criterias = this.criterias.filter((criteria0) => criteria0.id != criteria.id)
    }
  }

  private findChildren(criteria: AbstractCriteria): AbstractCriteria[] {
    const children = criteria?.children?.flatMap(this.findChildren);
    return [ criteria, ...(children ?? [])]
  }

}

import { Component, Vue } from 'vue-property-decorator';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import FormChangePassword from '@/components/forms/form-change-password.vue';
import FormUserSettings from '@/components/forms/form-user-settings.vue';
import { formValidator } from '@/util/form-validator';
import { globalFunctions } from '@/util/global-functions';
import { Modal } from 'bootstrap';
import ModalChangeSubscription from '@/components/modals/modal-change-subscription.vue';
import { Product } from '@/interfaces/product';
import { User } from '@/interfaces/user';
import { userService } from '@/services/restapi/user-service';

@Component({
  components: {
    BaseModalContainer,
    FormChangePassword,
    FormUserSettings,
    ModalChangeSubscription,
  },
})
export default class UserSettingsView extends Vue {
  user: User = { ...this.$vmx.user.user };
  initialEmail: string = this.$vmx.user.user.mail;
  password1: string = null;
  password2: string = null;
  showEmailSent = false;
  showEmailSentError = false;
  showCancelSent = false;
  showChangeSent = false;
  submitPressed = false;
  retries = 0;

  get validUser(): boolean {
    return (
      this.validUsername &&
      this.validName &&
      this.validPhone &&
      this.validMail &&
      this.validPosition
    );
  }

  get validUsername(): boolean {
    if (!this.submitPressed) {
      return null;
    }
    return formValidator.validateLetterNumberString(this.user.username);
  }

  get validName(): boolean {
    if (!this.submitPressed) {
      return null;
    }
    return formValidator.isBetween1And255Characters(this.user.name);
  }

  get validMail(): boolean {
    if (!this.submitPressed) {
      return null;
    }
    return formValidator.validateEmail(this.user.mail);
  }

  get validPhone(): boolean {
    if (!this.submitPressed) {
      return null;
    }
    return formValidator.validatePhoneNumber(this.user.phone);
  }

  get validPosition(): boolean {
    if (!this.submitPressed) {
      return null;
    }
    return (
      this.user.position == null ||
      this.user.position == '' ||
      formValidator.validateLetterString(this.user.position)
    );
  }

  get invalidUsernameFeedback(): string {
    if (this.user.username.length < 1) {
      return 'Indtast et brugernavn';
    } else if (this.user.username.length > 255) {
      return 'Brugernavnet må maks være 255 tegn';
    }
  }

  get invalidNameFeedback(): string {
    if (this.user.name.length < 1) {
      return 'Indtast dit navn';
    } else if (this.user.name.length > 255) {
      return 'Dit navn må maks være 255 tegn';
    } else {
      return 'Navnet må kun indeholde bogstaver';
    }
  }

  get invalidPositionFeedback(): string {
    if (this.user.position == null) {
      return '';
    } else if (this.user.position.length < 1) {
      return 'Indtast din stilling';
    } else if (this.user.position.length > 255) {
      return 'Din stilling må maks være 255 tegn';
    } else {
      return 'Stillingen må kun indeholde bogstaver';
    }
  }
  get invalidPasswordFeedback(): string {
    if (this.password1 == null) {
      return '';
    } else if (this.password1 != this.password2) {
      return 'De to kodeord skal være ens';
    } else {
      return 'Kodeordet skal være mindst 8 tegn';
    }
  }

  async changePassword() {
    userService.sendChangePasswordEmail().then(
      (success) => {
        this.showEmailSent = true;
        globalFunctions.timeout(5000).then(() => (this.showEmailSent = false));
      },
      (error) => {
        this.showEmailSentError = true;
        globalFunctions.timeout(5000).then(() => (this.showEmailSentError = false));
      },
    );
  }

  async cancelSubscription() {
    userService.sendCancelSubscription().then(() => {
      this.showCancelSent = true;
      const element = document.getElementById('cancel-subscription-modal');
      if (element) {
        const modal = Modal.getInstance(element);
        if (modal) {
          modal.hide();
        }
      }
    });
  }

  changeSubscription(newSubscription: string) {
    userService.sendChangeSubscription(newSubscription).then(() => {
      this.showChangeSent = true;
    });
  }
}

import moment from 'moment';

export class FormatUtils {
  constructor() {}

  public formatDate = (date: string | Date, format: string): string => {
    if (date) {
      return moment(date).locale('process.env.VUE_APP_I18N_LOCALE').format(format);
    }
    return 'N/A';
  };

  public formatTextToHtml = (text: string): string => {
    if (text && !text.includes('<p') && !text.includes('<div')) {
      text = text.replace(/(.*)(?:\r\n|\n)/g, '<p>$1</p>');
      text = text.replaceAll('<p></p>', '');
    }
    return text;
  };
}

type DateType = {
  day: number;
  month: number;
  year: number;
  hour: number;
  minute: number;
};

export const formatUtils = new FormatUtils();

















































import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalPaywall from '@/components/modals/modal-paywall.vue';
import moment from 'moment';
import { ProcurementPhase } from '@/interfaces/criterias/procurement-phase';
import { ProcurementPhaseRecord } from '@/interfaces/procurement-phase-record';
import { ProjectPhase } from '@/interfaces/criterias/project-phase';
import { ProjectPhaseRecord } from '@/interfaces/project-phase-record';

@Component({
  components: {
    ModalPaywall,
  },
})
export default class PhaseTimeline extends Vue {
  @Prop() private phaseRecords: ProcurementPhaseRecord[] | ProjectPhaseRecord[];
  @Prop() private currentPhase: ProcurementPhase | ProjectPhase;

  windowSize = window.innerWidth;

  mounted() {
    window.addEventListener('resize', this.resizeHandler);
  }

  get isAboveBasicProduct(): boolean {
    return this.$vmx.user.hasProduct;
  }

  public getPhase (record: ProcurementPhaseRecord | ProjectPhaseRecord) {
    if ((record as ProcurementPhaseRecord).procurementPhase !== undefined) {
      return (record as ProcurementPhaseRecord).procurementPhase;
    } else {
      return (record as ProjectPhaseRecord).projectPhase;
    }
  }

  public isCurrentPhase(record: ProcurementPhaseRecord | ProjectPhaseRecord) {

    if (((record as ProcurementPhaseRecord).procurementPhase != undefined
          && (record as ProcurementPhaseRecord).procurementPhase.id === this.currentPhase.id)
        || ((record as ProjectPhaseRecord).projectPhase != undefined
          &&  (record as ProjectPhaseRecord).projectPhase.id === this.currentPhase.id)) {
      return true;
    }
    return false;
  }

  public isBeforeCurrent(record: ProcurementPhaseRecord | ProjectPhaseRecord) {
    if (((record as ProcurementPhaseRecord).procurementPhase != undefined
          && (record as ProcurementPhaseRecord).procurementPhase.index < this.currentPhase.index)
        || ((record as ProjectPhaseRecord).projectPhase != undefined
          &&  (record as ProjectPhaseRecord).projectPhase.index < this.currentPhase.index)) {
      return true;
    }
    return false;
  }

  public isNotLast(index: number) {
    return index < this.phaseRecords.length - 1;
  }

  public formatDate(date: Date) {
    if (date) {
      return moment(date).format('DD.MM.YYYY');
    }
    return 'N/A';
  }

  public resizeHandler() {
    this.windowSize = window.innerWidth;
  }

  public getCircleClasses(record: ProcurementPhaseRecord | ProjectPhaseRecord) {
    const currentPhase = this.isCurrentPhase(record);
    const beforeCurrent = this.isBeforeCurrent(record);
    return {
      'circle-filled': beforeCurrent,
      'circle-bordered': currentPhase,
      'circle-dashed': !currentPhase && !beforeCurrent,
    };
  }

  public getLineClasses(record: ProcurementPhaseRecord | ProjectPhaseRecord) {
    const beforeCurrent = this.isBeforeCurrent(record);
    return {
      'line': beforeCurrent,
      'line-dashed': !beforeCurrent,
    };
  }

  public getFontClasses(record: ProcurementPhaseRecord | ProjectPhaseRecord) {
    const currentPhase = this.isCurrentPhase(record);
    const beforeCurrent = this.isBeforeCurrent(record);
    return {
      'lato-sm': !currentPhase,
      'lato': currentPhase,
      'text-trademark': currentPhase || beforeCurrent,
      'text-trademark-50': !currentPhase && !beforeCurrent,
    };
  }

  public getSvgClasses(record: ProcurementPhaseRecord | ProjectPhaseRecord) {
    const beforeCurrent = this.isBeforeCurrent(record);
    const isCurrent = this.isCurrentPhase(record);
    return {
      'text-trademark': isCurrent,
      'text-white': beforeCurrent,
      'text-trademark-50': !beforeCurrent && !isCurrent,
      'big-icon': isCurrent,
      'normal-icon': !isCurrent,
    };
  }

  // public getSize(record: ProcurementPhaseRecord | ProjectPhaseRecord) {
  //   return this.isCurrentPhase(record) && this.isAboveBasicProduct ? '2x' : '1x';
  // }
}

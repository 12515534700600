import { Component, Vue } from 'vue-property-decorator';
import ContactOptions from '@/components/general-components/contact-options.vue';
import { Notification } from '@/interfaces/notification';
import { notificationService } from '@/services/restapi/notification-service';
import NotificationSidebar from '@/components/sidebars/notification-sidebar.vue';
import UserGuide from '@/components/help-components/user-guide.vue';

@Component({
  components: {
    ContactOptions,
    NotificationSidebar,
    UserGuide,
  },
})
export default class HelpView extends Vue {
  notifications: Notification[] = null;
  showSidebar = false;

  openNotificationSidebar() {
    this.showSidebar = true;
    notificationService.getNotifications(null).then((notifications) => {
      notifications.sort((a, b) => a.created > b.created ? -1 : 1);
      this.notifications = notifications;
    });
  }
}

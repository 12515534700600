
























































import { Component, Prop, Ref, VModel, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class AgentCriteriaRange extends Vue {
  @VModel() model: [number, number];
  @Prop({ required: true }) max: number;
  @Prop({ required: true }) min: number;
  @Prop({ default: 'missing' }) formatText: string;
  @Ref() minInput: HTMLInputElement;
  @Ref() maxInput: HTMLInputElement;

  readonly INPUT_TIMEOUT = 3000;

  rangeValue: [number, number] = null;
  dbounseTimer: ReturnType<typeof setTimeout> = null;

  get range() {
    if (this.rangeValue == null) {
      this.rangeValue = [
        Math.min(this.max, Math.max(this.min, this.model?.[0] ?? this.min)),
        Math.min(this.max, Math.max(this.min, this.model?.[1] ?? this.max)),
      ]
    }

    return this.rangeValue;
  }

  set range(val) {
    this.rangeValue = [
      Math.max(this.min, Math.min(this.max, val[0])),
      Math.max(this.min, Math.min(this.max, val[1])),
    ];
  }

  get minInputValue() {
    return this.rangeValue[0];
  }

  set minInputValue(val) {
    if (val > this.range[1]) {
      this.maxInput.focus();
    }
    this.range = [val, this.range[1]]
    this.onInputChange();
  }

  get maxInputValue() {
    return this.rangeValue[1];
  }

  set maxInputValue(val) {
    if (val < this.range[0]) {
      this.minInput.focus();
    }
    this.range = [this.range[0], val]
    this.onInputChange();
  }

  @Watch('model')
  onModelChange() {
    this.range = [
      this.model[0] ?? this.min,
      this.model[1] ?? this.max,
    ];
  }

  onSliderDragEnd() {
    this.model = this.rangeValue;
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.model = this.rangeValue;
      if (this.dbounseTimer != null) {
        clearTimeout(this.dbounseTimer);
        this.dbounseTimer = null;
      }
      return
    }
    if (this.dbounseTimer != null) {
      clearTimeout(this.dbounseTimer);

      this.dbounseTimer = setTimeout(() => {
        this.model = this.rangeValue;
        this.dbounseTimer = null;
      }, this.INPUT_TIMEOUT)
    }
  }

  onInputChange() {
    if (this.dbounseTimer != null) {
      clearTimeout(this.dbounseTimer);
    }

    this.dbounseTimer = setTimeout(() => {
      this.model = this.rangeValue;
      this.dbounseTimer = null;
    }, this.INPUT_TIMEOUT)
  }
}

import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { SuggestedProduct } from '@/interfaces/suggested-product';

const api = 'suggested-products';

export class SuggestedProductService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getSuggestedProducts = async (): Promise<SuggestedProduct[]> => {
    const response = await this.get<SuggestedProduct[]>(api);
    return this.getResponse(response);
  };
}

export const suggestedProductService = new SuggestedProductService(apiConfig);

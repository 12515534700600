


























































import { Component, Vue } from 'vue-property-decorator';
import { helpdeskService } from '@/services/restapi/helpdesk-service';
import { HelpdeskTab } from '@/interfaces/help/helpdesk-tab';
import UserGuideRow from '@/components/help-components/user-guide-row.vue'

@Component({
  components: {
    UserGuideRow,
  },
})
export default class UserGuide extends Vue {
  tabsData: HelpdeskTab[] = null;
  loading = true;

  get tabs(): HelpdeskTab[] {
    if (this.tabsData != null) return this.tabsData;

    helpdeskService.getHelpdeskTabs().then((tabs) => {
      this.tabsData = tabs;
      this.tabsData.sort((x, y) => (x.index < y.index ? -1 : 1));
      this.loading = false;
    });
    return this.tabsData;
  }

  get currentTab(): HelpdeskTab {
    return this.tabs.filter(tab => tab.id.toString() == this.$route.query.tab)[0];
  }

  set currentTab(tab: HelpdeskTab) {
    if (this.$route.query.tab != null && this.$route.query.tab == tab.id.toString()) {
      return;
    }

    this.$router.push({
      query: { 
        ...this.$route.query,
        page : [],
        tab : [ tab.id.toString() ],
      },
    })
  }

  isCurrentPage(id: number) {
    return this.$route.hash != null && this.$route.hash == `#page${id}`;
  }

  setCurrentPage(id: number) {
    if (this.$route.hash != null && this.$route.hash == `#page${id}`) {
      this.$router.push({
        hash : undefined,
        query : this.$route.query,
      })
      return;
    }

    this.$router.push({
      hash : `page${id}`,
      query : this.$route.query,
    })
  }
}

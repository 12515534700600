
































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseInputField from '@/components/form-fields/base-input-field.vue';
import { ResponseCodes } from '@/util/enums/response-codes';

@Component({
  components: {
    AbstractForm,
  },
})
export default class FormImpersonate extends Vue {
  @Prop({ required: true }) token: string;

  @Ref() inputUsername: BaseInputField;
  @Ref() inputPassword: BaseInputField;

  adminUsername = '';
  adminPassword = '';
  failedLogin = false;
  showError = false;

  get formIsValid() {
    let isValid = this.inputUsername.validate();
    isValid = this.inputPassword.validate() && isValid;
    return isValid;
  }

  async submit() {
    this.failedLogin = false;
    this.showError = false;
    if (!this.formIsValid) {
      return;
    }

    try {
      this.$vmx.session.impersonate({
        username: this.adminUsername,
        password: this.adminPassword,
        token: this.token,
      });
    } catch(error) {
      if (error === ResponseCodes.UNAUTHORIZED) {
        this.failedLogin = true;
      } else {
        this.showError = true;
      }
    }
  }
}

import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { Quote } from '@/interfaces/quote';

const api = '/quotes';

export class QuoteService extends AbstractService {
  
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getQuotes = async (): Promise<Quote[]> => {
    const response = await this.get<Quote[]>(api);
    return this.getResponse(response);
  }
}

export const quoteService = new QuoteService(apiConfig);
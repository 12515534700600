































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CompanyAgent } from '@/interfaces/search/company-agent';
import CompanyAgentCountField from '@/components/dashboard-components/company-agent-count-field.vue';
import ModalAgentSettings from '@/components/modals/modal-agent-settings.vue';
import { RouterNames } from '@/util/enums/router-names';

@Component({
  components: {
    CompanyAgentCountField,
    ModalAgentSettings,
  },
})
export default class CompanyAgentsOverview extends Vue {
  @Prop({ default: 5 }) limit: number;
  showAll = false;

  get companyAgents(): CompanyAgent[] {
    return this.$vmx.companyAgent.companyAgents;
  }

  get limitedCompanyAgents(): CompanyAgent[] {
    return this.showAll ? this.companyAgents : this.companyAgents.slice(0, this.limit);
  }

  get lineClasses() {
    return [
      'border-bottom',
      'border-trademark-25',
    ]
  }

  openModal(id: number) {
    this.$bvModal.show(`agent-settings-modal-${id}`);
  }

  searchWithCompanyAgent(companyAgent: CompanyAgent) {
    this.$vmx.searchCompany.setCompanyAgent(companyAgent);
    this.$router.push({ name: RouterNames.LOGGED_IN_COMPANY_SEARCH, params: { page: '1' } });
  }

  openCompanyAgentSettingsModal(companyAgent: CompanyAgent) {
    this.$vmx.modal.openCompanyAgentSettingsModal(companyAgent);
  }
}

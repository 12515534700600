

































































import { Component, Vue } from 'vue-property-decorator';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { Company } from '@/interfaces/company';
import { CompanyList } from '@/interfaces/company-list';
import { Modal } from 'bootstrap';
import { ModalIds } from '@/util/enums/modal-ids';

@Component({
  components: {
    BaseModalContainer,
  },
})
export default class ModalCompanyListRemove extends Vue {
  selectedCompanyLists: CompanyList[] = [];
  loading = true;
  submitting = false;
  error = false;

  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  get company(): Company {
    return this.$vmx.modal.company;
  }

  get companyListsWithCompany(): CompanyList[] {
    return this.$vmx.companyList.companyListsWithCompany(this.company);
  }

  get modalId(): string {
    return ModalIds.COMPANY_LIST_REMOVE_MODAL;
  }

  addCompanyList(companyList: CompanyList) {
    if (!this.selectedContainsCompanyList(companyList.id)) {
      this.selectedCompanyLists.push(companyList);
    }
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }

  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal();
    }
  }

  createCompanyListName(companyList: CompanyList): string {
    const length = companyList.paUserIds.length;
    if (length > 1) {
      return `${companyList.name} (${this.$tc('components.iconBar.list.sharedWidth', length - 1, [length - 1])})`;
    }
    return companyList.name;
  }

  hideModal() {
    const element = document.getElementById(this.modalId);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }

  initializeModal() {
    this.loading = true;
    if (this.companyListsWithCompany.length > 0) {
      this.selectedCompanyLists.push(this.companyListsWithCompany[0]);
    }
    this.loading = false;
  }

  removeCompanyList(id: number) {
    if (this.selectedContainsCompanyList(id)) {
      const index = this.selectedCompanyLists.findIndex((companyList) => companyList.id === id);
      if (index >= 0) {
        this.selectedCompanyLists.splice(index, 1);
      }
    }
  }

  async submit() {
    if (this.selectedCompanyLists.length <= 0) {
      return;
    }
    this.submitting = true;
    this.error = false;
    try {
      for (const selectedCompanyList of this.selectedCompanyLists) {
        let newCompanyList: CompanyList = null;
        const index = selectedCompanyList.companies.findIndex(com => com.id == this.company.id);
        if (index >= 0) {
          selectedCompanyList.companies.splice(index, 1);
          newCompanyList = await this.$vmx.companyList.putCompanyList(selectedCompanyList);
          if (!newCompanyList) {
            this.error = true;
            break;
          }
        }
      }
      if (!this.error)  {
        this.$vmx.companyList.refetchCompanyLists();
        this.hideModal();
      }
      this.submitting = false;
    } catch (error) {
      this.error = true;
      this.submitting = false;
    }
  }

  selectedContainsCompanyList(id: number): boolean {
    return this.selectedCompanyLists.some(companyList => companyList.id === id);
  }
}

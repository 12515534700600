import { action } from 'vuex-class-component';
import { Company } from '@/interfaces/company';
import { CompanyList } from '@/interfaces/company-list';
import { companyListService } from '@/services/restapi/company-list-service';
import { createModule } from '../preloader';
import { vmx } from '..';

export default class CompanyListStore extends createModule('company-list') {
  private companyListsData = null as CompanyList[];
  private companyListPromise = null as Promise<CompanyList[]>;

  get companyListById(): (id: number) => CompanyList {
    return (id) => {
      if (this.companyLists === null) {
        return null;
      }
      for (const companyList of this.companyLists) {
        if (companyList.id == id) {
          return companyList;
        }
      }
      return null;
    }
  }

  get companyLists(): CompanyList[] {
    return this.companyListsData;
  }

  get companyListsLoaded(): boolean {
    return this.companyLists != null;
  }

  get companyListsWithCompany(): (company: Company) => CompanyList[] {
    return (company) => {
      if (!company) {
        return [];
      }
      return this.companyLists.filter((list) =>
        list.companies && list.companies.some((com) => com.id === company.id),
      );
    }
  }

  get isIdInCompanyList(): (id: number) => boolean {
    return (id) => {
      for (const list of this.companyLists ?? []) {
        if (list.companies.some((listCom) => listCom.id === id)) {
          return true;
        }
      }
      return false;
    }
  }

  get isInCompanyList(): (company: Company) => boolean {
    return (company) => {
      for (const list of this.companyLists ?? []) {
        if (list.companies.some((listCom) => listCom.id === company.id)) {
          return true;
        }
      }
      return false;
    }
  }


  @action
  async deleteCompanyList(id: number): Promise<void> {
    await companyListService.deleteCompanyList(id);
    this.companyListPromise = companyListService.getCompanyLists();
    this.companyListsData = await this.companyListPromise;
  }

  @action
  async fetchCompanyLists(): Promise<CompanyList[]> {
    if (!vmx.user.hasCompanyAccess) {
      return [];
    }
    if (this.companyListsData != null) {
      return this.companyListsData;
    }

    if (this.companyListPromise != null) {
      return await this.companyListPromise;
    }

    this.companyListPromise = companyListService.getCompanyLists();
    this.companyListsData = await this.companyListPromise;

    return this.companyListsData;
  }

  @action
  async postCompanyList(companyList: CompanyList): Promise<CompanyList> {
    const newCompanyList = await companyListService.createCompanyList(companyList);

    this.companyListPromise = companyListService.getCompanyLists();
    this.companyListsData = await this.companyListPromise;

    return newCompanyList;
  }

  @action
  async putCompanyList(companyList: CompanyList): Promise<CompanyList> {
    const newCompanyList = await companyListService.updateCompanyList(companyList);

    this.companyListPromise = companyListService.getCompanyLists();
    this.companyListsData = await this.companyListPromise;

    return newCompanyList;
  }

  @action
  async refetchCompanyLists(): Promise<CompanyList[]> {
    if (!vmx.user.hasCompanyAccess) {
      return [];
    }
    
    this.companyListPromise = companyListService.getCompanyLists();
    this.companyListsData = await this.companyListPromise;

    return this.companyListsData;
  }
}
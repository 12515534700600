import { AbstractService } from '../superclasses/abstract-service';
import { apiConfig } from '../config/api.config';
import { AxiosRequestConfig } from 'axios';
import { HelpdeskTab } from '@/interfaces/help/helpdesk-tab';

const api = '/helpdesk';

export class HelpdeskService extends AbstractService {
  constructor(config: AxiosRequestConfig) {
    super(config);
  }

  public getHelpdeskTabs = async (): Promise<HelpdeskTab[]> => {
    const response = await this.get<HelpdeskTab[]>(api);
    return this.getResponse(response);
  };
}

export const helpdeskService = new HelpdeskService(apiConfig);












































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import AbstractForm from '@/components/forms/abstract-form.vue';
import BaseInputField from '@/components/form-fields/base-input-field.vue';
import BaseModalContainer from '@/components/modals/base-modal-container.vue';
import { Company } from '@/interfaces/company';
import { CompanyList } from '@/interfaces/company-list';
import FormMailTime from '@/components/forms/form-mail-time.vue';
import { MailTime } from '@/interfaces/enums/mail-time';
import { Modal } from 'bootstrap';
import { ModalIds } from '@/util/enums/modal-ids';
import { RouterNames } from '@/util/enums/router-names';
import { User } from '@/interfaces/user';

@Component({
  components: {
    AbstractForm,
    BaseModalContainer,
    FormMailTime,
  },
})
export default class ModalCompanyListSettings extends Vue {
  @Ref() companyListName: BaseInputField;
  companyListCopy: CompanyList = null;
  listsWithCompany: CompanyList[] = null;
  users: User[] = [];
  selectedUsers: User[] = [];
  loading = true;
  submitting = false;
  error = false;

  mounted() {
    this.addEventListeners();
  }

  activated() {
    this.addEventListeners();
  }

  get buttonRowClasses() {
    return [
      `justify-content-${this.companyList ? 'between' : 'end'}`,
    ];
  }

  get company(): Company {
    return this.$vmx.modal.company;
  }

  get companyList(): CompanyList {
    return this.$vmx.modal.companyList;
  }

  get deleteModalId(): string {
    return `delete-${this.modalId}`;
  }

  get formIsValid(): boolean {
    return this.companyListName.validate() && this.selectedUsers.length > 0;
  }

  get modalId(): string {
    return ModalIds.COMPANY_LIST_SETTINGS_MODAL;
  }

  @Watch('selectedUsers')
  selectedUsersChanged() {
    this.companyListCopy.paUserIds = this.selectedUsers.map(x => x.id);
  }

  addEventListeners() {
    const modalElement = document.getElementById(this.modalId);
    modalElement?.addEventListener('shown.bs.modal', this.initializeModal);
    modalElement?.addEventListener('hidePrevented.bs.modal', this.clickBackdrop);
  }
  
  clickBackdrop() {
    if (!this.submitting) {
      this.hideModal(this.modalId);
    }
  }

  async createNewList(): Promise<CompanyList> {
    const newCompanyList: CompanyList = {
      id: null,
      name: '',
      mailTime: MailTime.Daily,
      companies: [],
      paUserIds: [],
    }
    if (this.company) {
      newCompanyList.companies = [{
        id: this.company.id,
        name: this.company.companyName,
      }];
    }
    return newCompanyList;
  }

  async deleteCompanyList() {
    this.submitting = true;
    try {
      await this.$vmx.companyList.deleteCompanyList(this.companyListCopy.id)
      this.hideModal(this.deleteModalId);
      if (this.$route.name === RouterNames.LOGGED_IN_COMPANY_LIST_VIEW) {
        this.$router.push({ name: RouterNames.LOGGED_IN_DASHBOARD});
      }
      this.submitting = false;
    }  catch (error) {
      this.error = true;
      this.submitting = false;
    }
  }

  async getOriginalCompanyLists() {
    if (this.company) {
      await this.$vmx.companyList.fetchCompanyLists();
      if (this.$vmx.companyList.companyLists != null && this.$vmx.companyList.companyLists.length > 0)  {
        this.listsWithCompany = this.$vmx.companyList.companyListsWithCompany(this.company)
          .filter(companyList => companyList.id != this.companyListCopy.id);
      }
    }
  }

  hideModal(modal: string) {
    const element = document.getElementById(modal);
    if (element) {
      const modal = Modal.getInstance(element);
      if (modal) {
        modal.hide();
      }
    }
  }

  initializeModal() {
    this.loading = true;
    if (this.companyList) {
      this.companyListCopy = {...this.companyList};
      this.setUsers();
      this.getOriginalCompanyLists().then(() => {
        this.loading = false;
      });
    } else {
      this.createNewList().then((newCompanyList) => {
        this.companyListCopy = newCompanyList;
        this.setUsers();
        this.getOriginalCompanyLists().then(() => {
          this.loading = false;
        });
      });
    }
  }

  private nameValidator(name: string): boolean {
    return name.trim().length >= 1 && name.trim().length <= 100;
  }

  openModal(id: string) {
    const element = document.getElementById(id);
    const modal = new Modal(element);
    modal.show();
  }

  private setUsers() {
    const user = this.$vmx.user.user as User;
    this.users = this.$vmx.user.colleagues;
    if (this.companyListCopy.id) {
      this.selectedUsers = this.users.filter(x => this.companyListCopy.paUserIds.some(paUserId => paUserId === x.id));
    } else {
      this.selectedUsers = [user];
    }
  }

  async submit() {
    if (this.formIsValid) {
      this.submitting = true;
      try {

        const companyListsToUpdate: CompanyList[] = [];

        if (this.listsWithCompany && this.listsWithCompany.length > 0) {
          const removedLists = this.listsWithCompany
            .filter(companyList =>
              companyList.id != this.companyListCopy.id,
            );
          removedLists.forEach((companyList) => {
            companyList.companies = companyList.companies.filter(company => company.id != this.company.id);
            companyListsToUpdate.push(companyList);
          });
        }

        for (const companyListToUpdate of companyListsToUpdate) {
          const companyList = await this.$vmx.companyList.putCompanyList(companyListToUpdate);
          if (companyList) {
            this.error = false;
          } else {
            this.error = true;
            return;
          }
        }

        if (this.companyListCopy.id) {
          await this.$vmx.companyList.putCompanyList(this.companyListCopy);
          this.error = false;
          this.submitting = false;
          this.hideModal(this.modalId);
        } else {
          await this.$vmx.companyList.postCompanyList(this.companyListCopy);
          this.error = false;
          this.submitting = false;
          this.hideModal(this.modalId);
        }
      } catch (error) {
        this.error = true;
        this.submitting = false;
      }
    }
  }
}

































































import { Component, Prop, Vue } from 'vue-property-decorator';
import DropdownOrderBy from '@/components/dropdowns/dropdown-order-by.vue';
import { globalFunctions } from '@/util/global-functions';
import ListItemCard from '@/components/cards/list-item-card.vue';
import moment from 'moment';
import { Procurement } from '@/interfaces/procurement';
import { Project } from '@/interfaces/project';
import SearchInput from '@/components/form-fields/search-input.vue';
import SearchItemCard from '@/components/cards/search-item-card.vue';
import SearchItemsListView from '@/components/search-components/search-items-list-view.vue';
import { TranslateResult } from 'vue-i18n';

@Component({
  components: {
    DropdownOrderBy,
    ListItemCard,
    SearchItemCard,
    SearchInput,
    SearchItemsListView,
  },
})
export default class SmallListLayout extends Vue {
  @Prop({ default: null }) emptyListText: string;
  @Prop({ default: true }) listViewDefault: boolean;

  // Callbacks
  @Prop({ required: true }) onFilterText: (val: string) => void;
  @Prop({ default: null }) onShowBlacklistsed: (val: boolean) => void;
  @Prop({ required: true }) onOrderBy: (val: (a: Project | Procurement, b: Project | Procurement) => number) => void;
  @Prop({ required: true }) onListView: (val: boolean) => void;

  filterText = '';
  orderBy: TranslateResult = null;
  orderByDescending = true;
  listView = true;

  created() {
    this.orderBy = this.sortingOptions[0][0];
  }

  mounted() {
    this.listView = this.listViewDefault;
    this.returnOrderBy();
  }

  resultsForList: (Procurement | Project)[] = null;
  resultsForCard: (Procurement | Project)[] = null;

  sortingOptions: [TranslateResult, (a: Project | Procurement, b: Project | Procurement) => number][] = [
    [ this.$t('components.sorting.updated'), this.compareLatestUpdate ],
    [ this.$t('components.sorting.contractSum'), (a, b) => b.budget - a.budget ],
    [ this.$t('components.sorting.published'), (a, b) => moment(a.published).isBefore(moment(b.published)) ? 1 : -1 ],
  ]

  get orderByDesc() {
    return this.orderByDescending ? 'desc' : 'asc'
  }

  set orderByDesc(val: string) {
    this.orderByDescending = val === 'desc';
    this.returnOrderBy();
  }

  get viewType() {
    return this.listView ? 'list' : 'card';
  }

  set viewType(val: string) {
    this.listView = val === 'list';
    this.onListView(this.listView);
  }

  returnOrderBy() {
    for (const [sortName, sortMethod] of this.sortingOptions) {
      if (sortName == this.orderBy) {
        if (this.orderByDescending) {
          this.onOrderBy(sortMethod);
        } else {
          this.onOrderBy((a, b) => sortMethod(b, a));
        }
        return;
      }
    }
  }

  compareLatestUpdate(a: Procurement | Project, b: Procurement | Project) {
    if (!a.lastUpdateDate) return 1;
    if (!b.lastUpdateDate) return -1;
    return moment(a.lastUpdateDate).isAfter(moment(b.lastUpdateDate)) ? -1 : 1;
  }
}

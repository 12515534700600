















import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import AbstractModal from '@/components/modals/abstract-modal.vue';
import FormBookDemo from '@/components/forms/form-book-demo.vue';

@Component({
  components: {
    AbstractModal,
    FormBookDemo,
  },
})
export default class ModalBookDemo extends Vue {
  @Prop({ required: true }) modalId: string;
  @Prop() backgroundColor: string;
  @Prop() textColor: string;
  @Prop() fade: string;
  @Prop() size: string;
  @Ref() formBookDemo: FormBookDemo;

  public resetSize() {
    this.formBookDemo.setSize();
  }
}































































































































import { Component, Prop, Ref, VModel, Vue } from 'vue-property-decorator';
import BaseButton from '@/components/buttons/base-button.vue';
import BaseSidebar from '@/components/sidebars/base-sidebar.vue';
import { File } from '@/interfaces/file';
import { formatUtils } from '@/util/format-utils';
import { Notification } from '@/interfaces/notification';
import { notificationService } from '@/services/restapi/notification-service';
import { User } from '@/interfaces/user';

@Component({
  components: {
    BaseButton,
    BaseSidebar,
  },
})
export default class NotificationSidebar extends Vue {
  @VModel() show: boolean;
  @Ref() checkButton: BaseButton;
  @Prop({ required: true }) notifications: Notification[];
  @Prop() styleClasses: string;
  @Prop({ default: true }) overlayClickable: boolean;
  @Prop() title: string;
  @Prop({ default: true }) updateOnClick: boolean;
  savingUser = false;
  selectedIndex = 0;
  private getFromFile = this.$vmx.file.fromFile;

  get showNotifications(): boolean {
    return (this.$vmx.user.user as User).showPopups;
  }

  set showNotifications(show: boolean) {
    const user: User = this.$vmx.user.user;
    user.showPopups = show;
    this.savingUser = true;
    this.$vmx.user.putUser(user).finally(() => this.savingUser = false);
  }

  formatDate(date: string) {
    return formatUtils.formatDate(date, 'DD.MM.YYYY');
  }

  fold(index: number) {
    this.selectedIndex = this.selectedIndex === index ? null : index;
  }

  async notificationsSeen() {
    if (this.updateOnClick) {
      await notificationService.setAllSeen();
      this.show = false;
    } else {
      this.show = false;
    }
  }

  imageFromFile(file: File) {
    if (file) {
      return file.link ? file.link : this.getFromFile(`${file.code}.${file.extension}`);
    } else {
      return this.getFromFile(undefined);
    }
  }
}

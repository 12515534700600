import { Agent } from '@/interfaces/agent';
import { User } from '@/interfaces/user';

type Storage = {
  agent: Agent;
  version: string;
  user: User;
};

export function getStorage<K extends keyof Storage, V extends Storage[K]>(key: K): V {
  try {
    const storeAgent = localStorage.getItem(key);
    if (storeAgent == null) {
      return null;
    }

    return JSON.parse(storeAgent);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function setStorage<K extends keyof Storage, V extends Storage[K]>(key: K, value: V): V {
  try {
    if (value == null) {
      return null;
    }
    localStorage.setItem(key, JSON.stringify(value));
    return value;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function clearStorage(): void {
  localStorage.clear();
}

export function removeStorage<K extends keyof Storage>(key: K): void {
  if (key == null) {
    return;
  }
  localStorage.removeItem(key);
}

import moment, { Moment } from 'moment';

export const dateUtils = {
  isDateStringAfter(date1: string, date2: string): boolean {
    if (!date1) {
      return false;
    }
    if (!date2) {
      return true;
    }
    return moment(date1).isAfter(moment(date2));
  },

  isFormattedDateStringAfter(date1: string, date2: string, format: string): boolean {
    if (!date1) {
      return false;
    }
    if (!date2) {
      return true;
    }
    return moment(date1, format).isAfter(moment(date2, format));
  },

  findNextDate(format: string, dates: string[]): string {
    const now = moment.now();
    let closest: Moment = null;
    for (const date of dates) {
      if (closest == null && date !== null) {
        closest = moment(date);
        continue;
      }
      if (date != null && moment(date).isAfter(now) && moment(date).isBefore(closest)) {
        closest = moment(date);
      }
    }
    return closest ? closest.locale('da').format(format) : null;
  },
}


















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Error404 extends Vue {
}

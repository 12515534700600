import VueRouter, { RouteConfig } from 'vue-router';
import AboutView from '@/views/about/about-view.vue';
import AccessExpiredView from '@/views/access-expired/access-expired-view.vue';
import BlacklistView from '@/views/blacklist/blacklist-view.vue';
import BookDemoView from '@/views/book-demo/book-demo-view.vue';
import ChangePasswordView from '@/views/change-password/change-password-view.vue';
import CompanyListView from '@/views/company-list/company-list-view.vue';
import CompanyView from '@/views/company/company-view.vue';
import ContactView from '@/views/contact/contact-view.vue';
import CookiePolicyView from '@/views/cookie-policy/cookie-policy-view.vue';
import DashboardView from '@/views/dashboard/dashboard-view.vue';
import EmailChangeView from '@/views/email-change/email-change-view.vue';
import Error404View from '@/views/error-404/error-404-view.vue';
import HelpView from '@/views/help/help-view.vue';
import HomeView from '@/views/home/home-view.vue';
import i18n from '@/localization/i18n';
import ImpersonateView from '@/views/impersonate/impersonate-view.vue';
import InvalidSessionView from '@/views/invalid-session/invalid-session-view.vue';
import Jura from '@/views/jura/jura-view.vue';
import LayoutDefault from '@/views/layouts/layout-default/layout-default.vue';
import LayoutLoggedIn from '@/views/layouts/layout-logged-in/layout-logged-in.vue';
import LayoutPrint from '@/views/layouts/layout-print/layout-print.vue';
import ListView from '@/views/list-view/list-view.vue';
import PricesView from '@/views/prices/prices-view.vue';
import PrintProcurementView from '@/views/print-procurement/print-procurement-view.vue';
import PrintProjectView from '@/views/print-project/print-project-view.vue';
import ProcurementView from '@/views/procurement-view/procurement-view.vue';
import ProjectView from '@/views/project-view/project-view.vue';
import { RouterNames } from '@/util/enums/router-names';
import SearchCompanyView from '@/views/search-company/search-company-view.vue';
import SearchView from '@/views/search/search-view.vue';
import ServicesView from '@/views/services/services-view.vue';
import { Tooltip } from 'bootstrap';
import UserSettingsView from '@/views/user-settings/user-settings-view.vue';
import { vmx } from '@/store';
import Vue from 'vue';

/**
 * @author Jais Christiansen
 */

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { // LoggedIn
    path: '',
    component: LayoutLoggedIn,
    meta: {
      requiresAuth: true,
    },
    children: [
      // Children that require auth
      {
        path: '/dashboard',
        name: RouterNames.LOGGED_IN_DASHBOARD,
        component: DashboardView,
        meta: { title: 'pages.dashboard.pageTitle', header: 'compact' },
      },
      {
        path: '/settings',
        name: RouterNames.LOGGED_IN_USER_SETTINGS,
        component: UserSettingsView,
        meta: { title: 'pages.userSettings.pageTitle', header: 'compact' },
      },
      {
        path: '/blacklist',
        name: RouterNames.LOGGED_IN_BLACKLIST_VIEW,
        component: BlacklistView,
        meta: { title: 'pages.blacklist.pageTitle', header: 'compact' },
      },
      {
        path: '/list/:id',
        name: RouterNames.LOGGED_IN_LIST_VIEW,
        props: true,
        component: ListView,
        meta: { title: 'pages.lists.pageTitle', header: 'compact' },
      },
      {
        path: '/company-list/:id',
        name: RouterNames.LOGGED_IN_COMPANY_LIST_VIEW,
        props: true,
        component: CompanyListView,
        meta: { title: 'pages.companyList.pageTitle', header: 'compact' },
      },
      {
        path: '/help',
        name: RouterNames.LOGGED_IN_HELP,
        component: HelpView,
        meta: { title: 'pages.help.pageTitle', header: 'compact' },
      },
      {
        path: '/procurement/:id',
        name: RouterNames.LOGGED_IN_PROCUREMENT_VIEW,
        props: true,
        component: ProcurementView,
        meta: { title: 'pages.procurement.pageTitle', header: 'compact', autoscroll: true },
      },
      {
        path: '/search-company',
        name: RouterNames.LOGGED_IN_COMPANY_SEARCH,
        component: SearchCompanyView,
        meta: { title: 'pages.companySearch.pageTitle', autoscroll: true, header: 'compact' },
      },
      {
        path: '/search/:page',
        name: RouterNames.LOGGED_IN_SEARCH,
        component: SearchView,
        props: (route) => ({
          ...route.params,
        }),
        meta: { title: 'pages.search.pageTitle', autoscroll: true, header: 'compact' },
      },
      {
        path: '/project/:id',
        name: RouterNames.LOGGED_IN_PROJECT_VIEW,
        props: true,
        component: ProjectView,
        meta: { title: 'pages.project.pageTitle', limitedAccess: true, header: 'compact' },
      },
      {
        path: '/company/:id',
        name: RouterNames.LOGGED_IN_COMPANY_VIEW,
        props: true,
        component: CompanyView,
        meta: { title: 'pages.company.pageTitle', header: 'compact' },
      },
      {
        path: '/access-expired',
        name: RouterNames.LOGGED_IN_ACCESS_EXPIRED,
        component: AccessExpiredView,
        meta: { title: 'pages.accessExpired.pageTitle', limitedAccess: true, header: 'compact' },
      },
    ],
  },
  { // Print
    path: '',
    component: LayoutPrint,
    meta: {
      requiresAuth: true,
    },
    props: true,
    children: [
      {
        path: '/print-procurement/:id',
        name: RouterNames.PRINT_PROCUREMENT,
        props: true,
        component: PrintProcurementView,
        meta: { title: 'pages.procurement.pageTitle' },
      },
      {
        path: '/print-project/:id',
        name: RouterNames.PRINT_PROJECT,
        props: true,
        component: PrintProjectView,
        meta: { title: 'pages.project.pageTitle' },
      },
    ],
  },
  { // LoggedOut
    path: '',
    component: LayoutDefault,
    meta: {
      requiresAuth: false,
    },
    children: [
      // Children that don't require auth
      {
        path: '/invalid-session/',
        name: RouterNames.INVALID_SESSION,
        component: InvalidSessionView,
        meta: { title: 'pages.accessExpired.pageTitle', header: 'compact' },
      },
      {
        path: '/home',
        name: RouterNames.LOGGED_OUT_HOME,
        component: HomeView,
        meta: { title: 'pages.home.pageTitle' },
      },
      {
        path: '/about',
        name: RouterNames.LOGGED_OUT_ABOUT,
        component: AboutView,
        meta: { title: 'pages.about.pageTitle' },
      },
      {
        path: '/book-demo',
        name: RouterNames.LOGGED_OUT_BOOK_DEMO,
        component: BookDemoView,
        meta: { title: 'pages.bookDemo.pageTitle' },
      },
      {
        path: '/contact',
        name: RouterNames.LOGGED_OUT_CONTACT,
        component: ContactView,
        meta: { title: 'pages.contact.pageTitle' },
      },
      {
        path: '/prices',
        name: RouterNames.LOGGED_OUT_PRICES,
        component: PricesView,
        meta: { title: 'pages.prices.pageTitle' },
      },
      {
        path: '/services',
        name: RouterNames.LOGGED_OUT_SERVICES,
        component: ServicesView,
        meta: { title: 'pages.services.pageTitle' },
      },
      {
        path: '/cookie-policy',
        name: RouterNames.LOGGED_OUT_COOKIE_POLICY,
        component: CookiePolicyView,
        meta: { title: 'pages.cookiePolicy.pageTitle' },
      },
      {
        path: '/jura',
        name: RouterNames.LOGGED_OUT_JURA,
        component: Jura,
        meta: { title: 'pages.jura.pageTitle' },
      },
      {
        path: '/change-password/:resetToken',
        name: RouterNames.LOGGED_OUT_CHANGE_PASSWORD,
        component: ChangePasswordView,
        props: (route) => ({
          ...route.params,
        }),
        meta: { title: 'pages.changePassword.pageTitle' },
      },
      {
        path: '/impersonate/:token',
        name: RouterNames.LOGGED_OUT_IMPERSONATE_USER,
        component: ImpersonateView,
        props: (route) => ({
          ...route.params,
        }),
        meta: { title: 'pages.impersonate.pageTitle' },
      },
      {
        path: '/confirmations/email',
        name: RouterNames.LOGGED_OUT_UPDATE_EMAIL,
        component: EmailChangeView,
        props: (route) => ({
          ...route.query,
        }),
        meta: { title: 'pages.emailChange.pageTitle' },
      },
      {
        path: '/404',
        name: RouterNames.ERROR_NOT_FOUND,
        component: Error404View,
      },
      // Any other path we redirect to 404
      {
        path: '*',
        redirect: '/404',
        meta: { title: 'pages.error404.pageTitle' },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === RouterNames.LOGGED_IN_SEARCH && from.name === RouterNames.LOGGED_IN_SEARCH) {
      return null;
    }

    if (to.meta?.autoscroll === true && savedPosition != null) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash };
    }

    return { x: 0, y: 0 };
  },
});

// Authentication guard for routes with authentication required
router.beforeEach((to, from, next) => {
  document.querySelectorAll('div[role=tooltip]').forEach(element => {
    element.remove();
  })
  if (!to.path || to.path == '/') {
    next({ name: vmx.session.isLoggedinUser ? RouterNames.LOGGED_IN_DASHBOARD : RouterNames.LOGGED_OUT_HOME });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = (i18n.t(to.meta.title) as string) || to.name;
  });
});

export default router;

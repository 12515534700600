







































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Agent } from '@/interfaces/agent';
import AgentCriteriaFilter from './agent-criteria-filter.vue'
import AgentCriteriaQuarterSelector from './agent-criteria-quarter-selector.vue'
import AgentCriteriaRange from './agent-criteria-range.vue'
import BaseDropDown from '/src/components/dropdowns/base-dropdown.vue'
import BaseFoldMenu from '../buttons/base-fold-menu.vue'
import ButtonUnfold from '@/components/buttons/button-unfold.vue'
import CriteriaTree from './criteria-tree.vue'
import { Criterion } from '@/interfaces/criterias/criterion';
import CriterionTree from './criterion-tree.vue';
import { ProjectPhase } from '@/interfaces/criterias/project-phase';
import SearchInput from '@/components/form-fields/search-input.vue';

@Component({
  components: {
    SearchInput,
    AgentCriteriaFilter,
    AgentCriteriaRange,
    ButtonUnfold,
    CriteriaTree,
    CriterionTree,
    BaseFoldMenu,
    AgentCriteriaQuarterSelector,
    BaseDropDown,
  },
})
export default class AgentCriteriaMenu extends Vue {
  @Prop({ default: null }) onUpdateAgent: (agent: Partial<Agent>) => void;

  readonly A_MILLION = 1000000;

  readonly BUDGET_MIN = 0;
  readonly BUDGET_MAX = 3000;

  readonly BUILDING_MIN = 0;
  readonly BUILDING_MAX = 1000000;

  readonly GROUND_MIN = 0;
  readonly GROUND_MAX = 1000000;

  readonly METRES_MIN = 0;
  readonly METRES_MAX = 1000000;

  readonly UNITS_MIN = 0;
  readonly UNITS_MAX = 100000;

  get agent() {
    return this.$vmx.search.agent;
  }

  get page() {
    return this.$vmx.search.searchItemPage;
  }

  // Project Phase
  get projectPhases() {
    return this.agent.projectPhases;
  }

  set projectPhases(projectPhases) {
    this.onUpdateAgent({ projectPhases });
  }

  get projectPhaseCount() {
    return this.page?.projectPhaseCount
  }

  isProjectPhaseDisabled(criteria: ProjectPhase) {
    return this.$vmx.user.user.product.allowedProjectPhases.every(pp => criteria.index != pp.index)
  }

  // Procurement Phase
  get procurementPhases() {
    return this.agent.procurementPhases;
  }

  set procurementPhases(procurementPhases) {
    this.onUpdateAgent({ procurementPhases });
  }

  updateCriteria(criteria: Criterion[], typeId: number) {
    var agentCriteria = this.agent.criteria?.filter(criterion => criterion.typeId !== typeId) ?? [];
    agentCriteria = agentCriteria.concat(criteria);
    this.onUpdateAgent({ criteria: agentCriteria });
  }
  
  getAgentCriteriaOfType(typeId: number) {
    return this.agent.criteria?.filter(criterion => criterion.typeId === typeId) ?? [];
  }

  getAllowUnspecifiedType(typeId: number) {
    if (!this.agent.allowUnusedCriterionTypes) {
      this.agent.allowUnusedCriterionTypes = [];
    }
    return this.agent.allowUnusedCriterionTypes.some(n => n === typeId);
  }

  updateAllowUnspecifiedType(typeId: number, checked: boolean) {
    const allowed = [...this.agent.allowUnusedCriterionTypes];
    if (checked) {
      if (!allowed.some(n => n === typeId)) {
        allowed.push(typeId);
      }
    } else {
      const index = allowed.indexOf(typeId, 0);
      if (index > -1) {
        allowed.splice(index, 1);
      }
    }
    this.onUpdateAgent({allowUnusedCriterionTypes: allowed});
  }




  getTypeIdFromTranslation(translation: string): number {
    return this.$vmx.criterion.criterionTypeFromTranslation(translation).id;
  }


  // Procurement Types
  get procurementTypes() {
    return this.agent.procurementTypes;
  }

  set procurementTypes(procurementTypes) {
    this.onUpdateAgent({ procurementTypes })
  }

  // Project Start/End
  get projectStart() {
    return this.agent.projectStart;
  }

  set projectStart(val) {
    this.onUpdateAgent({
      projectStart: val,
    })
  }

  get projectEnd() {
    return this.agent.projectEnd;
  }

  set projectEnd(val) {
    this.onUpdateAgent({
      projectEnd: val,
    })
  }

  get allowUnspecifiedStartAndEnd() {
    return this.agent.allowUnspecifiedStartAndEnd;
  }

  set allowUnspecifiedStartAndEnd(val) {
    this.onUpdateAgent({
      allowUnspecifiedStartAndEnd: val,
    })
  }

  // Budget
  get budget() {
    return [
      this.agent.minBudget == null ? null : this.agent.minBudget / this.A_MILLION,
      this.agent.maxBudget == null ? null : this.agent.maxBudget / this.A_MILLION,
    ] as const;
  }

  set budget(val) {
    this.onUpdateAgent({
      minBudget: val[0] == this.BUDGET_MIN ? null : val[0] * this.A_MILLION,
      maxBudget: val[1] == this.BUDGET_MAX ? null : val[1] * this.A_MILLION,
    });
  }

  get allowUnspecifiedBudget() {
    return this.agent.allowUnspecifiedBudget;
  }

  set allowUnspecifiedBudget(val) {
    this.onUpdateAgent({
      allowUnspecifiedBudget: val,
    })
  }

  // Size Building
  get sizeBuilding() {
    return [this.agent.minSizeBuilding, this.agent.maxSizeBuilding]
  }

  set sizeBuilding([min, max]) {
    this.onUpdateAgent({
      minSizeBuilding: min === this.BUILDING_MIN ? null : min,
      maxSizeBuilding: max === this.BUILDING_MAX ? null : max,
    });
  }

  get allowUnspecifiedSize() {
    return this.agent.allowUnspecifiedSize;
  }

  set allowUnspecifiedSize(val) {
    this.onUpdateAgent({
      allowUnspecifiedSize: val,
    });
  }

  // Size Ground
  get sizeGround() {
    return [this.agent.minSizeGround, this.agent.maxSizeGround];
  }

  set sizeGround([min, max]) {
    this.onUpdateAgent({
      minSizeGround: min === this.GROUND_MIN ? null : min,
      maxSizeGround: max === this.GROUND_MAX ? null : max,
    });
  }

  get numberOfUnits() {
    return [this.agent.minNumberOfUnits, this.agent.maxNumberOfUnits];
  }

  set numberOfUnits([min, max]) {
    this.onUpdateAgent({
      minNumberOfUnits: min === this.UNITS_MIN ? null : min,
      maxNumberOfUnits: max === this.UNITS_MAX ? null : max,
    })
  }

  // Public/Private
  get isPublic() {
    return this.agent.isPublic === true ? 'isPublic' : this.agent.isPublic === false ? 'isPrivate' : 'isBoth'
  }

  set isPublic(val) {
    this.onUpdateAgent({
      isPublic: val === 'isPublic' ? true : val === 'isPrivate' ? false : null,
    });
  }

  isPublicTranslation(isPublic: boolean) {

  }

}

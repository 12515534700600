



























































































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Company } from '@/interfaces/company';
import CompanyProjectsList from '@/components/company-list-components/company-projects-list.vue';
import DropdownNotes from '@/components/dropdowns/dropdown-notes.vue';
import { Location } from 'vue-router';
import { Modal } from 'bootstrap';
import ModalNoteDelete from '@/components/modals/modal-note-delete.vue';
import ModalNoteEdit from '@/components/modals/modal-note-edit.vue';
import { Note } from '@/interfaces/note';
import { RouterNames } from '@/util/enums/router-names';

@Component({
  components: {
    CompanyProjectsList,
    DropdownNotes,
    ModalNoteEdit,
    ModalNoteDelete,
  },
})
export default class TableCompanyList extends Vue {
  @Prop() companies: Company[];
  @Prop({ default: false }) loading: boolean;
  @Ref() tableContainer: HTMLDivElement;
  projectLimit = 5;

  unfoldedLists: Array<number> = [];

  selectedNote: Note = null;
  tableSize = 4000;

  tableOptions = {
    xxl: 1265,
    xl: 900,
    lg: 768,
    md: 535,
    sm: 400,
  }

  mounted() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  }

  activated() {
    this.resizeHandler();
  }

  get colClassesAddress() {
    if (this.tableSize > this.tableOptions.xl) {
      return [
        'col-3',
      ];
    } else if (this.tableSize > this.tableOptions.lg) {
      return [
        'col-4',
      ];
    } else {
      return [
        'd-none',
      ];
    }
  }

  get colClassesMail() {
    if (this.tableSize > this.tableOptions.xxl) {
      return [
        'col-2',
      ];
    } else {
      return [
        'd-none',
      ];
    }
  }

  get colClassesName() {
    if (this.tableSize > this.tableOptions.xl) {
      return [
        'col-5',
      ];
    } else if (this.tableSize > this.tableOptions.lg) {
      return [
        'col-6',
      ];
    } else if (this.tableSize > this.tableOptions.md) {
      return [
        'col-8',
      ];
    } else {
      return [
        'col-12',
      ];
    }
  }

  get colClassesPhone() {
    if (this.tableSize > this.tableOptions.xxl) {
      return [
        'col-1',
      ];
    } else if (this.tableSize > this.tableOptions.xl) {
      return [
        'col-2',
      ];
    } else {
      return [
        'd-none',
      ];
    }
  }

  get colClassesVatin() {
    if (this.tableSize > this.tableOptions.xxl) {
      return [
        'col-1',
      ];
    } else if (this.tableSize > this.tableOptions.lg) {
      return [
        'col-2',
      ];
    } else if (this.tableSize > this.tableOptions.md) {
      return [
        'col-4',
      ];
    } else {
      return [
        'd-none',
      ];
    }
  }

  collapseCompany(companyIndex: number, collapse: boolean) {
    if (collapse) {
      const index = this.unfoldedLists.indexOf(companyIndex, 0);
      if (index >= 0) {
        this.unfoldedLists.splice(index, 1);
      }
    } else if (!this.isExpanded(companyIndex)) {
      this.unfoldedLists.push(companyIndex);
    }
  }

  @Watch('companies')
  companiesChanged() {
    this.unfoldedLists = [];
  }

  isExpanded(index: number) {
    return this.unfoldedLists.includes(index);
  }

  isOnCompanyList(company: Company): boolean {
    return this.$vmx.companyList.isInCompanyList(company);
  }


  noteDeleted(id: number) {
    const company = this.companies.find((company) => {
      return company.id === this.selectedNote.companyId;
    });
    company.notes.splice(
      company.notes.findIndex((x) => x.id === id),
      1,
    );
    this.selectedNote = null;
  }

  noteId(id: number) {
    return `note-list-company-${id}`;
  }

  noteSaved(note: Note) {
    const company = this.companies.find((company) => {
      return company.id === this.selectedNote.companyId;
    });
    if (company.notes.some((x) => x.id === note.id)) {
      company.notes = company.notes.map((obj) =>
        obj.id === note.id ? note : obj,
      );
    } else {
      company.notes.push(note);
      company.notes.sort((a, b) => (a.created > b.created ? -1 : 1));
    }
    this.selectedNote = null;
  }

  openCompanyListAddModal(company: Company) {
    this.$vmx.modal.openCompanyListAddModal(company);
  }

  openDeleteNoteModal(id: number) {
    for (const company of this.companies) {
      const note = company.notes.find((note) => note.id == id);
      if (note) {
        this.selectedNote = note;
        this.openModal(`delete-${this.noteId(company.id)}`);
      }
    }
  }

  openModal(modalId: string) {
    const element = document.getElementById(modalId);
    const modal = new Modal(element);
    modal.show();
  }

  public resizeHandler() {
    if (this.tableContainer) {
      this.tableSize = this.tableContainer.clientWidth;
    }
  }

  roundedAtSize(maxSize: number) {
    return [
      this.tableSize <= maxSize ? 'rounded-start' : '',
    ]
  }

  rowClasses(roleCount: number, index: number) {
    return [
      roleCount !== 0 ? 'border-bottom rounded-top' : 'rounded',
    ]
  }

  routerLink(id: number): Location {
    return {
      name: RouterNames.LOGGED_IN_COMPANY_VIEW,
      params: { id: id.toString() },
    };
  }

  showAtSize(minSize: number) {
    return [
      this.tableSize < minSize ? 'd-none' : '',
    ];
  }
}

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatUtils } from '@/util/format-utils';
import { Note } from '@/interfaces/note';

@Component
export default class PrintNote extends Vue {
  @Prop({ required: true }) note: Note;

  get createdDate(): string {
    return formatUtils.formatDate(this.note?.created, 'DD.MM.YYYY');
  }
}

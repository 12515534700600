


































import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonUnfold from '@/components/buttons/button-unfold.vue'

@Component({
  components: {
    ButtonUnfold,
  },
})
export default class BaseFoldMenu extends Vue {
  @Prop({ default: 'missing text' }) text: string;
  @Prop({ default: 'xs' }) size: string;
  @Prop({ default: 'xs' }) iconSize: string;
  @Prop({ default: false }) invisible: boolean;
  @Prop({ default: 'white '}) variant: string;
  @Prop({ default: true }) folded: boolean;
  @Prop({ default: '' }) textClass: string | string[] | {[attr: string]: boolean};
  @Prop({ default: '' }) contentClass: string | string[] | {[attr: string]: boolean};

  foldedVal = null as boolean;

  get isFolded() {
    if (this.foldedVal == null) {
      this.foldedVal = this.folded;
    }
    return this.foldedVal;
  }

  set isFolded(val) {
    this.foldedVal = val;
  }

  click() {
    if (this.invisible) {
      return;
    }
    this.isFolded = !this.isFolded;
  }

}

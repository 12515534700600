const numberFormats = {
  'da-DK': {
    currency: {
      style: 'currency',
      currency: 'DKK',
      maximumFractionDigits: 0,
    },
  },
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    },
  },
  'sv-SE': {
    currency: {
      style: 'currency',
      currency: 'SEK',
      maximumFractionDigits: 0,
    },
  },
};

export default numberFormats;
